<template>
  <div>
    <div id="chart">
      <h5 class="header">Opportunity Chart</h5>
      <div>
        <div v-if="loading" class="loading-container">
          <Loading />
        </div>
        <div v-else>
          <div class="container">
            <span style="max-width: 50px; flex-grow: 1">
              <SparkSelect
                v-model="yAxisLabel"
                custom="!w-[240px]  mr-120 my-48"
                class="vertical-selection"
                name="y-axis"
                :options="yAxisOptions"
                @update:model-value="updateAxes"
              />
            </span>
            <div class="chart">
              <apexchart
                type="scatter"
                height="350"
                :options="chartOptions"
                :series="series"
                @dataPointSelection="dataPointSelectionHandler"
              />
            </div>
          </div>
          <SparkSelect
            v-model="xAxisLabel"
            custom="!w-[250px]"
            class="mx-auto"
            name="x-axis"
            :options="xAxisOptions"
            @update:model-value="updateAxes"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import Loading from '@/components/Reusable/Loading.vue';
import SparkSelect from '@/components/SparkComponents/SparkSelect.vue';

export default {
  name: 'Scatter',

  components: { Loading, SparkSelect },

  props: {
    filtering: Number,
  },

  data: function () {
    return {
      loading: true,
      yAxisLabel: 'l_saved',
      xAxisLabel: 'c_saved_pp',
      xAxisOptions: [
        { value: 'c_saved_pp', label: 'Cost Saved per Part in €' },
        { value: 'c_saved_pl', label: 'Cost Saved per Lot in €' },
        { value: 'economic_suitability', label: 'Economical Suitability in %' },
      ],

      yAxisOptions: [
        { value: 'l_saved', label: 'Lead Time saved in days' },
        { value: 'tech_suitability', label: 'Technological Suitability in %' },
        { value: 'overall_suitability', label: 'Overall Suitability in %' },
      ],

      values: [],
      rawValues: [],
    };
  },

  computed: {
    ...mapState(['dashboardFilters']),
    ...mapState('application', ['axiosInstance']),

    filterSettings() {
      return this.dashboardFilters;
    },

    chartOptions() {
      return {
        chart: {
          height: 350,
          type: 'scatter',
          zoom: {
            enabled: true,
            type: 'xy',
          },

          fontFamily: 'Poppins, Arial, sans-serif',
          animations: {
            enabled: false,
          },

          toolbar: {
            show: true,
            tools: {
              download: '<i class="fas fa-download"></i>',
            },
          },
        },

        annotations: {
          position: 'front',
          yaxis: [
            {
              y: 0,
              y2: null,
              strokeDashArray: 0,
              borderColor: '#383e46',
            },
          ],

          xaxis: [
            {
              x: 0,
              x2: null,
              strokeDashArray: 0,
              borderColor: '#383e46',
            },
          ],
        },

        tooltip: {
          shared: false,
          intersect: true,
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            let data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            return (
              "<div style='padding:4px;'>" +
              '<p<b>' +
              data.name +
              '</b></p>' +
              '<p><b>x = ' +
              parseFloat(data.x).toFixed(0) +
              '</br>' +
              '<b>y = ' +
              parseFloat(data.y).toFixed(0) +
              '</br>' +
              '</p>' +
              "<p style='font-size:0.8em;'>Navigate to part by click.</p>" +
              '</div>'
            );
          },
        },

        xaxis: {
          tickAmount: 10,
          forceNiceScale: true,
          labels: {
            formatter: function (val) {
              return parseFloat(val).toFixed(0);
            },
          },
        },

        yaxis: {
          min: function (min) {
            if (min < 0) {
              return min;
            } else {
              return 0;
            }
          },

          max: function (max) {
            if (max < 0) {
              return 0;
            } else {
              return max;
            }
          },

          forceNiceScale: true,
          labels: {
            formatter: function (val) {
              return parseFloat(val).toFixed(0);
            },
          },
        },
      };
    },

    series() {
      return [
        {
          data: this.values,
        },
      ];
    },
  },

  watch: {
    filtering() {
      this.loading = true;
      this.getData();
    },
  },

  mounted() {
    this.getData();
  },

  methods: {
    ...mapMutations(['updatePart', 'updateActiveId']),
    dataPointSelectionHandler(event, chartContext, config, w) {
      let selected_part = config.w.globals.initialSeries[config.seriesIndex].data[config.dataPointIndex];

      this.updatePart({ part_id: selected_part.part_id });
      this.updateActiveId(selected_part.part_id);

      this.$router.push({
        name: 'partframe',
        params: {
          part_id: selected_part.part_id,
        },
      });
    },

    updateAxes() {
      let transformData = this.rawValues.map(a => {
        return { ...a };
      });
      // apex Charts can handle NaN Values for Scatter Plot, but not null Values
      transformData.forEach((entry, index) => {
        if (entry[this.xAxisLabel] === null) {
          entry['x'] = NaN;
        } else {
          entry['x'] = entry[this.xAxisLabel];
        }
        if (entry[this.yAxisLabel] === null) {
          entry['y'] = NaN;
        } else {
          entry['y'] = entry[this.yAxisLabel];
        }
      });

      this.values = transformData;
    },

    async getData() {
      this.axiosInstance
        .post('/api/v1/dashboard/leadtime-vs-cost-saved/', this.filterSettings)
        .then(response => {
          this.rawValues = response.data.values;
          this.updateAxes();
          this.loading = false;
        })
        .catch(error => {
          // 404 - specified group not found
          console.log(JSON.stringify(error));
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  margin-top: 30px;
  display: block;
}

.container {
  display: flex;
  justify-content: start;
  align-items: start;
}

.vertical-selection {
  transform: translate(-150px, 20px) rotate(-90deg);
  transform-origin: 80% 100%;
}

.horizontal-selection {
  display: flex;
  width: 30%;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
}

.loading-container {
  position: relative;
  top: -100px;
  left: 0;
  right: 0;
  align-self: start;
}
.chart {
  flex-grow: 1;
}
</style>
