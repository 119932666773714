<template>
  <div class="relative text-16">
    <!-- Dropdown trigger -->
    <div class="flex items-center justify-end mr-8" @click="toggleDropdown">
      <span class="p-8 rounded-8 border border-white hover:border-gray-200 cursor-pointer"
        ><img src="@/assets/icons/comparison/columns.svg"
      /></span>
    </div>
    <div
      v-if="isOpen"
      class="fixed w-[300px] rounded-8 bg-white border border-gray-100 shadow-md z-[100]"
      :style="dropdownPosition"
    >
      <div class="max-h-[500px] overflow-y-auto border-t border-gray-100">
        <div
          v-for="key in orderedComparisonTableColumns"
          :key="key"
          class="flex items-center px-8 py-4"
          :class="[
            !comparisonTableColumns[key].disabled && 'hover:bg-gray-50 hover:cursor-pointer',
            comparisonTableColumns[key].disabled && 'text-gray-500 cursor-not-allowed',
          ]"
          @click="selectIfNotdisabled(key)"
        >
          <input
            :id="key"
            v-model="comparisonTableColumns[key].selected"
            type="checkbox"
            :value="comparisonTableColumns[key].selected"
            :disabled="comparisonTableColumns[key].disabled"
            class="mr-2 rounded-6 border border-gray-300 focus:ring-primary-600 focus:ring-offset-0"
            :class="[
              !comparisonTableColumns[key].disabled && 'text-primary-500 hover:bg-gray-50 hover:cursor-pointer',
              comparisonTableColumns[key].disabled && 'bg-gray-200 text-gray-500 cursor-not-allowed',
            ]"
          />
          <div class="flex items-center px-10 mb-4 font-normal">
            {{ comparisonTableColumns[key].title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'SelectColumns',

  data() {
    return {
      isOpen: false,
      dropdownPosition: {
        top: '0px',
        right: '0px',
      },
    };
  },

  computed: {
    ...mapState(['user']),
    ...mapState('application', ['axiosInstance']),

    comparisonTableColumns() {
      return this.user.user_settings.comparison_table_columns;
    },

    orderedComparisonTableColumns() {
      return Object.keys(this.comparisonTableColumns).sort((a, b) => {
        return this.comparisonTableColumns[a].index - this.comparisonTableColumns[b].index;
      });
    },
  },

  beforeUnmount() {
    this.removeEventListeners();
  },

  methods: {
    ...mapMutations(['updateUserSettings']),
    toggleDropdown() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.$nextTick(() => {
          this.updateDropdownPosition();
          document.addEventListener('click', this.handleClickOutside);
          window.addEventListener('scroll', this.updateDropdownPosition);
          window.addEventListener('resize', this.updateDropdownPosition);
        });
      } else {
        this.removeEventListeners();
        this.updateUserSettings({ comparison_table_columns: this.comparisonTableColumns });
        this.putUserSettings();
      }
    },

    updateDropdownPosition() {
      const trigger = this.$el.getBoundingClientRect();
      this.dropdownPosition = {
        top: `${trigger.bottom + 8}px`,
        right: `${window.innerWidth - trigger.right}px`,
      };
    },

    removeEventListeners() {
      document.removeEventListener('click', this.handleClickOutside);
      window.removeEventListener('scroll', this.updateDropdownPosition);
      window.removeEventListener('resize', this.updateDropdownPosition);
    },

    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false;
        this.updateUserSettings({ comparison_table_columns: this.comparisonTableColumns });
        this.putUserSettings();
      }
    },

    async putUserSettings() {
      this.axiosInstance.put('/api/v1/user-settings/', {
        comparison_table_columns: this.comparisonTableColumns,
      });
    },

    selectIfNotdisabled(key) {
      if (!this.comparisonTableColumns[key].disabled) {
        this.comparisonTableColumns[key].selected = !this.comparisonTableColumns[key].selected;
      }
    },
  },
};
</script>
