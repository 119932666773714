<template>
  <div>
    <table class="spark-table">
      <tbody>
        <tr v-if="additionalData?.has_ai_prediction_model">
          <td colspan="4">{{ description.text }}</td>
        </tr>
        <tr v-if="additionalData?.has_ai_prediction_model">
          <td colspan="4">
            <b><a :href="description.linkvalue" target="_blank" rel="noopener noreferrer">Want to learn more?</a></b>
          </td>
        </tr>
        <template v-for="(data, propertykey) in processFormData">
          <tr v-for="(infos, name) in data" :key="name">
            <td class="title-column" :class="infos.required ? 'required' : ''">
              {{ name }}<span v-if="infos.required">&nbsp;*</span>
            </td>
            <td>
              <img
                v-if="infos.source == 'ai' && additionalData?.has_ai_prediction_model"
                title="If empty: Build time will be predicted via AI"
                class="icon-source"
                src="@/assets/icons/comparison/artificial_intelligence.svg"
              />
            </td>
            <td v-if="infos.field == 'input-text'" class="value-column">
              <input
                v-model="form.parent_id[propertykey]"
                type="text"
                maxlength="250"
                @change="addData(infos.db_name, form.parent_id[propertykey])"
              />
              <div class="warning-message">{{ required.parent_id[propertykey] }}</div>
            </td>
            <td v-if="infos.field == 'input-number'" class="value-column">
              <input
                v-model="form.parent_id[propertykey]"
                type="number"
                :min="infos.options[0]"
                :max="infos.options[1]"
                class="align-right"
                :placeholder="infos.placeholder"
                @change="addData(infos.db_name, form.parent_id[propertykey])"
              />
              <div class="warning-message">{{ required.parent_id[propertykey] }}</div>
            </td>
            <td v-if="infos.field == 'select'" class="value-column">
              <select
                v-model="form.parent_id[propertykey]"
                @change="addData(infos.db_name, form.parent_id[propertykey])"
              >
                <option v-for="item in infos.options" :key="item.key" :value="item.value">
                  {{ item.text }}
                </option>
              </select>
              <div class="warning-message">{{ required.parent_id[propertykey] }}</div>
            </td>
            <td v-if="infos.field == 'scaling-custom'" class="combined">
              <input
                v-model="scalingX"
                type="number"
                placeholder="x"
                class="align-right"
                @change="addData(infos.db_name, [scalingX, scalingY, scalingZ])"
              />
              <input
                v-model="scalingY"
                type="number"
                placeholder="y"
                class="align-right"
                @change="addData(infos.db_name, [scalingX, scalingY, scalingZ])"
              />
              <input
                v-model="scalingZ"
                type="number"
                placeholder="z"
                class="align-right"
                @change="addData(infos.db_name, [scalingX, scalingY, scalingZ])"
              />
            </td>
            <td class="unit-column">{{ infos.unit }}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import useCopyObject from '@/composables/copyObject.js';

export default {
  name: 'PopupForm',

  props: {
    formData: { type: Object, default: {} },
    data: { type: Object, default: {} },
    additionalData: { type: Object, default: {} },
    type: { type: String, default: '' },
    description: {
      type: Object,
      default() {
        return { text: '', linkvalue: '' };
      },
    },
  },

  data() {
    return {
      filledFormData: {},
      form: {
        parent_id: [],
      },

      required: {
        parent_id: [],
      },

      scalingX: null,
      scalingY: null,
      scalingZ: null,
    };
  },

  computed: {
    ...mapState(['popup']),
    processFormData() {
      let process = this.data['prc_acronym'];
      let copy = useCopyObject(this.formData);
      Object.keys(copy).forEach(key => {
        let entryPrc = Object.entries(copy[key])[0][1].prc;
        if (entryPrc == process || entryPrc == '' || process == null) {
        } else {
          delete copy[key];
        }
      });
      return copy;
    },
  },

  watch: {
    infillWallLineCount(newVal) {
      if (!this.checkValue(newVal)) {
        this.infillWallLineCount = parseInt(newVal);
      }
    },

    filledFormData() {
      this.triggerPopup({ filled_form_data: this.filledFormData });
    },

    data: {
      handler(data) {
        this.filledFormData = data;
        Object.keys(this.formData).forEach(key => {
          let db_name = Object.values(this.formData[key])[0].db_name;
          this.form.parent_id[key] = data[db_name];
          if (db_name == 'prc_uid') {
            this.filterMachinesOnProcess(data[db_name]);
          }
          if (db_name == 'scaling_factors' && data['scaling_factors'] != null) {
            this.scalingX = data[db_name][0];
            this.scalingY = data[db_name][1];
            this.scalingZ = data[db_name][2];
          }
          if (this.type == 'add' && db_name == 'restriction') {
            this.form.parent_id[key] = 'owner';
          }
        });

        if (this.type == 'add') {
          {
            this.form.parent_id[0] = null;
            delete this.filledFormData.mat_name;
            delete this.filledFormData.mat_id;

            delete this.filledFormData.mac_name;
          }
        }
      },

      immediate: true,
    },
  },

  methods: {
    ...mapMutations(['filterMachinesOnProcess', 'triggerPopup']),

    checkValue(value) {
      if (value == null || isNaN(value)) {
        return false;
      } else {
        return true;
      }
    },

    addData(field, value) {
      if (field == 'prc_uid') {
        this.filterMachinesOnProcess(value);
      }
      if (field == 'mat_lvl_2_id' && value == null) {
        this.formData[9] = {
          'Material Level 3': {
            db_name: 'mat_lvl_3_id',
            field: 'select',
            options: this.additionalData?.mat_lvl_3,
            unit: '',
            required: false,
          },
        };
      }
      if (field == 'mat_lvl_3_id' && value == null) {
        this.formData[10] = {
          'Material Level 4': {
            db_name: 'mat_lvl_4_id',
            field: 'select',
            options: this.additionalData?.mat_lvl_4,
            unit: '',
            required: false,
          },
        };
      }
      this.filledFormData[field] = value;
    },

    checkRequiredInputs() {
      this.required.parent_id = [];
      let array = Object.entries(this.formData);
      array.forEach(entry => {
        if (Object.entries(entry[1])[0][1].required == true) {
          let fieldName = Object.entries(entry[1])[0][1].db_name;
          if (!this.filledFormData.hasOwnProperty(fieldName)) {
            this.required.parent_id[entry[0]] = 'This field is required.';
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.required {
  font-weight: bold;
}

.help {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 10px;
  border-radius: 50%;
  color: black;
  &:hover {
    background-color: var(--accent-color);
    color: white;
  }
}
.align-right {
  text-align: right;
}

.title-column {
  width: 50%;
}
.value-column {
  width: 40%;
}
.unit-column {
  width: 10%;
}

.warning-message {
  float: right;
  color: red;
}

.combined {
  display: flex;
  justify-content: space-between;

  > input {
    flex-grow: 2;
    margin-right: 2px;
  }
}

.icon-source {
  height: 25px;
}
</style>
