<template>
  <div>
    <div class="text-18 font-semibold text-center mb-16" v-text="'Properties visible in PRP'" />
    <div class="flex w-full justify-between">
      <div class="flex-1 gap-8">
        <div class="text-16 font-semibold text-center mb-8" v-text="'Part Properties'" />
        <div class="flex flex-col gap-12 border-r-2 border-r-gray-300">
          <SparkCheckbox v-for="(property, key) in partProperties" :key="key" v-model="property.value" :name="key">
            <div class="text-13">{{ property.label }}</div>
          </SparkCheckbox>
        </div>
      </div>
      <div class="flex-1 gap-12 pl-20">
        <div class="text-16 text-center font-semibold mb-8" v-text="'Process Chain Properties'" />
        <div class="flex flex-col gap-4">
          <SparkCheckbox
            v-for="(property, key) in processChainProperties"
            :key="key"
            v-model="property.value"
            :name="key"
          >
            <div class="text-13">{{ property.label }}</div>
          </SparkCheckbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import SparkCheckbox from '@/components/SparkComponents/SparkCheckbox.vue';

export default {
  name: 'CustomPrpInfo',

  components: { SparkCheckbox },

  data() {
    return {
      partProperties: {},
      processChainProperties: {},
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
  },

  async created() {
    await this.fetchPrpSettingsProperties();
  },

  beforeUnmount() {
    this.updateProperties({ partProperties: this.partProperties, processChainProperties: this.processChainProperties });
  },

  methods: {
    async fetchPrpSettingsProperties() {
      try {
        const response = await this.axiosInstance.get('api/v1/prp-properties/');
        const properties = response.data;
        this.partProperties = properties.partProperties;
        this.processChainProperties = properties.processChainProperties;
      } catch (error) {
        console.error('Failed to fetch PRP settings properties:', error);
      }
    },

    async updateProperties(formData) {
      try {
        await this.axiosInstance.put('api/v1/prp-properties/', formData);
      } catch (error) {
        console.error('Failed to update PRP settings properties:', error);
      }
    },
  },
};
</script>
