<template>
  <SparkModal dismissable :model-value="show" @update:model-value="close">
    <template #header>
      <div class="pl-20">Delete Category</div>
    </template>
    <div class="flex flex-col gap-16 p-20">
      <div class="text-13">
        Do you really want to delete <span class="font-bold">{{ category?.label }}</span
        >?
      </div>

      <div v-if="category?.items?.length" class="text-13">
        <div class="font-semibold mb-8">The following options will be deleted:</div>
        <div class="flex flex-wrap gap-8">
          <SparkBadge v-for="item in category.items" :key="item.uid" variant="warning" class="text-11">
            <span v-text="item.value" />
          </SparkBadge>
        </div>
      </div>

      <div class="text-13 text-red-500 bg-red-50 p-4 rounded-4">
        If there is data related to this category, it will also be deleted.
      </div>

      <div class="flex justify-end gap-8">
        <SparkButton variant="outlined" @click="close">Cancel</SparkButton>
        <SparkButton variant="secondary" @click="handleDelete">Delete</SparkButton>
      </div>
    </div>
  </SparkModal>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import SparkBadge from '@/components/SparkComponents/SparkBadge.vue';
import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkModal from '@/components/SparkComponents/SparkModal.vue';

export default {
  name: 'DeleteCategoryModal',

  components: {
    SparkModal,
    SparkButton,
    SparkBadge,
  },

  props: {
    show: { type: Boolean },
    category: { type: Object, default: () => ({}) },
    prp: { type: Boolean },
  },

  emits: ['close'],

  computed: {
    ...mapState('application', ['axiosInstance']),

    comboCategoryUrl() {
      return this.prp ? '/api/v1/prp-combo-category/' : '/api/v1/combo-category/';
    },

    textCategoryUrl() {
      return this.prp ? '/api/v1/prp-text-category/' : '/api/v1/text-category/';
    },
  },

  methods: {
    ...mapActions(['fetchOrganization']),

    close() {
      this.$emit('close');
    },

    handleDelete() {
      this.deleteCategory();
    },

    deleteCategory() {
      if (this.category.category_type === 'combo  ') {
        this.deleteComboCategory();
      } else if (
        this.category.category_type === 'text' ||
        this.category.category_type === 'date' ||
        this.category.category_type === 'number'
      ) {
        this.deleteTextCategory();
      }
    },

    deleteComboCategory() {
      this.axiosInstance.delete(`${this.comboCategoryUrl}${this.category.uid}`).then(() => {
        this.fetchOrganization();
        this.close();
      });
    },

    deleteTextCategory() {
      this.axiosInstance.delete(`${this.textCategoryUrl}${this.category.uid}`).then(() => {
        this.fetchOrganization();
        this.close();
      });
    },
  },
};
</script>
