<template>
  <div>
    <teleport v-if="isMounted" to="#sub-navigation__action-buttons">
      <div class="flex items-center justify-center gap-8 h-56">
        <StepTypeFilter
          :process-steps="processStepsCopy"
          class="mr-8"
          @update-selected-types="handleSelectedTypes($event)"
        />
        <ProcessFilter :process-steps="processStepsCopy" @update-selected-processes="setSelectedProcesses($event)" />
        <SparkButton
          v-if="!showCreateForm"
          variant="secondary"
          class="w-100 ml-8"
          @click="addNewProcessChainStepTemplate()"
        >
          <i class="fas fa-plus" /> Add
        </SparkButton>
      </div>
    </teleport>
    <div class="m-8 bg-white rounded-8 border border-solid border-gray-50 text-13">
      <table class="w-full">
        <thead class="border-b border-solid border-gray-50 text-gray-500 font-normal h-56">
          <tr>
            <td class="px-16 py-8">Name</td>
            <td class="px-16 py-8">Process</td>
            <td class="px-16 py-8">Restriction</td>
            <td class="px-16 py-8">Calculation</td>
            <td class="px-16 py-8" title="Setup Time (hh:mm:ss)">Setup</td>
            <td class="px-16 py-8" title="Process Time (hh:mm:ss)">Process</td>
            <td class="px-16 py-8" title="Unloading Time (hh:mm:ss)">Unloading</td>
            <td class="px-16 py-8">Fixed Cost</td>
            <td class="px-16 py-8">Machine Hourly Rate</td>
            <td class="px-16 py-8">Staff Time Factor</td>
            <td class="px-16 py-8">Worker</td>
            <td class="px-16 py-8">Co2 Expression</td>
            <td class="px-16 py-8">&nbsp;</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="step in processSteps" :key="step" class="border-b border-solid border-gray-50 hover:bg-gray-50">
            <td class="px-16 py-8">
              <div class="font-bold truncate max-w-[300px]" :title="step.name">{{ step.name }}</div>
              <div class="text-gray-400">{{ getReadableStepType(step.step_type) }}</div>
            </td>
            <td class="px-16 py-8">{{ processSynonymsLookup[step.prc_acronym] || step.prc_acronym }}</td>
            <td class="px-16 py-8">
              <i v-if="step.restriction == 'organization'" class="fas fa-user-friends" />

              <i v-else-if="step.restriction == 'owner'" class="fas fa-user" />
              <div v-else>-</div>
            </td>

            <td class="px-16 py-8">
              {{ getReadableCalcLvl(step.calc_lvl) }}
              <div v-if="step.calc_expression">{{ step.calc_expression }}</div>
            </td>

            <td class="px-16 py-8">
              <div v-if="step.setup_time">
                <FeedbackValueTimespan :editable="false" :feedback-value="step?.setup_time" />
              </div>
              <div v-else>-</div>
            </td>
            <td class="px-16 py-8">
              <div v-if="step.process_time">
                <FeedbackValueTimespan :editable="false" :feedback-value="step?.process_time" />
              </div>
              <div v-else>-</div>
            </td>
            <td class="px-16 py-8">
              <div v-if="step.unloading_time">
                <FeedbackValueTimespan :editable="false" :feedback-value="step?.unloading_time" />
              </div>
              <div v-else>-</div>
            </td>
            <td class="px-16 py-8 text-right">
              <div v-if="step.fixed_cost && step.fixed_cost > 0">
                {{ step.fixed_cost }}
                <span class="text-gray-500">€</span>
              </div>
              <div v-else>-</div>
            </td>
            <td class="px-16 py-8 text-right">
              <div v-if="step.machine_hourly_rate">
                {{ step.machine_hourly_rate }}
                <span class="text-gray-500">€/h</span>
              </div>
              <div v-else>-</div>
            </td>
            <td class="px-16 py-8 text-right">
              <div v-if="step.staff_time_factor_percent">
                {{ parseInt(step.staff_time_factor_percent) }}
                <span class="text-gray-500">%</span>
              </div>
              <div v-else>-</div>
            </td>
            <td class="px-16 py-8">{{ getReadableWorkerType(step.worker_type) }}</td>
            <td class="px-16 py-8">{{ step.co2_expression }}</td>
            <td class="px-16 py-8 flex">
              <DropdownMenu
                :list-options="dropdownOptions[step.uid]"
                title-icon="fas fa-ellipsis-h"
                close-click-style="always"
                @update-option="handleDropdownSelect($event, step)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <ProcessChainStepFormModal
      :filled-form="stepTemplateToEdit"
      :mode="mode"
      :show="showCreateForm"
      @close="closeModal"
      @created="getProcessSteps"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import DropdownMenu from '@/components/Reusable/DropdownMenu.vue';
import FeedbackValueTimespan from '@/components/Reusable/FeedbackValues/FeedbackValueTimespan.vue';
import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import ProcessChainStepFormModal from '@/views/Library/steps/components/modals/ProcessChainStepFormModal.vue';
import ProcessFilter from '@/views/Library/steps/components/ProcessFilter.vue';
import StepTypeFilter from '@/views/Library/steps/components/StepTypeFilter.vue';

export default {
  name: 'ProcessChainSteps',

  components: {
    StepTypeFilter,
    ProcessChainStepFormModal,
    SparkButton,
    FeedbackValueTimespan,
    ProcessFilter,
    DropdownMenu,
  },

  data() {
    return {
      processSteps: {},
      processStepsCopy: {},
      showCreateForm: false,
      stepTemplateToEdit: {},
      selectedProcesses: [],
      isMounted: false,
      stepTypes: {
        pre: 'Pre-Process',
        main: 'Main Process',
        post: 'Post-Process',
      },

      defaultDropdownOptions: [
        { text: 'Delete Process Step', value: 'delete', icon: 'fas fa-trash', disabled: true },
        { text: 'Edit Process Step', value: 'edit', icon: 'fas fa-pen', disabled: true },
      ],

      ownDropdownOptions: [
        { text: 'Delete Process Step', value: 'delete', icon: 'fas fa-trash', disabled: false },
        { text: 'Edit Process Step', value: 'edit', icon: 'fas fa-pen', disabled: false },
      ],

      dropdownOptions: {},
      mode: 'Create',
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
    ...mapState(['user']),
    ...mapState(['workerTypes', 'calcLvls', 'user', 'expressionOptions', 'organization']),

    ...mapState({
      storeProcesses: 'processes',
    }),

    splitOperators() {
      return ['+', '-', '*', '/', '(', ')', '>', '<', '>=', '<=', '==', '!=', '&&', '||', ' '];
    },

    processSynonyms() {
      return this.organization.process_synonyms;
    },

    processSynonymsLookup() {
      if (!this.processSynonyms) return {};

      const lookup = {};
      Object.values(this.processSynonyms).forEach(synonym => {
        lookup[synonym.original_prc_acronym] = synonym.prc_name;
      });
      lookup['all_processes'] = 'All Processes';
      return lookup;
    },
  },

  mounted() {
    this.getProcessSteps();
    this.isMounted = true;
  },

  methods: {
    handleSelectedTypes(selectedTypes) {
      if (Object.keys(selectedTypes).length > 0) {
        this.processSteps = Object.values(this.processStepsCopy).filter(step => selectedTypes.includes(step.step_type));
      } else {
        this.processSteps = this.processStepsCopy;
      }
    },

    setSelectedProcesses(selectedProcesses) {
      this.selectedProcesses = selectedProcesses;
      this.handleSelectedProcesses();
    },

    handleSelectedProcesses() {
      if (Object.keys(this.selectedProcesses).length > 0) {
        const selectedPrcAcronyms = this.selectedProcesses.map(process => process.original_prc_acronym);

        this.processSteps = Object.values(this.processStepsCopy).filter(step =>
          selectedPrcAcronyms.includes(step.prc_acronym)
        );
      }
    },

    calculateDropdownOptions() {
      this.processSteps.forEach(step => {
        if (step.restriction == 'owner' || (step.restriction == 'organization' && this.user.user_role == 'manager')) {
          this.dropdownOptions[step.uid] = this.ownDropdownOptions;
        } else {
          this.dropdownOptions[step.uid] = this.defaultDropdownOptions;
        }
      });
    },

    getReadableCalcLvl(calcLvl) {
      if (this.calcLvls) {
        return Object.values(this.calcLvls).find(obj => {
          return obj.value === calcLvl;
        }).text;
      }
    },

    getReadableWorkerType(workerType) {
      if (this.workerTypes) {
        return Object.values(this.workerTypes).find(obj => {
          return obj.value === workerType;
        }).text;
      }
    },

    getReadableStepType(stepType) {
      if (this.stepTypes) {
        return this.stepTypes[stepType];
      }
    },

    getProcessName(prcAcronym) {
      if (prcAcronym === null) {
        prcAcronym = 'all_processes';
      }
      if (this.storeProcesses) {
        const process = Object.values(this.storeProcesses).find(p => p.value === prcAcronym);
        return process ? process.text : 'Unknown Process';
      }
      return 'Unknown Process';
    },

    handleDropdownSelect(event, step) {
      if (event.value === 'delete') {
        this.deleteProcessStepTemplate(step);
      } else if (event.value === 'edit') {
        this.editProcessStepTemplate(step);
      }
    },

    addNewProcessChainStepTemplate() {
      this.showCreateForm = true;
      this.stepTemplateToEdit = { unloading_time: 0, setup_time: 0, process_time: 0 };
      this.mode = 'Create';
    },

    closeModal() {
      this.showCreateForm = false;
      this.stepTemplateToEdit = { unloading_time: 0, setup_time: 0, process_time: 0 };
      this.getProcessSteps();
    },

    editProcessStepTemplate(step) {
      this.mode = 'Edit';
      this.stepTemplateToEdit = { ...step };
      this.stepTemplateToEdit = this.processExpressionsAndRules(this.stepTemplateToEdit, 'calc_expression');
      this.stepTemplateToEdit = this.processExpressionsAndRules(this.stepTemplateToEdit, 'co2_expression');
      this.showCreateForm = true;
    },

    processExpressionsAndRules(step, field) {
      const expression = step[field] || '';
      let expressionWords = [];
      let currentWord = '';

      // Iterate through each character in the expression
      for (let i = 0; i < expression.length; i++) {
        const char = expression[i];

        // Check if current character is a space
        if (char === ' ') {
          if (currentWord) {
            expressionWords.push(currentWord);
            currentWord = '';
          }
          continue;
        }

        // Check if current character is an operator
        const twoCharOperator = expression.slice(i, i + 2);
        if (this.splitOperators.includes(twoCharOperator)) {
          // Handle two-character operators
          if (currentWord) {
            expressionWords.push(currentWord);
            currentWord = '';
          }
          expressionWords.push(twoCharOperator);
          i++; // Skip next character since we used it
          continue;
        }

        if (this.splitOperators.includes(char)) {
          // Handle single-character operators
          if (currentWord) {
            expressionWords.push(currentWord);
            currentWord = '';
          }
          expressionWords.push(char);
          continue;
        }

        // Add character to current word
        currentWord += char;
      }

      // Add any remaining word
      if (currentWord) {
        expressionWords.push(currentWord);
      }

      // Filter out empty strings
      expressionWords = expressionWords.filter(word => word.trim() !== '');
      return {
        ...step,
        [field + '_words']: expressionWords,
      };
    },

    async getProcessSteps() {
      this.axiosInstance
        .get(`/api/v1/process-step-templates-all/`)
        .then(response => {
          this.processSteps = response.data;
          Object.keys(this.processSteps).forEach(key => {
            if (this.processSteps[key].prc_acronym === null) {
              this.processSteps[key].prc_acronym = 'all_processes';
            }
          });
          this.processStepsCopy = JSON.parse(JSON.stringify(this.processSteps));
          this.calculateDropdownOptions();
          this.handleSelectedProcesses();
        })
        .catch(error => {
          console.log(JSON.stringify(error));
        });
    },

    async deleteProcessStepTemplate(step) {
      let name = step.name;
      await this.axiosInstance.delete(`/api/v1/process-step-template/${step.uid}`).then(() => {
        this.getProcessSteps();
        this.$root.notify('success', name, 'Process Step Deleted');
      });
    },
  },
};
</script>
