<template>
  <div class="flex mx-20"><PriceCard /></div>
</template>

<script>
import PriceCard from './PriceCard.vue';

export default {
  name: 'PricePopup',

  components: { PriceCard },
};
</script>