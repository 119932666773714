<template>
  <div>
    <teleport v-if="isMounted" to="#sub-navigation__action-buttons">
      <div class="flex items-center justify-center gap-8 h-56">
        <AddBatchButton />
      </div>
    </teleport>
    <BatchTable />
  </div>
</template>

<script>
import AddBatchButton from './components/AddBatchButton.vue';
import BatchTable from './components/BatchTable.vue';

export default {
  name: 'Batch',

  components: {
    BatchTable,
    AddBatchButton,
  },

  data() {
    return { isMounted: false };
  },

  computed: {},

  mounted() {
    this.isMounted = true;
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.flex-container {
  display: flex;
  margin: var(--grid-outer-whitespace);
  margin-top: 0px;
  padding-bottom: 8px;
}
</style>
