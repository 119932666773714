<template>
  <div class="bg-white overflow-hidden border border-gray-50 rounded-8">
    <table class="w-full">
      <thead class="text-gray-600 border-b border-gray-50 bg-gray-25">
        <tr>
          <th
            v-for="column in columns"
            :key="column.key"
            class="px-12 py-8 text-start"
            :class="{ 'cursor-pointer': column.sortable }"
            @click="column.sortable && handleSort(column.key)"
          >
            <div class="flex items-center gap-4">
              <span class="text-13 text-gray-500">{{ column.title }}</span>
              <template v-if="column.sortable">
                <div v-if="sortKey === column.key" class="flex flex-col">
                  <i 
                    class="fas fa-caret-up text-9"
                    :class="{ 'text-primary-500': !sortDesc }"
                  />
                  <i 
                    class="fas fa-caret-down text-9 -mt-2"
                    :class="{ 'text-primary-500': sortDesc }"
                  />
                </div>
              </template>
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="divide-y divide-gray-50">
        <tr v-for="(row, index) in sortedRows" :key="index">
          <td 
            v-for="column in columns" 
            :key="column.key"
            class="px-12 py-8 text-13"
          >
            <slot :name="`cell-${column.key}`" :value="row[column.key]" :row="row">
              {{ formatValue(row[column.key], column.unit) }}
            </slot>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'SparkSmallTable',

  props: {
    columns: {
      type: Array,
      required: true,
      // Example: [{ key: 'name', title: 'Name', sortable: true, unit: '€' }]
    },
    rows: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      sortKey: '',
      sortDesc: false,
    };
  },

  computed: {
    sortedRows() {
      if (!this.sortKey) return this.rows;

      const sorted = [...this.rows].sort((a, b) => {
        const aVal = a[this.sortKey];
        const bVal = b[this.sortKey];

        if (typeof aVal === 'number' && typeof bVal === 'number') {
          return this.sortDesc ? bVal - aVal : aVal - bVal;
        }

        return String(aVal).localeCompare(String(bVal)) * (this.sortDesc ? -1 : 1);
      });

      return sorted;
    },
  },

  methods: {
    handleSort(key) {
      if (this.sortKey === key) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortKey = key;
        this.sortDesc = false;
      }
    },

    formatValue(value, unit) {
      if (value === null || value === undefined) return '';
      return unit ? `${value} ${unit}` : value;
    },
  },
};
</script> 