<template>
  <div class="flex w-full p-20 gap-32">
    <teleport v-if="isMounted" to="#sub-navigation__action-buttons">
      <div class="flex items-center gap-8 justify-end">
        <div class="text-18">Want to quote instantly?</div>
        <SparkButton variant="primary" @click="rejectQuote">
          <span class="text-18">Book a Demo and start your 14 day free trial</span>
        </SparkButton>
      </div>
    </teleport>
    <div class="flex flex-col gap-32 flex-1">
      <div>
        <h2 class="text-18 font-semibold mb-8">Client information</h2>
        <div class="bg-white rounded-8 border border-gray-50">
          <div class="flex flex-col divide-y divide-gray-50">
            <div class="grid grid-cols-[200px_1fr] items-start px-20 py-20 first:pt-20 last:pb-20">
              <div>
                <div class="text-13">Company</div>
                <div class="text-gray-400 text-11">Description in one line</div>
              </div>
              <div class="flex items-center gap-8">
                <img src="@/assets/img/Logo_Transparent.png" alt="Company Logo" class="h-24 w-24" />
                <div class="flex flex-col">
                  <div class="text-13">Siemens Mobility</div>
                  <div class="text-gray-400 text-11">Secondary information</div>
                </div>
              </div>
            </div>

            <div class="grid grid-cols-[200px_1fr] items-start px-20 py-20">
              <div>
                <div class="text-13">Contact information</div>
                <div class="text-gray-400 text-11">Description in one line</div>
              </div>
              <div class="flex flex-col">
                <div class="text-13">Maggie Dibbert</div>
                <div class="text-gray-400 text-11">martin.leback@hotmail.com</div>
              </div>
            </div>

            <div class="grid grid-cols-[200px_1fr] items-start px-20 py-20">
              <div>
                <div class="text-13">Address</div>
                <div class="text-gray-400 text-11">Description in one line</div>
              </div>
              <div class="flex flex-col">
                <div class="text-13">731-717 8th Ave, New York, NY 10036, USA</div>
                <div class="text-gray-400 text-11">Secondary information</div>
              </div>
            </div>

            <div class="grid grid-cols-[200px_1fr] items-start px-20 py-20 last:pb-20">
              <div>
                <div class="text-13">Notes</div>
                <div class="text-gray-400 text-11">Description in one line</div>
              </div>
              <div class="text-13">
                Non officia est delectus et illo. Quisquam incidunt occaecati qui voluptates amet error asperiores sed.
                Minima blanditiis vero omnis ipsum assumenda praesentium molestiae ea. Quia nostrum et nulla neque
                molestias veritatis officiis enim incidunt. Voluptates sit tempora quas molestiae sed.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h2 class="text-16 font-semibold mb-8">Parts</h2>
        <div class="bg-white rounded-8 border border-gray-50">
          <table class="w-full">
            <thead>
              <tr class="text-left text-gray-600 border-b border-gray-50">
                <th class="py-8 px-20 font-normal w-[40%] text-13">Parts</th>
                <th class="py-8 px-20 font-normal w-[15%] text-13">Quantity</th>
                <th class="py-8 px-20 font-normal w-[25%] text-13">Material</th>
                <th class="py-8 px-20 font-normal w-[20%] text-13" />
              </tr>
            </thead>
            <tbody>
              <tr v-for="part in parts" :key="part.id" class="border-b border-gray-50">
                <td class="py-12 px-20">
                  <div class="flex items-center gap-8">
                    <img :src="part.image" :alt="part.name" class="w-40 h-40 rounded-6" />
                    <span class="text-13">{{ part.name }}</span>
                  </div>
                </td>
                <td class="py-12 px-20">
                  <span class="text-13">{{ part.quantity }}</span>
                </td>
                <td class="py-12 px-20">
                  <span class="text-13">{{ part.material }}</span>
                </td>
                <td class="py-12 px-20 text-right">
                  <SparkButton variant="outlined" size="small">
                    <i class="fas fa-download mr-4" />
                    <span class="text-13">Download files</span>
                  </SparkButton>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="w-[500px]">
      <h2 class="text-16 font-semibold mb-8">Your quote</h2>
      <div class="bg-white rounded-8 border border-gray-50 p-20 flex flex-col h-full">
        <div class="flex flex-col gap-16 flex-1">
          <div>
            <div class="text-gray-600 mb-4 text-11">Quote</div>
            <div
              class="border-2 border-dashed border-gray-100 rounded-8 p-16 min-h-200 flex flex-col items-center justify-center relative cursor-pointer"
              @click="triggerFileInput"
            >
              <input
                ref="fileInput"
                type="file"
                class="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                accept=".stl,.pdf,.jpeg,.jpg,.png,.tiff"
                multiple
                @change="handleFileUpload"
              />
              <div class="flex flex-col items-center justify-center gap-12">
                <img :src="FileUploadSVG" width="28" height="28" alt="file-upload" />
                <div class="text-gray-1000 font-semibold">Drag and drop or Browse</div>
                <div class="text-gray-300 text-11">STL, PDF, JPEG, PNG, TIFF</div>
                <div class="flex gap-16 mt-20 text-gray-1000 text-11 items-center font-semibold">
                  <div class="flex flex-col gap-8 items-center">
                    <img :src="PackageSVG" width="28" height="28" alt="map" />
                    <span>CAD Files</span>
                  </div>
                  <div class="flex flex-col gap-8 items-center">
                    <img :src="MapSVG" width="28" height="28" alt="package" />
                    <span>Technical Drawings</span>
                  </div>
                  <div class="flex flex-col gap-8 items-center">
                    <img :src="FileSVG" width="28" height="28" alt="file-upload" />
                    <span>Other Files</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex-1">
            <div class="text-gray-600 mb-4 text-11">Notes</div>
            <textarea class="w-full border border-gray-100 rounded-6 p-8 min-h-100" />
          </div>

          <div class="flex justify-between mt-auto pt-16">
            <div>
              <SparkButton custom="text-red-500" variant="plain" @click="rejectQuote">
                <span class="text-13">Reject Quote</span>
              </SparkButton>
            </div>
            <div class="flex gap-16 items-center">
              <SparkButton variant="plain" @click="cancel">
                <span class="text-13">Cancel</span>
              </SparkButton>
              <SparkButton variant="secondary" @click="submit">
                <span class="text-13">Submit</span>
              </SparkButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileSVG from '@/assets/icons/file.svg';
import MapSVG from '@/assets/icons/map.svg';
import PackageSVG from '@/assets/icons/package.svg';
import FileUploadSVG from '@/assets/icons/prp/file-upload.svg';
import defaultImage from '@/assets/img/Logo_Transparent.png';
import SparkButton from '@/components/SparkComponents/SparkButton.vue';

export default {
  name: 'Quotation',

  components: {
    SparkButton,
  },

  data() {
    return {
      isMounted: false,
      parts: [
        {
          id: 1,
          name: 'Deleniti',
          quantity: 6,
          material: 'Voluptis',
          image: defaultImage,
        },
        {
          id: 2,
          name: 'Accusamus',
          quantity: 5,
          material: 'Molestias',
          image: defaultImage,
        },
        {
          id: 3,
          name: 'Vitae',
          quantity: 3,
          material: 'Magni',
          image: defaultImage,
        },
      ],

      selectedFiles: [],
      FileUploadSVG,
      PackageSVG,
      MapSVG,
      FileSVG,
    };
  },

  mounted() {
    this.isMounted = true;
  },

  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    handleFileUpload(event) {
      this.selectedFiles = Array.from(event.target.files);
    },

    rejectQuote() {},

    cancel() {},

    submit() {},
  },
};
</script>
