<template>
  <div v-if="updatedCostItems.length > 0" class="order-summary border border-gray-100 rounded-8 bg-white">
    <div
      v-for="(option, index) in updatedCostItems"
      :key="index"
      class="flex justify-end gap-100 border-b border-gray-100 last-of-type:border-0"
      :class="{
        'text-18 font-semibold': costItems.length - 1 === index || index === 0,
      }"
    >
      <div class="py-12" v-text="option.name" />
      <div class="text-primary-500 py-12 px-24" v-text="`${option.value}`" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'OrderSummary',

  props: {
    costItems: { type: Array, default: () => [] },
  },

  data() {
    return {
      updatedCostItems: [],
    };
  },

  computed: {
    ...mapState(['user', 'organization']),

    showPrice() {
      return this.organization?.prp_show_price;
    },
  },

  mounted() {
    if (!this.showPrice && this.costItems.length === 1 && this.costItems[0].name === 'Total Price') {
      this.updatedCostItems = [];
    } else {
      this.updatedCostItems = this.costItems;
    }
  },
};
</script>
