<template>
  <div class="relative text-15">
    <div
      class="flex justify-between items-center p-8 rounded-8 border border-gray-300 cursor-pointer"
      @click="toggleDropdown"
    >
      <span class="text-15 px-8">Technology</span>
      <span class="text-15 px-8 text-primary-500">{{ selectedTechsText }}</span>
      <i class="fas fa-chevron-down" />
    </div>
    <div
      v-if="isOpen"
      class="absolute w-[300px] top-full rounded-8 right-0 bg-white border border-gray-100 shadow-md z-10"
    >
      <div class="max-h-[500px] overflow-y-auto">
        <div
          v-for="tech in technologies"
          :key="tech.value"
          class="flex items-center px-8 py-4 hover:bg-gray-50 hover:cursor-pointer"
          @click="selectTech(tech.value)"
        >
          <input
            :id="tech.value"
            :checked="selectedTechs.includes(tech.value)"
            type="checkbox"
            :value="tech.value"
            class="mr-2 rounded-4 border border-gray-300 text-primary-500 focus:ring-primary-600 focus:ring-offset-0"
            @change="selectTech(tech.value)"
          />
          <div class="flex items-center p-2">{{ tech.text }}</div>
        </div>
      </div>
    </div>
    <div
      v-if="selectedTechs.length > 0"
      class="absolute right-0 mb-2 hover:cursor-pointer hover:text-primary-500 text-11"
      @click="clearSelectedTechs"
    >
      <span class="mb-4">Clear</span>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'TechFilter',

  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
  },

  emits: ['update-selected-techs', 'update:modelValue'],

  setup(props, { emit }) {
    const store = useStore();
    const technologies = computed(() => {
      const curTechs = store.state.curTechs || {};
      return Object.values(curTechs);
    });

    return {
      technologies,
    };
  },

  data() {
    return {
      isOpen: false,
      selectedTechs: [],
    };
  },

  computed: {
    selectedTechsText() {
      if (this.selectedTechs.length === 0) {
        return '';
      } else if (this.selectedTechs.length === 1) {
        const selectedTech = this.technologies.find(tech => tech.value === this.selectedTechs[0]);
        return '|  ' + (selectedTech?.text || '');
      } else {
        const firstTech = this.technologies.find(tech => tech.value === this.selectedTechs[0]);
        const remainingCount = this.selectedTechs.length - 1;
        return '|  ' + `${firstTech?.text || ''}, +${remainingCount} more`;
      }
    },
  },

  watch: {
    modelValue: {
      immediate: true,
      handler(newVal) {
        this.selectedTechs = [...newVal];
      },
    },
  },

  mounted() {
    this.selectedTechs = this.technologies.map(tech => tech.value);
    this.$emit('update-selected-techs', this.selectedTechs);
  },

  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },

    selectTech(techValue) {
      const index = this.selectedTechs.indexOf(techValue);
      if (index > -1) {
        this.selectedTechs.splice(index, 1);
      } else {
        this.selectedTechs.push(techValue);
      }

      this.$emit('update:modelValue', this.selectedTechs);
      this.$emit('update-selected-techs', this.selectedTechs);
      this.isOpen = false;
    },

    clearSelectedTechs() {
      this.selectedTechs = [];
      this.$emit('update-selected-techs', this.selectedTechs);
      this.isOpen = false;
    },
  },
};
</script>
