<template>
  <div>
    <SparkDate title="Created Date" :input-date-value="part.created_date" @date-changed="createdDateChanged($event)" />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import SparkDate from '@/components/SparkComponents/SparkDate.vue';

export default {
  name: 'CreatedDate',

  components: {
    SparkDate,
  },

  emits: ['created-date-changed'],

  computed: {
    ...mapState(['part']),
  },

  methods: {
    ...mapMutations(['updatePart']),

    createdDateChanged(value) {
      if (!value) return;
      this.$emit('created-date-changed', { created: value });
      this.updatePart({ created_date: value });
    },
  },
};
</script>

<style scoped>
:deep(.created-date-picker input) {
  @apply block border h-32 focus:border-2 px-12 pb-2 pt-4 w-full text-16 text-gray-900 bg-white rounded-6 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-primary-600;
}

/* Center the calendar icon */
:deep(.created-date-picker .dp__input_icon) {
  display: none;
}

:deep(.created-date-picker .dp__clear_icon) {
  display: none;
}
</style>
