<template>
  <div class="relative" :class="{ [custom]: custom }">
    <label
      v-if="!!label || $slots.label"
      class="text-13 text-gray-500 z-10"
      :class="{
        'absolute z-10 text-13 px-2 text-gray-500 ml-8 bg-white duration-300 transform origin-[0] -top-12 start-1':
          animateLabel,
      }"
      :for="uid"
      data-test="spark-select-label"
    >
      <slot name="label">
        {{ label }}
      </slot>
    </label>
    <div class="relative">
      <div class="relative">
        <input
          :id="uid"
          v-model="searchQuery"
          class="block border mb-8 h-32 focus:!h-32 hover:!border-primary-600 px-12 pb-2 pt-4 w-full text-16 text-gray-900 bg-white rounded-6 border-1 border-gray-200 focus:outline-none focus:ring-0 focus:border-primary-600 peer pr-32"
          :class="{
            '!border-gray-200  !bg-gray-50 !text-gray-300': disabled,
            '!text-gray-300': !searchQuery && !!placeholder,
            '!border-red-500': !!hasErrors,
          }"
          :disabled="disabled"
          :name="name"
          :placeholder="placeholder"
          :autocomplete="autocomplete"
          data-test="spark-select-input"
          @focus="handleFocus"
          @blur="handleBlur"
        />

        <div class="absolute right-12 top-8 h-full flex items-center pointer-events-none">
          <i class="fas fa-chevron-down text-16 text-gray-500" />
        </div>

        <div
          v-show="isOpen"
          class="absolute z-50 w-full top-full bg-white border border-gray-200 rounded-4 max-h-200 overflow-y-auto"
        >
          <div
            v-for="(option, i) in filteredItems"
            :key="i"
            class="px-12 py-8 hover:bg-primary-50 cursor-pointer text-16"
            :class="{ 'bg-gray-200': option.value === inputField }"
            @click="selectOption(option)"
          >
            {{ option.label }}
          </div>
          <div v-if="filteredItems.length === 0" class="px-12 py-8 text-gray-500 text-16">No results found</div>
        </div>
      </div>
    </div>
    <span v-if="error" class="text-11 absolute left-0 top-full pt-4 text-red-500" v-text="error" />
  </div>
</template>

<script>
import { computed, ref, watch, onMounted, onUnmounted } from 'vue';

export default {
  name: 'SparkSelect',

  props: {
    autocomplete: { type: HTMLInputElement['autocomplete'], default: 'off' },
    disabled: { type: Boolean },
    label: { type: String, default: '' },
    animateLabel: { type: Boolean },
    name: { type: String, required: true },
    placeholder: { type: String, default: '' },
    options: { type: Array, required: true },
    custom: { type: String, default: '' },
    modelValue: { type: [Boolean, Number, String, Object, null], default: '' },
    error: { type: String, default: '' },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const searchQuery = ref('');
    const isOpen = ref(false);
    const inputField = computed({
      get: () => props.modelValue,
      set: value => emit('update:modelValue', value),
    });

    const uid = computed(() => `input-${Math.random().toString(36).substring(2, 9)}`);

    const hasErrors = ref(false);
    watch(
      () => props.error,
      () => {
        hasErrors.value = props.error ? true : false;
      },
      { immediate: true }
    );

    const items = computed(() => {
      return props.options.map(item => {
        if (typeof item === 'object') return item;
        return { label: item, value: item };
      });
    });

    const filteredItems = computed(() => {
      if (!searchQuery.value) return items.value;
      const query = searchQuery.value.toLowerCase();
      return items.value.filter(item => item.label.toLowerCase().includes(query));
    });

    const handleFocus = () => {
      searchQuery.value = '';
      isOpen.value = true;
    };

    const handleBlur = () => {
      setTimeout(() => {
        const selected = items.value.find(item => item.value === inputField.value);
        if (selected) {
          searchQuery.value = selected.label;
        }
        isOpen.value = false;
      }, 200);
    };

    const selectOption = option => {
      inputField.value = option.value;
      searchQuery.value = option.label;
      isOpen.value = false;
    };

    watch(
      inputField,
      newValue => {
        const selected = items.value.find(item => item.value === newValue);
        if (selected) {
          searchQuery.value = selected.label;
        } else {
          searchQuery.value = '';
        }
      },
      {
        immediate: true,
      }
    );

    const handleClickOutside = event => {
      if (!event.target.closest(`#${uid.value}`)) {
        isOpen.value = false;
      }
    };

    onMounted(() => {
      document.addEventListener('click', handleClickOutside);
    });

    onUnmounted(() => {
      document.removeEventListener('click', handleClickOutside);
    });

    return {
      inputField,
      items,
      uid,
      hasErrors,
      searchQuery,
      isOpen,
      filteredItems,
      selectOption,
      handleFocus,
      handleBlur,
    };
  },
};
</script>
