<template>
  <div class="mx-10">
    <div v-if="hasOrderDescription" class="label">PRP Order Description</div>
    <textarea
      v-if="hasOrderDescription"
      v-model="prpOrderDescription"
      name="info"
      disabled
      class="w-full h-100 rounded-6 border border-gray-200"
    />
    <div class="text-13 text-gray-500 m-0 p-0">Description</div>
    <textarea
      v-model.lazy="storedInfo"
      class="w-full h-100 rounded-6 border border-gray-200 m-0 p-2 focus:border-primary-600 focus:outline-none focus:ring-1 focus:ring-primary-600 text-13"
      name="info"
      :title="lockedForUser ? lockedTitle : ''"
      :disabled="lockedForUser"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
export default {
  name: 'Description',

  emits: ['info'],

  data() {
    return {
      prpTextCategoriesText: '',
      prpComboCategoriesText: '',
    };
  },

  computed: {
    ...mapState(['part']),
    ...mapState('application', ['lockedTitle', 'axiosInstance']),
    ...mapGetters(['lockedForUser']),

    storedInfo: {
      get() {
        return this.part.info;
      },

      set(value) {
        this.$emit('info', { info: value });
      },
    },

    hasOrderDescription() {
      return this.prpTextCategoriesText || this.prpComboCategoriesText;
    },

    prpOrderDescription() {
      return `PRP Order Description \n${this.prpTextCategoriesText}\n${this.prpComboCategoriesText}`;
    },
  },

  mounted() {
    if (this.part.part_id) {
      this.axiosInstance.get(`api/v1/prp-order-per-part/${this.part.part_id}/`).then(response => {
        this.prpTextCategoriesText = this.convertObjectToText(response.data.prp_text_categories);
        this.prpComboCategoriesText = this.convertObjectToText(response.data.prp_combo_categories);
      });
    }
  },

  methods: {
    convertObjectToText(categories) {
      let result = '';
      for (let key in categories) {
        if (Object.prototype.hasOwnProperty.call(categories, key)) {
          result += `• ${categories[key].label}: ${categories[key].value}\n`;
        }
      }
      return result;
    },
  },
};
</script>
