<template>
  <div class="h-[300px]">
    <div v-if="loading" class="flex items-center justify-center h-full">
      <SparkSpinner />
    </div>
    <div v-else class="h-full pb-40">
      <apexchart 
        ref="priceChart"
        type="area" 
        :options="chartOptions" 
        :series="series"
        width="100%"
        height="260"
      />
    </div>
  </div>
</template>

<script>
import SparkSpinner from '@/components/SparkComponents/SparkSpinner.vue';

export default {
  name: 'PriceChart',

  components: {
    SparkSpinner,
  },

  props: {
    xValues: {
      type: Array,
      default: () => [],
    },
    yValues: {
      type: Array,
      default: () => [],
    },
    loading: Boolean,
  },

  computed: {
    series() {
      return [{
        name: 'Price per part',
        data: this.xValues.map((x, index) => ({
          x,
          y: this.yValues[index],
        })),
      }];
    },

    chartOptions() {
      return {
        chart: {
          type: 'area',
          fontFamily: 'Poppins, Arial, sans-serif',
          toolbar: {
            show: false,
          },
          height: 260,
        },
        colors: ['#B8CE45'], // primary-500
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2,
          curve: 'smooth',
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.45,
            opacityTo: 0.05,
          },
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5,
          },
        },
        xaxis: {
          type: 'numeric',
          logarithmic: true,
          title: {
            text: 'Lot size',
            style: {
              fontSize: '13px',
              fontFamily: 'Poppins',
              color: '#838A93',
            },
          },
          labels: {
            formatter: (value) => Math.round(value),
          },
        },
        yaxis: {
          title: {
            text: 'Price per part (€)',
            style: {
              fontSize: '13px',
              fontFamily: 'Poppins',
              color: '#838A93',
            },
          },
          labels: {
            formatter: (value) => `${value.toFixed(2)} €`,
          },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.chart {
  margin-top: 1px;
  border: 1px solid #e4e5e8;
  border-radius: 3px;
  width: 100%;
}
</style>
