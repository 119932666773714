<template>
  <div>
    <i v-if="partChecked" class="far fa-check-square text-16" />
    <i v-else class="far fa-square text-16" />
  </div>
</template>

<script>
export default {
  name: 'ActionCheckbox',

  props: {
    checkedParts: {
      type: Object,
      default() {
        return {};
      },
    },

    partId: { type: String, default: '' },
  },

  data() {
    return {
      partChecked: false,
    };
  },

  watch: {
    checkedParts: {
      handler() {
        this.getCheckboxState();
      },

      deep: true,
      immediate: true,
    },
  },

  methods: {
    getCheckboxState() {
      if (Object.prototype.hasOwnProperty.call(this.checkedParts, this.partId)) {
        this.partChecked = true;
      } else {
        this.partChecked = false;
      }
    },
  },
};
</script>
