<template>
  <SparkModal :model-value="show" dismissable @update:model-value="close">
    <template #header>
      <div class="flex justify-between items-center">
        <div class="font-semibold text-18 mb-16">Add Part</div>
      </div>
    </template>
    <template #default>
      <Upload />
    </template>
  </SparkModal>
</template>

<script>
import { mapState } from 'vuex';

import SparkModal from '../../../../components/SparkComponents/SparkModal.vue';
import Upload from '../Upload.vue';

export default {
  name: 'FileUploadModal',

  components: { SparkModal, Upload },

  props: { show: Boolean },

  emits: ['close'],

  computed: {
    ...mapState(['partUploadError']),
  },

  watch: {
    partUploadError(error) {
      if (error) {
        this.close();
      }
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
