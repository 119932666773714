<template>
  <SparkModal dismissable :model-value="show" @update:model-value="close">
    <div class="text-16 font-bold pb-20">{{ mode }} Category</div>
    <SparkInput v-model="newCategory.label" name="label" label="Name" />
    <div class="text-gray-400 pt-16">Properties</div>
    <div class="flex flex-col border border-gray-50 pt-8 rounded-6">
      <div class="flex justify-between p-8 border-b border-gray-50">
        <div>Required</div>
        <SparkSwitch v-model="newCategory.required" name="is_required" />
      </div>
    </div>
    <div class="pt-16">
      <div class="text-gray-400">Type</div>
      <SparkSelect
        v-model="newCategory.category_type"
        name="category_type"
        :options="categoryTypeOptions"
        :disabled="mode === 'Edit'"
      />
    </div>

    <div v-if="newCategory.category_type === 'combo'" class="pt-16">
      <div class="text-gray-400">Options</div>
      <div class="border border-gray-50 rounded-6 flex flex-col gap-8">
        <div v-for="(item, index) in items" :key="item" class="border-b border-gray-50 p-8">
          <div class="flex justify-between items-center">
            {{ item.value }}
            <i
              class="pr-16 text-gray-600 hover:text-gray-900 cursor-pointer fas fa-trash"
              @click="deleteComboCategoryItem(item.uid, index)"
            />
          </div>
        </div>
        <div class="flex items-center justify-between gap-8">
          <SparkInput
            v-model="newCategoryItem"
            placeholder="Add value"
            custom="border-none text-13"
            class="pl-8"
            name="options"
            label=""
            @keyup.enter="addItemAndCheckCategory"
          />
          <i
            v-if="newCategoryItem != ''"
            class="pr-24 text-gray-600 hover:text-gray-900 cursor-pointer fas fa-check"
            @click="addItemAndCheckCategory"
          />
        </div>
      </div>
    </div>

    <div class="flex justify-end gap-8 pt-16">
      <SparkButton variant="outlined" @click="close">Cancel</SparkButton>
      <SparkButton variant="secondary" @click="saveCategory">Save</SparkButton>
    </div>
  </SparkModal>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkInput from '@/components/SparkComponents/SparkInput.vue';
import SparkModal from '@/components/SparkComponents/SparkModal.vue';
import SparkSelect from '@/components/SparkComponents/SparkSelect.vue';
import SparkSwitch from '@/components/SparkComponents/SparkSwitch.vue';

export default {
  name: 'AddEditPrpCategoryModal',

  components: { SparkModal, SparkButton, SparkInput, SparkSwitch, SparkSelect },

  props: {
    show: { type: Boolean },
    mode: { type: String, default: 'Add' },
    categoryToEdit: { type: Object, default: () => ({}) },
    updateKey: { type: Number, default: 0 },
  },

  emits: ['close'],

  data() {
    return {
      modalMode: this.mode,
      newCategory: {
        label: '',
        required: false,
        items: [],
      },

      emptyCategory: {
        label: '',
        required: false,
        items: [],
      },

      items: [],

      newCategoryItem: '',
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
    ...mapState(['categoryTypes']),

    categoryTypeOptions() {
      return Object.entries(this.categoryTypes).map(([key, value]) => ({
        value: value.value,
        label: value.text,
      }));
    },
  },

  watch: {
    updateKey: {
      handler() {
        if (this.mode === 'Edit') {
          this.newCategory = JSON.parse(JSON.stringify(this.categoryToEdit));
          this.items = JSON.parse(JSON.stringify(this.categoryToEdit?.items || []));
        }
      },

      deep: true,
    },

    show: {
      handler(newValue) {
        this.modalMode = this.mode;
        if (!newValue) {
          this.modalMode = 'Add';
          this.items = [];
        }
      },

      immediate: true,
    },
  },

  methods: {
    ...mapActions('prp', ['fetchPrpCategoryList']),
    close() {
      this.$emit('close');
      this.newCategory = this.emptyCategory;
      this.newCategoryItem = '';
    },

    deleteComboCategoryItem(itemuid, index) {
      if (itemuid != 'new_item') {
        this.axiosInstance.delete(`/api/v1/prp-combo-category-item/${itemuid}`).then(() => {
          this.fetchPrpCategoryList();
        });
      }

      this.items.splice(index, 1);
    },

    addItemAndCheckCategory() {
      this.newCategory.items.push({ uid: 'new_item', value: this.newCategoryItem });
      this.items.push({ uid: 'new_item', value: this.newCategoryItem });
      this.newCategoryItem = '';
    },

    saveCategory() {
      if (this.mode === 'Edit') {
        if (this.newCategory.category_type === 'combo') {
          this.updateComboCategory(this.newCategory, this.items);
        } else if (
          this.newCategory.category_type === 'text' ||
          this.newCategory.category_type === 'date' ||
          this.newCategory.category_type === 'number'
        ) {
          this.updateTextCategory(this.newCategory.uid, this.newCategory);
        }
      } else if (this.mode === 'Add') {
        if (this.newCategory.category_type === 'combo') {
          this.addComboCategory();
        } else if (
          this.newCategory.category_type === 'text' ||
          this.newCategory.category_type === 'date' ||
          this.newCategory.category_type === 'number'
        ) {
          this.addTextCategory();
        }
      }
      this.fetchPrpCategoryList();
    },

    async addComboCategory() {
      const formData = {
        category_label: this.newCategory.label,
        required: this.newCategory.required,
        items: this.items,
      };
      await this.axiosInstance.post('/api/v1/prp-combo-category-item/', formData).then(() => {
        this.fetchPrpCategoryList();
        this.close();
      });
    },

    async updateComboCategory(category) {
      let formData = {
        category_label: category.label,
        required: category.required,
      };
      await this.axiosInstance.put(`/api/v1/prp-combo-category/${category.uid}/`, formData);

      if (Object.values(this.items).some(item => item.uid === 'new_item')) {
        const promises = this.items
          .filter(item => item.uid === 'new_item')
          .map(item => {
            const itemFormData = { ...this.newCategory, category_label: category.label, item: item.value };
            return this.axiosInstance.post(`/api/v1/prp-combo-category-item/`, itemFormData);
          });

        await Promise.all(promises)
          .then(() => {
            this.$nextTick(async () => {
              await this.fetchPrpCategoryList();
            });
          })
          .catch(error => {
            console.error('Error updating combo category item:', error);
          });
      }
      this.$nextTick(() => {
        this.fetchPrpCategoryList();
        this.$getUser(this.$keycloak, this.axiosInstance, this.$store);
      });
      this.close();
    },

    async addTextCategory() {
      const formData = {
        category_label: this.newCategory.label,
        required: this.newCategory.required,
      };
      await this.axiosInstance
        .post('/api/v1/prp-text-category/', formData)
        .then(() => {
          this.close();
        })
        .catch(error => {
          console.error('Error adding text category:', error);
        });
      this.fetchPrpCategoryList();
    },

    async updateTextCategory(uid, category) {
      await this.axiosInstance.put(`/api/v1/prp-text-category/${uid}/`, category).then(() => {
        this.fetchPrpCategoryList();
        this.close();
      });
    },
  },
};
</script>
