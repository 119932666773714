<template>
  <div class="part-library">
    <div class="sidebar-title">
      <span class="heading">
        <i
          class="chevron-icon"
          :class="[filterSideBarExpanded ? 'fas fa-circle-chevron-left fa-xl' : 'fas fa-circle-chevron-right fa-xl']"
          @click="setFIlterSideBarExpanded"
        />
      </span>
      <transition name="accordion" @enter="start" @after-enter="end" @before-leave="start" @after-leave="end">
        <div v-show="filterSideBarExpanded">
          <div class="library-header">
            <div class="part-library__action-buttons">
              <div class="filter-header">
                <div class="flex justify-between items-center w-full">
                  <span class="filter icon-button" @click="filtering"><i class="fas fa-filter" />Apply</span>
                  <Reset @resetting="resetLibrary" />
                </div>
              </div>
            </div>
            <PartLibraryFilter :reset="reset" />
          </div>
        </div>
      </transition>
    </div>

    <div class="library-table">
      <PartTable class="bg-white" :filter-key="filterKey" @columns-changed="setLibraryUserSettings()" />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import SparkButton from '../../../components/SparkComponents/SparkButton.vue';

import PartLibraryFilter from './components/PartLibraryFilter.vue';

import PartTable from '@/components/PartLibrary/PartTable.vue';
import Reset from '@/components/Reusable/Filter/Reset.vue';

export default {
  name: 'PartLibrary',

  components: {
    PartLibraryFilter,
    PartTable,
    Reset,
    SparkButton,
  },

  data() {
    return {
      filterKey: 0,
      reset: 0,
      filterSideBarWidth: '25%',
      chevronColor: 'var(--accent-color)',
      filterSideBarExpanded: true,
    };
  },

  computed: {
    ...mapState(['user', 'possiblePartLibraryFilters']),
    ...mapState('application', ['axiosInstance']),

    partLibraryColumns() {
      return this.user.user_settings.part_library_columns;
    },
  },

  watch: {
    user: {
      handler() {
        this.resetLibrary();
      },

      deep: true,
    },
  },

  mounted() {
    this.updateActiveId('');
    this.resetPart();
  },

  methods: {
    ...mapMutations(['updateActiveId', 'resetPart', 'updateUserSettings', 'setReloadPartLibrary']),

    async setLibraryUserSettings() {
      this.axiosInstance
        .put('/api/v1/user-settings/', {
          part_library_columns: this.partLibraryColumns,
        })
        .then(() => {
          this.updateUserSettings({ part_library_columns: this.partLibraryColumns });
        });
    },

    start(el) {
      el.style.width = el.scrollWidth + 'px';
    },

    end(el) {
      el.style.width = '';
    },

    setFIlterSideBarExpanded() {
      this.filterSideBarExpanded = !this.filterSideBarExpanded;
      if (this.filterSideBarExpanded) {
        this.filterSideBarWidth = '25%';
      } else {
        this.filterSideBarWidth = '2%';
      }
    },

    filtering() {
      this.filterKey += 1;
    },

    resetLibrary() {
      this.reset += 1;
      setTimeout(() => {
        this.filtering();
      }, 100);
    },

    reloadPartLibrary() {
      this.setReloadPartLibrary();
    },
  },
};
</script>

<style lang="scss" scoped>
.part-library {
  display: flex;
  margin-right: var(--grid-outer-whitespace);
  background-color: white;
}

.chevron-icon {
  position: absolute;
  right: -10px;
  top: 30px;
  color: v-bind(chevronColor);
  cursor: pointer;
}

.library-header {
  display: flex;
  flex-direction: column;
  margin: var(--grid-outer-whitespace);
  background-color: white;
}

.sidebar-title {
  position: relative;
  border-right: 1px solid var(--light-color);
  width: v-bind(filterSideBarWidth);
}

.accordion-enter-active,
.accordion-leave-active {
  transition:
    width 0.3s ease-in,
    opacity 0.3s ease;
}

.accordion-enter,
.accordion-leave-to {
  width: 0 !important;
  opacity: 0;
}

.library-table {
  padding: var(--grid-outer-whitespace);
  margin-top: var(--grid-outer-whitespace);
  padding-top: 0px;
  background-color: white;
  padding-bottom: 30px;
  max-width: 100% !important;
  width: 100% !important;
  overflow: hidden;
}

h1 {
  display: inline-block;
}

.part-library__action-buttons {
  display: flex;
  align-items: center;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 32px;
}

.action-buttons {
  display: flex;
  align-items: center;
}

.filter {
  cursor: pointer;
  height: fit-content;
  display: flex;
  align-items: center;
}
</style>
