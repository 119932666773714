<template>
  <div class="ml-8">
    <VueMultiselect
      v-model="currentMaterial"
      :options="materials"
      taggable
      close-on-select
      :limit="10"
      :max="1"
      multiple
      label="name"
      allow-empty
      placeholder="Search Material or Enter New"
      tag-placeholder="Press Enter to add this as material"
      @remove="handleMaterialRemoval"
      @search-change="searchMaterials($event)"
      @tag="addTag"
    />
    <span v-if="currentMaterial && currentMaterial !== '' && resultsFromMatAnalysisAvailable" class="text-11 ml-10 p-2">
      <i class="fas fa-check mr-4" />Material Proposals are shown at the next page.
    </span>
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect';
import { mapState, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'CurrentMaterialSelection',

  components: { VueMultiselect },

  emits: ['material-change'],

  data() {
    return {
      materials: [],
    };
  },

  computed: {
    ...mapState(['part', 'lockedTitle']),
    ...mapState('application', ['axiosInstance']),
    ...mapGetters(['lockedForUser']),

    resultsFromMatAnalysisAvailable() {
      return Object.keys(this.part.current_mat_analysis).length > 0;
    },

    currentMaterial: {
      get() {
        if (this.part.current_mat === null || this.part.current_mat === '') {
          return '';
        } else {
          return {
            name: this.part.current_mat,
          };
        }
      },

      set(value) {
        this.setCurrentMat(value[0].name);
        this.$emit('material-change', { current_mat: value[0].name });
      },
    },
  },

  methods: {
    handleMaterialRemoval() {
      this.currentMaterial = [{ name: '' }];
      this.$emit('material-change', { current_mat: '' });
    },

    ...mapMutations(['setCurrentMat']),

    addTag(tag) {
      this.currentMaterial = [{ mat_id: null, name: tag }];
      this.setCurrentMat(tag);
      this.$emit('material-change', { current_mat: tag });
    },

    async searchMaterials(event) {
      let formData = {
        search_string: event,
      };
      try {
        const response = await this.axiosInstance.post(`api/v1/material-search/`, formData);

        const materialMap = new Map();
        response.data.forEach(entry => {
          if (!materialMap.has(entry.mat_name)) {
            materialMap.set(entry.mat_name, {
              mat_id: entry.mat_id,
              name: entry.mat_name,
            });
          }
        });
        this.materials = Array.from(materialMap.values());
      } catch (error) {
        console.error('Error searching materials:', error);
        this.materials = [];
      }
    },
  },
};
</script>
