<template>
  <SparkModal :model-value="show" class="h-full" @update:model-value="close">
    <template #header>
      <div class="text-18 font-bold" v-text="'Create Supplier'" />
    </template>

    <template #default>
      <Form @submit="createSupplier">
        <SparkInput v-model="supplierName" class="mb-32" name="supplierName" label="Name *" :rules="validateName" />
        <SparkInput v-model="supplierEmail" class="mb-32" name="supplierEmail" label="Email *" :rules="validateEmail" />
        <label class="text-13 text-gray-500" for="file_input">Logo</label>
        <input id="file_input" class="mb-32" type="file" accept=".jpeg, .jpg, .png, .svg" @change="handleFileUpload" />
        <div class="text-13 text-gray-500">Processes</div>
        <VueMultiselect
          v-model="selectedOptions"
          :options="processOptions"
          multiple
          :close-on-select="false"
          :clear-on-select="false"
          placeholder="Select processes"
          label="label"
        />
        <div class="flex justify-end gap-16 mt-[300px]">
          <SparkButton variant="outlined" @click="close">
            <div v-text="'Cancel'" />
          </SparkButton>
          <SparkButton variant="secondary" type="submit">
            <div v-text="'Create Email Supplier'" />
          </SparkButton>
        </div>
      </Form>
    </template>
  </SparkModal>
</template>

<script>
import { Form } from 'vee-validate';
import VueMultiselect from 'vue-multiselect';
import { mapActions, mapState } from 'vuex';
import { z } from 'zod';

import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkInput from '@/components/SparkComponents/SparkInput.vue';
import SparkModal from '@/components/SparkComponents/SparkModal.vue';

export default {
  name: 'CreateSupplierModal',

  components: {
    SparkButton,
    SparkModal,
    SparkInput,
    VueMultiselect,
    Form,
  },

  props: {
    show: { type: Boolean },
  },

  emits: ['close'],

  data() {
    return {
      supplierName: '',
      supplierEmail: '',
      logoFile: null,
      selectedOptions: [],
    };
  },

  computed: {
    ...mapState(['processes']),

    processOptions() {
      return Object.keys(this.processes).map(key => ({
        value: this.processes[key].value,
        label: this.processes[key].text,
      }));
    },
  },

  methods: {
    ...mapActions('supplier', ['createEmailSupplier', 'updateSuppliers']),

    close() {
      this.$emit('close');
    },

    validateName(value) {
      try {
        z.string().min(1, 'Name is required').parse(value);
        return true;
      } catch (error) {
        return error.errors[0].message;
      }
    },

    validateEmail(value) {
      try {
        z.string().min(1, 'Email is required').email('Invalid email format').parse(value);
        return true;
      } catch (error) {
        return error.errors[0].message;
      }
    },

    async createSupplier(values) {
      try {
        let formData = new FormData();
        formData.append('name', this.supplierName);
        formData.append('email', this.supplierEmail);

        if (this.logoFile) {
          formData.append('file', this.logoFile);
        }

        if (this.selectedOptions.length > 0) {
          const selected = this.selectedOptions.map(option => option.value);
          formData.append('supplier_processes', selected);
        }

        await this.createEmailSupplier({ data: formData, notify: this.$root.notify });
        this.close();
        this.updateSuppliers();
        this.supplierName = '';
        this.supplierEmail = '';
        this.logoFile = null;
        this.selectedOptions = [];
      } catch (error) {
        console.error('Error creating supplier:', error);
      }
    },

    handleFileUpload(event) {
      this.logoFile = event.target.files[0];
    },
  },
};
</script>
