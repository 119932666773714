<template>
  <div>
    <div ref="form" class="bg-white w-full p-20">
      <template v-for="field in machineData" :key="field.db_name">
        <Component
          :is="field.component"
          v-model="formData[field.db_name]"
          :name="field.db_name"
          :label="field.name + (field.unit ? ` (${field.unit})` : '')"
          :options="Array.isArray(field.options) && field.options.length > 0 ? field.options : []"
          :type="field.field"
          :disabled="field.name === 'Id'"
          :error="errors[field.db_name]"
          :is-percent="field.unit === '%'"
          top-label
          @update:model-value="validateField(field.db_name)"
          @input="validateField(field.db_name)"
        />
      </template>
    </div>

    <div class="flex justify-end m-20 gap-8">
      <SparkButton variant="outlined" @click="$emit('close')">
        <div v-text="'Cancel'" />
      </SparkButton>
      <SparkButton variant="secondary" type="button" @click="validateFields">
        <div v-text="mode === 'add' ? 'Add Machine' : 'Save'" />
      </SparkButton>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { z } from 'zod';

import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkForm from '@/components/SparkComponents/SparkForm.vue';
import SparkInput from '@/components/SparkComponents/SparkInput.vue';
import SparkSelect from '@/components/SparkComponents/SparkSelect.vue';
import getCurrentDateWithTime from '@/composables/dateFormatting.js';

export default {
  name: 'CustomMachineForm',

  components: {
    SparkButton,
    SparkForm,
    SparkInput,
    SparkSelect,
  },

  props: {
    machineData: { type: Object, required: true },
    show: { type: Boolean, required: true },
    mode: { type: String, required: true },
  },

  emits: ['add', 'close', 'edit'],

  data() {
    return {
      formData: {},
      errors: {},
    };
  },

  computed: {
    ...mapState(['user']),

    schema() {
      return z.object({
        mac_name: z.string().min(1, 'Required').max(100, 'Maximal length is 100'),
        oem_name: z.string().min(1, 'Required'),
        prc_uid: z.string().min(1, 'Required'),
        mac_cost_ph: z
          .union([z.string().trim(), z.number()])
          .transform(val => (val === '' ? undefined : Number(val)))
          .pipe(z.number().min(0, 'Required, cannot be less than 0').max(100000, 'cannot be more than 100000')),
        bld_size_x: z
          .union([z.string().trim(), z.number()])
          .transform(val => (val === '' ? undefined : Number(val)))
          .pipe(z.number().min(0.1, 'Required, cannot be less than 0.1').max(100000, 'cannot be more than 100000')),
        bld_size_y: z
          .union([z.string().trim(), z.number()])
          .transform(val => (val === '' ? undefined : Number(val)))
          .pipe(z.number().min(0.1, 'Required, cannot be less than 0.1').max(100000, 'cannot be more than 100000')),
        bld_size_z: z
          .union([z.string().trim(), z.number()])
          .transform(val => (val === '' ? undefined : Number(val)))
          .pipe(z.number().min(0.1, 'Required, cannot be less than 0.1').max(100000, 'cannot be more than 100000')),
      });
    },
  },

  watch: {
    show: {
      handler(value) {
        if (value && Object.keys(this.machineData).length > 0) {
          this.formData = {};
          Object.values(this.machineData).forEach(field => {
            this.formData[field.db_name] = field.value;
            if (field.db_name === 'mac_name' && this.mode === 'add') {
              this.formData[field.db_name] = '';
            }
          });
        }
      },

      immediate: true,
    },
  },

  methods: {
    handleSubmit() {
      if (this.mode === 'add') {
        this.formData = this.generateMacNameIfNotGiven(this.formData);
        this.$emit('add', this.formData);
      } else {
        this.$emit('edit', this.formData);
      }
    },

    generateMacNameIfNotGiven(formData) {
      if (formData === undefined) return formData;
      if (!formData.mac_name) {
        let dateString = getCurrentDateWithTime().dateWithTime;
        formData.mac_name = this.user.first_name + ' ' + this.user.last_name + ' ' + dateString;
      }
      return formData;
    },

    validateFields() {
      const result = this.schema.safeParse(this.formData);
      const errors = result.error?.issues;
      if (!errors) {
        this.handleSubmit();
        return;
      }

      this.errors = errors.reduce((acc, issue) => {
        acc[issue.path[0]] = issue.message;
        return acc;
      }, {});

      this.$refs.form.scrollIntoView({ behavior: 'smooth' });
    },

    validateField(field) {
      if (!this.schema.shape[field]) {
        return;
      }
      const result = this.schema.safeParse(this.formData);
      const errors = result.error?.issues;
      if (errors) {
        this.errors[field] = errors.find(issue => issue?.path[0] === field)?.message || '';
      } else {
        this.errors[field] = '';
      }
    },
  },
};
</script>
