<template>
  <!-- <div class="overview-frame">{{ processChain }}</div> -->
  <div>
    <DownloadPdf :chain="processChain" />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import DownloadPdf from '@/components/PartWorkflow/Overview/DownloadPdf.vue';

export default {
  name: 'OverviewFrame',

  components: { DownloadPdf },

  computed: {
    ...mapState(['part']),

    processChain() {
      let uid = this.$route.params.process_chain_id;
      return this.part.process_chains[uid];
    },
  },

};
</script>

<style lang="scss" scoped>
.overview-frame {
  margin: 0px var(--grid-outer-whitespace);
}
</style>
