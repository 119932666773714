<template>
  <EnergySettings />
  <hr class="border-gray-50" />
  <SalesRepresentative />
  <hr class="border-gray-50" />
  <OrganizationGoalSettings />
  <hr class="border-gray-50" />
  <LogoSettings />
  <hr class="border-gray-50" />
  <ColorSettings />
  <hr class="border-gray-50" />
  <PipelineSettings />
  <hr class="border-gray-50" />
  <ProcessSynonyms />
</template>

<script>
import ColorSettings from './components/ColorSettings.vue';
import EnergySettings from './components/EnergySettings.vue';
import LogoSettings from './components/LogoSettings.vue';
import OrganizationGoalSettings from './components/OrganizationGoalSettings.vue';
import PipelineSettings from './components/PipelineSettings.vue';
import ProcessSynonyms from './components/ProcessSynonyms.vue';
import SalesRepresentative from './components/SalesRepresentative.vue';

export default {
  name: 'OtherSettings',

  components: {
    EnergySettings,
    SalesRepresentative,
    OrganizationGoalSettings,
    LogoSettings,
    ColorSettings,
    PipelineSettings,
    ProcessSynonyms,
  },
};
</script>
