<template>
  <div class="flex p-20">
    <div class="w-1/3">
      <div class="text-15">Process Synonyms</div>
      <div class="text-13 text-gray-500">Click in the table to change Process Name and Process Acronym</div>
    </div>

    <table class="border border-gray-50 rounded-8 text-13">
      <thead class="h-48 text-13 font-normal">
        <th class="pl-16">Process Name</th>
        <th class="pl-16">Process Acronym</th>
      </thead>
      <tbody class="text-13 border border-gray-50">
        <tr v-for="name in sortedNames" :key="getUid(name)" class="border border-gray-50">
          <td>
            <SparkInput
              v-model="synonyms[getUid(name)].prc_name"
              :class="{ 'bg-red-50': isEmptyField(getUid(name), 'prc_name') }"
              class="cursor-pointer hover:bg-primary-50"
              @blur="saveSynonym(getUid(name), 'prc_name')"
            />
          </td>
          <td>
            <SparkInput
              v-model="synonyms[getUid(name)].prc_acronym"
              :class="{ 'bg-red-50': isEmptyField(getUid(name), 'prc_acronym') }"
              class="cursor-pointer hover:bg-primary-50"
              @blur="saveSynonym(getUid(name), 'prc_acronym')"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import SparkInput from '@/components/SparkComponents/SparkInput.vue';

export default {
  name: 'ProcessSynonyms',

  components: {
    SparkInput,
  },

  data() {
    return {
      synonyms: {},
      sortedNames: [],
      uidLookup: {},
    };
  },

  computed: {
    ...mapState(['organization']),
    ...mapState('application', ['axiosInstance']),
    processSynonyms() {
      return this.organization.process_synonyms;
    },
  },

  mounted() {
    this.initializeSynonyms();
  },

  methods: {
    initializeSynonyms() {
      if (this.processSynonyms) {
        this.synonyms = this.processSynonyms;

        // Create lookup table and sorted names array
        Object.entries(this.processSynonyms).forEach(([key, value]) => {
          this.uidLookup[value.prc_name] = key;
          this.sortedNames.push(value.prc_name);
        });

        this.sortedNames.sort();
      }
    },

    getUid(name) {
      return this.uidLookup[name];
    },

    isEmptyField(uid, field) {
      return !this.synonyms[uid]?.[field]?.trim();
    },

    async saveSynonym(uid, field) {
      if (this.isEmptyField(uid, field)) {
        this.$notify('error', 'Field cannot be empty');
        return;
      }

      try {
        const response = await this.axiosInstance.post(`/api/v1/process-synonym/${uid}/`, this.synonyms[uid]);
        this.$store.commit('setProcessSynonyms', response.data);
      } catch (error) {
        this.$notify('error', 'Failed to save changes');
      }
    },
  },
};
</script>
