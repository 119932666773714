<template>
  <div class="flex-container ">
    <div class="flex-container flex-row table-title">
      <h5   >Materials - In-House & Favorite</h5>
      <ComparisonPageMaterialLibraryFilter @filter="getFilteredMaterials($event)" />
    </div>
    <div v-if="materials.length > 0" class=" min-w-0 overflow-scroll">

    <table
      
      id="resizable-table"
      class="spark-table"
      :title="lockedForUser ? lockedTitle : ''"
      :disabled="processChainsComputationRunning || lockedForUser"
    >
      <thead>
        <tr>
          <th v-if="hasFavourites" />
          <!-- favorite -->
          <th v-if="hasInHouse" />
          <!-- inhouse -->
          <th
            v-for="col in selectedMaterialLibraryColumns"
            :id="col[0]"
            :key="col.index"
            @click="sort(col.sorting)"
            @mouseover="hover[col.title] = true"
            @mouseleave="hover[col.title] = false"
          >
            <div v-if="hover[col.title] || currentOrder === col.sorting">
              <Sorting :enabled="currentOrder === col.sorting" :reverse="currentOrderReverse" />
            </div>
            <span>{{ col.title }}</span>
          </th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody class="text-start !mt-0 !pt-0 !cursor-pointer">
        <TransitionGroup name="fadening">
          <!-- Iterate over parts -->
          <tr
            v-for="material in materials"
            :key="material"
            :style="selectedMatId === material.mat_id ? 'background-color: var(--light-color); font-weight: bold' : ''"
          >
            <td v-if="material.favorite"  >
              <i class="fas fa-star" />
            </td>
            <td v-else-if="hasFavourites"><div /></td>
            <td v-if="material.inhouse" >
              <i class="fa-solid fa-house" />
            </td>
            <td v-else-if="hasInHouse"><div /></td>
            <td v-for="col in selectedMaterialLibraryColumns" :key="col" @click="setMatId(material)">
              {{ material[col['database']] }}
              <span v-if="material[col['database']] != null && col.unit">{{ col.unit }}</span>
            </td>
          </tr>
        </TransitionGroup>
      </tbody>
    </table>
  </div>

    <img
      v-else-if="materials.length === 0 && loading"
      src="@/assets/img/loading.gif"
      class="!w-[10%] !ml-auto !mr-auto"
    />
    <div v-else class="m-auto" >
      <p>        
        Go to the <span class="font-bold cursor-pointer" @click="gotoLibrary"><i class="fas fa-arrow-right"/> Material Library</span> to select
        <ul class=" list-none">
          <li class="flex gap-8 items-center" >
            <i class="far fa-star" title="Set Favorite"/>
            <span v-text="'Favorite Materials'" />
            </li>
          <li class="flex gap-8 items-center">
            <img class="!w-16 !h-16 inline-block" src="@/assets/icons/in_house.svg"  />
            <span v-text="'In-House Materials'" />
          </li>
        </ul>
      </p>
    </div>
    <TablePagination :current-page="currentPage" :max-page-number="numberOfPages" @update-current-page="updateTable" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

import ComparisonPageMaterialLibraryFilter from './ComparisonPageMaterialLibraryFilter.vue';

import Sorting from '@/components/Reusable/Sorting.vue';
import TablePagination from '@/components/Reusable/TablePagination.vue';

export default {
  name: 'ComparisonPageMaterialTable',

  components: {
    TablePagination,
    ComparisonPageMaterialLibraryFilter,
    Sorting,
  },

  emits: ['mat-id', 'materialChangeNotAllowed'],

  data() {
    return {
      materials: [],
      currentPage: undefined,
      numberOfPages: 1,
      filterParams: {},
      currentOrder: '',
      currentOrderReverse: true,
      hover: {},
      loading: false,
    };
  },

  computed: {
    ...mapState(['part', 'user', 'investigationDetails', 'popup', 'organization']),
    ...mapState('application', ['lockedTitle', 'axiosInstance']),
    ...mapGetters(['lockedForUser']),

    processChainsComputationRunning() {
      return this.$store.getters.processChainsComputationRunning;
    },

    activeInvestigationDetails() {
      return this.investigationDetails;
    },

    activeProcessChain() {
      if (this.activeInvestigationDetails != '') {
        return this.processChains[this.activeInvestigationDetails.uid];
      }
      return null;
    },

    hasFavourites() {
      return this.materials.some(material => material.favorite);
    },

    hasInHouse() {
      return this.materials.some(material => material.inhouse);
    },

    processChains() {
      return this.part.process_chains;
    },

    selectedMatId() {
      return this.processChains[this.activeInvestigationDetails.uid]?.mat_id;
    },

    materialLibraryColumns: {
      get() {
        let cols = this.user.user_settings.material_library_columns;
        // if (this.user.is_external == true) {
        //   cols = Object.fromEntries(Object.entries(cols).filter(([key, value]) => value.external == true));
        // }
        return cols;
      },

      set(columns) {
        this.setMaterialUserSettings();
        this.updateUserSettings({ materialLibraryColumns: columns });
      },
    },

    // 2. get selected Part Library Columns
    selectedMaterialLibraryColumns() {
      let shown = [];
      for (let col in this.materialLibraryColumns) {
        if (this.materialLibraryColumns[col].selected === true) {
          shown.push(this.materialLibraryColumns[col]);
        }
      }
      // sort selected Columns
      shown.sort((a, b) => {
        return a.index - b.index;
      });
      return shown;
    },
  },

  mounted() {
    this.getMaterials();
  },

  methods: {
    ...mapMutations(['updateUserSettings', 'triggerPopup']),

    updateTable(pageNumber) {
      this.currentPage = pageNumber;
      this.getMaterials();
    },

    sort(str) {
      this.currentOrder = str;
      this.currentOrderReverse = !this.currentOrderReverse;
      this.filterParams['order_by'] = this.currentOrder;
      this.filterParams['order_reverse'] = this.currentOrderReverse;
      this.getMaterials();
    },

    getFilteredMaterials(event) {
      this.filterParams['filter_value'] = event;
      this.getMaterials();
    },

    gotoLibrary() {
      this.triggerPopup('');
      this.$router.push({ name: 'material-library' });
    },

    setMatId(material) {
      if (!this.lockedForUser) {
        this.$emit('mat-id', {
          mat_id: material.mat_id,
          mat_name: material.mat_name,
          mat_prc: material.process_acronym,
        });
      }
    },

    addPrcAcronym(obj) {
      Object.keys(obj).forEach(key => {
        let prc_uid = obj[key]['prc_uid'];
        obj[key]['prc_acronym'] = this.organization.process_synonyms[prc_uid].prc_acronym;
      });
    },

    getMaterials() {
      let url = 'api/v1/material-library/';
      let params = { ...this.filterParams };
      params.inHouseFavorites = true;

      if (this.currentPage !== undefined) {
        url = `api/v1/material-library/?page=${this.currentPage}`;
      } else if (this.selectedMatId !== undefined) {
        url = `api/v1/material-library/${this.selectedMatId}/`;
      }
      // get paginated materials and not all via a prop
      this.loading = true;
      this.axiosInstance
        .get(url, {
          params,
        })
        .then(response => {
          this.materials = response.data.results;
          this.addPrcAcronym(this.materials);
          this.numberOfPages = response.data.num_pages;
          this.currentPage = Number(response.data.page_number);
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
    },

    async setMaterialUserSettings() {
      this.axiosInstance.put('/api/v1/user-settings/', {
        material_library_columns: this.materialLibraryColumns,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.favorite,
.inhouse {
  visibility: hidden;
}

tr:hover td.favorite,
tr:hover td.inhouse {
  visibility: visible;
}

th {
  height: 55px;
}

.container {
  display: flex;
  flex-direction: column;
  font-size: var(--12px);
  justify-content: start;
}
</style>
