<template>
  <SparkInput
    v-model="filterValue"
    name="filter"
    label=""
    custom="h-40 rounded-8 p-0 m-0"
    placeholder="Search in all columns"
    @blur="search"
    @keyup.enter="search"
  />
</template>

<script>
import SparkInput from '../../../components/SparkComponents/SparkInput.vue';

export default {
  name: 'LibraryFilter',

  components: {
    SparkInput,
  },

  emits: ['filter'],

  data() {
    return {
      filterValue: '',
    };
  },

  watch: {
    filterValue(newVal) {
      if (!newVal) this.$emit('filter', newVal);
    },
  },

  methods: {
    search() {
      this.$emit('filter', this.filterValue);
    },
  },
};
</script>
