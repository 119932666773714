<template>
  <SparkModal dismissable :model-value="show" class="!max-w-[800px]" @update:model-value="closeModal">
    <template #header>
      <h1>View Material</h1>
    </template>
    <template #default>
      <table class="w-full">
        <tbody>
          <tr>
            <td colspan="3" class="text-12 font-semibold text-gray-900 pb-8">General Properties</td>
          </tr>
          <tr v-for="(field, index) in filledFormData.General" :key="`general-${index}`" class="hover:bg-gray-50">
            <td class="text-11 text-gray-700 py-4">{{ field.name }}</td>
            <td class="text-11 text-right py-4">{{ field.value || '-' }}</td>
            <td class="text-11 text-gray-500 pl-16 py-4">{{ field.value ? field.unit : '' }}</td>
          </tr>

          <tr>
            <td colspan="3" class="text-12 font-semibold text-gray-900 pt-24 pb-8">Additive Manufacturing</td>
          </tr>
          <tr
            v-for="(field, index) in filledFormData['Additive Manufacturing']"
            :key="`am-${index}`"
            class="hover:bg-gray-50"
          >
            <td class="text-11 text-gray-700 py-4">{{ field.name }}</td>
            <td class="text-11 text-right py-4">
              {{ field.value ? (field.isPercent ? (field.value * 100).toFixed(2) : field.value) : '-' }}
            </td>
            <td class="text-11 text-gray-500 pl-16 py-4">{{ field.value ? field.unit : '' }}</td>
          </tr>

          <tr>
            <td colspan="3" class="text-12 font-semibold text-gray-900 pt-24 pb-8">Casting</td>
          </tr>
          <tr v-for="(field, index) in filledFormData['Casting']" :key="`casting-${index}`">
            <td class="text-11 text-gray-700 py-4">{{ field.name }}</td>
            <td class="text-11 text-right py-4">{{ field.value || '-' }}</td>
            <td class="text-11 text-gray-500 pl-16 py-4">{{ field.value ? field.unit : '' }}</td>
          </tr>

          <tr>
            <td colspan="3" class="text-12 font-semibold text-gray-900 pt-24 pb-8">Milling</td>
          </tr>
          <tr v-for="(field, index) in filledFormData['Milling']" :key="`milling-${index}`">
            <td class="text-11 text-gray-700 py-4">{{ field.name }}</td>
            <td class="text-11 text-right py-4">{{ field.value || '-' }}</td>
            <td class="text-11 text-gray-500 pl-16 py-4">{{ field.value ? field.unit : '' }}</td>
          </tr>

          <tr>
            <td colspan="3" class="text-12 font-semibold text-gray-900 pt-24 pb-8">Fire and Smoke</td>
          </tr>
          <tr v-for="(field, index) in filledFormData['Fire and Smoke']" :key="`fire-${index}`">
            <td class="text-11 text-gray-700 py-4">{{ field.name }}</td>
            <td class="text-11 text-right py-4">{{ field.value || '-' }}</td>
            <td class="text-11 text-gray-500 pl-16 py-4">{{ field.value ? field.unit : '' }}</td>
          </tr>
        </tbody>
      </table>
    </template>
    <template #footer>
      <div class="flex justify-end">
        <SparkButton variant="secondary" @click="closeModal">
          <div v-text="'Close'" />
        </SparkButton>
      </div>
    </template>
  </SparkModal>
</template>

<script>
import { mapState } from 'vuex';

import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkModal from '@/components/SparkComponents/SparkModal.vue';
import { transformObjectToArray } from '@/helpers.js';

export default {
  name: 'ViewMaterialModal',

  components: { SparkModal, SparkButton },

  props: {
    show: { type: Boolean },
    materialData: { type: Object, required: true },
    filledFormData: { type: Object, required: true },
  },

  emits: ['close'],

  computed: {
    ...mapState(['allMachines']),

    updatedMachines() {
      return transformObjectToArray(this.allMachines);
    },
  },

  watch: {
    show: {
      handler(value) {
        if (value) {
          const prcField = this.filledFormData['General'].find(field => field.db_name === 'prc_uid');
          const defaultProcess = this.materialData.general?.prc_uid;
          if (defaultProcess && prcField.options) {
            const process = prcField.options.find(opt => opt.value === defaultProcess);
            prcField.value = process ? process.label : '-';
          }

          const macField = this.filledFormData['General'].find(field => field.db_name === 'mac_id');
          const defaultMachine = this.materialData.general?.default_machine_uid;
          if (defaultMachine && this.updatedMachines) {
            const machine = this.updatedMachines.find(machine => machine.value === defaultMachine);
            macField.value = machine ? machine.label : '-';
          }

          const matLvl2Field = this.filledFormData['General'].find(field => field.db_name === 'mat_lvl_2_id');
          const defaultMatLvl2 = this.materialData.general.mat_lvl_2_uid;
          if (defaultMatLvl2 && matLvl2Field.options) {
            const matLvl2 = matLvl2Field.options.find(opt => opt.value === defaultMatLvl2);
            matLvl2Field.value = matLvl2 ? matLvl2.label : '-';
          }
        }
      },
    },
  },

  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>
