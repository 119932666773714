<template>
  <div class="flex gap-10 items-center">
    <PartActionDropdown />
    <AnalysisButton />
  </div>
</template>

<script>
import AnalysisButton from '@/components/PartWorkflow/Comparison/components/AnalysisButton.vue';
import PartActionDropdown from '@/components/PartWorkflow/Comparison/components/PartActionDropdown.vue';

export default {
  name: 'PartActionButtonsProduction',

  components: { PartActionDropdown, AnalysisButton },

  data() {
    return {};
  },

  computed: {},
};
</script>
