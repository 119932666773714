<template>
  <SparkModal dismissable :model-value="show" @update:model-value="closeModal">
    <template #custom>
      <div class="flex border-b border-gray-100 justify-between items-top">
        <div class="flex flex-col p-20">
          <div class="text-18 font-bold border-gray-100">Add Custom Machine</div>
          <a
            href="https://support.3dspark.de/machine-and-material-properties"
            target="_blank"
            rel="noopener noreferrer"
            class="text-13 text-primary-500 cursor-pointer hover:text-primary-700"
            >Need help?</a
          >
        </div>
        <div class="p-20"><i class="text-17 fas fa-times cursor-pointer text-gray-700" @click="closeModal" /></div>
      </div>
      <CustomMachineForm
        :show="show"
        :machine-data="updatedFormData"
        mode="add"
        @close="closeModal"
        @add="addCustomMachine"
      />
    </template>
  </SparkModal>
</template>

<script>
import CustomMachineForm from '../components/CustomMachineForm.vue';

import SparkModal from '@/components/SparkComponents/SparkModal.vue';

export default {
  name: 'AddCustomMachineModal',

  components: { CustomMachineForm, SparkModal },

  props: {
    show: { type: Boolean, required: true },
    machineData: { type: Object, required: true },
    machineDataForm: { type: Object, required: true },
  },

  emits: ['close', 'add'],

  data() {
    return {
      updatedFormData: {},
    };
  },

  watch: {
    machineData: {
      handler(newVal) {
        if (newVal) {
          const updatedForm = this.populateFormValues(newVal, this.machineDataForm);
          this.updatedFormData = updatedForm;
        }
      },

      immediate: true,
      deep: true,
    },
  },

  methods: {
    closeModal() {
      this.$emit('close');
    },

    populateFormValues(machineData, addMachineForm) {
      function getValueFromMachineData(machineData, db_name) {
        const keys = db_name.split('.');
        let value = machineData;
        for (let key of keys) {
          value = value ? value[key] : null;
          if (value === null || value === undefined) break;
        }
        return value;
      }

      let tempForm = JSON.parse(JSON.stringify(addMachineForm));

      tempForm.forEach(formField => {
        if (formField.db_name) {
          let value;
          value = getValueFromMachineData(machineData, formField.db_name);

          if (value !== undefined && value !== null) {
            formField.value = value;
          }
        }
      });

      return tempForm;
    },

    addCustomMachine(data) {
      this.$emit('add', data);
      this.closeModal();
    },
  },
};
</script>
