<template>
  <Loading v-if="loading" />
  <div v-else class="dashboard">
    <div class="title-summary">
      <DashboardSummary class="card" />
    </div>
    <OrganizationGoal class="goals card" />
    <div class="filter-charts">
      <DashboardFilter class="filter card" @filtering="filterKey = filterKey + 1" />
      <div class="charts card">
        <OverallSavings :filtering="filterKey" />
        <PartsUploaded :filtering="filterKey" />
        <Scatter :filtering="filterKey" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import DashboardFilter from './components/DashboardFilter.vue';
import DashboardSummary from './components/DashboardSummary.vue';
import OrganizationGoal from './components/OrganizationGoal.vue';
import OverallSavings from './components/overall_savings/OverallSavings.vue';
import PartsUploaded from './components/PartsUploaded.vue';
import Scatter from './components/Scatter.vue';

import Loading from '@/components/Reusable/Loading.vue';

export default {
  name: 'Dashboard',

  components: {
    Loading,
    PartsUploaded,
    DashboardSummary,
    OverallSavings,
    DashboardFilter,
    OrganizationGoal,
    Scatter,
  },

  data() {
    return {
      filterKey: 0,
      loading: true,
    };
  },

  computed: {
    ...mapState(['user']),
  },

  watch: {
    user: {
      handler() {
        if (this.user.is_external !== undefined) {
          if (this.user.is_external) {
            this.$router.push({ name: 'partframe', params: { part_id: '0' } });
          } else {
            this.loading = false;
          }
        }
      },

      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.title-summary {
  width: 100% !important;
  display: grid;
  grid-template-columns: 4fr; /* Two columns with equal width */
}

.charts {
  max-width: 100%;
  overflow: hidden;
}

.goals {
  width: 100%;
  padding: var(--grid-outer-whitespace);
}

.filter-charts {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr;
}

@media only screen and (max-width: 981px) {
  .filter-charts {
    display: flex;
    flex-direction: column;
  }

  .title-summary {
    grid-template-columns: 1fr;
  }
}
</style>
