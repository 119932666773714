<template>
  <div class="flex items-center">
    <div
      class="flex items-center bg-primary-500 rounded-6 mr-2 h-[38px] text-13 text-gray-900 px-4 py-2 hover:cursor-pointer hover:text-white min-w-100"
      data-test="create-part-button"
      @click="create()"
    >
      <i class="far fa-plus mr-4" /> Create Part
    </div>
    <DropdownMenu
      v-if="!user.is_external"
      class="hover:bg-primary-500 rounded-6 hover:text-white"
      :list-options="dropdownOptions"
      close-click-style="always"
      show-caret
      title=""
      @update-option="action($event)"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import DropdownMenu from '@/components/Reusable/DropdownMenu.vue';
export default {
  name: 'CreateButton',

  components: { DropdownMenu },

  emits: ['create-part', 'create-group', 'create-prp'],

  computed: {
    ...mapState(['user']),

    dropdownOptions() {
      return [
        { text: 'Create PRP Part', icon: 'far fa-plus', value: 'create-prp' },
        { text: 'Create Group', icon: 'far fa-plus', value: 'create-group' },
      ];
    },
  },

  methods: {
    create() {
      if (this.user.is_external) {
        this.$emit('create-prp');
      } else {
        this.$emit('create-part');
      }
    },

    action(event) {
      this.$emit(event.value);
    },
  },
};
</script>
