<template>
  <SparkModal dismissable :model-value="show" @update:model-value="close">
    <template #header>
      <div class="flex flex-col">
        <div>Process Chain</div>
        <div class="text-11 text-gray-400 font-normal">Select process chains to be added to calibration</div>
      </div>
    </template>
    <template #default>
      <div class="border border-gray-100 rounded-6">
        <table class="mb-32">
          <tr>
            <th>&nbsp;</th>
            <th>Name</th>
            <th>Process</th>
            <th>Material</th>
            <th>Machine</th>
          </tr>
          <tr
            v-for="template in templates"
            :key="template"
            class="border border-gray-50 h-48 hover:bg-gray-50 hover: cursor-pointer"
            @click="selectedTemplate = template"
          >
            <td class="px-8"><input id="uid" v-model="selectedTemplate" type="radio" :value="template" /></td>
            <td>{{ template.name }}</td>
            <td>{{ template.prc_name }}</td>
            <td>{{ template.material_name }}</td>
            <td>{{ template.machine }}</td>
          </tr>
        </table>
      </div>
      <div class="flex gap-8 my-8 justify-end">
        <SparkButton variant="outlined" @click="close()"><div v-text="'Close'" /></SparkButton>
      </div>
    </template>
  </SparkModal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkModal from '@/components/SparkComponents/SparkModal.vue';

export default {
  name: 'SelectTemplateModal',

  components: {
    SparkModal,
    SparkButton,
  },

  props: {
    show: { type: Boolean },
  },

  emits: ['close', 'select'],

  data() {
    return {
      templates: {},
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
    ...mapState('calibration', ['selectedProcessChain', 'calibrationConfigUid']),

    selectedTemplate: {
      get() {
        return this.selectedProcessChain;
      },

      set(value) {
        this.setSelectedProcessChain(value);
        this.select();
      },
    },
  },

  mounted() {
    this.getListTemplates();
  },

  methods: {
    ...mapMutations('calibration', ['setSelectedProcessChain', 'setCalibrationResult', 'setSelectedParameters']),

    close() {
      this.$emit('close');
    },

    select() {
      this.createCalibrationWithTemplate();
      this.$emit('close');
    },

    async getListTemplates() {
      await this.axiosInstance
        .get('api/v1/process-chain-template-list/')
        .then(response => {
          this.templates = response.data;
        })
        .catch(error => {
          console.error(error);
        });
    },

    async createCalibrationWithTemplate() {
      const data = {
        process_chain_template_uid: this.selectedTemplate.uid,
      };

      await this.axiosInstance
        .put(`api/v1/calibration-config-list/${this.calibrationConfigUid}`, data)
        .then(response => {
          this.setCalibrationResult(response.data);
          this.setSelectedParameters(response.data.parameters);
        })
        .catch(error => {
          console.error(error);
        });
    },
  },
};
</script>
