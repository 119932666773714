<template>
  <div class="flex pt-8 justify-between">
    <div v-if="showPage != 'CalibrationMode' && showPage !== 'CalibrationRunning'">
      <SparkButton variant="outlined" class="mx-8 ml-16" @click="back()">
        <div>Back</div>
      </SparkButton>
    </div>
    <div v-else />
    <div class="flex flex-col">
      <div>
        <SparkButton variant="outlined" class="mx-16"><div @click="cancel()" v-text="'Cancel'" /></SparkButton>
        <SparkButton
          v-if="showPage !== 'CalibrationRunning' && showPage !== 'CalibrationResults'"
          variant="outlined"
          class="mx-8"
          ><div @click="saveResults()" v-text="'Save'"
        /></SparkButton>
        <SparkButton
          v-if="showPage !== 'CalibrationResults' && showPage !== 'CalibrationRunning'"
          variant="secondary"
          :disabled="nextDisabled"
          class="mx-8"
          @click="next()"
          ><div v-text="'Next'"
        /></SparkButton>

        <SparkButton v-if="showPage === 'CalibrationResults'" variant="secondary" class="mx-8" @click="saveResults()"
          ><div v-text="'Save Results'"
        /></SparkButton>
      </div>
      <div class="flex text-13 font-normal justify-end mr-8 mt-4 text-red-600">
        <div class="border border-red-200 p-2 rounded-6 bg-red-50">
          {{ hint }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import SparkButton from '@/components/SparkComponents/SparkButton.vue';

export default {
  name: 'FooterButtons',

  components: {
    SparkButton,
  },

  emits: ['save', 'create-calibration-config', 'add-parts-calibration-config'],

  computed: {
    ...mapState('calibration', [
      'selectedMode',
      'actualPage',
      'calibrationConfigUid',
      'selectedProcessChain',
      'transformedPartsData',
      'selectedParameters',
      'loadCalibration',
    ]),

    ...mapState('application', ['axiosInstance']),

    showPage() {
      return this.actualPage;
    },

    nextDisabled() {
      let disabled = false;
      if (this.showPage === 'CalibrationMode') {
        if (this.selectedMode === 'unset') {
          disabled = true;
        }
      }
      if (this.showPage === 'SelectTemplate') {
        if (!this.enoughPartsSelected || !this.processChainSelected || !this.valuesNotNull) {
          disabled = true;
        }
      }
      return disabled;
    },

    valuesNotNull() {
      let anyNull = false;
      this.transformedPartsData.forEach(part => {
        if (part.value !== null) {
          anyNull = true;
        }
      });
      return anyNull;
    },

    processChainSelected() {
      return Object.keys(this.selectedProcessChain).length !== 0;
    },

    enoughPartsSelected() {
      return this.transformedPartsData.length >= 2;
    },

    hint() {
      if (this.nextDisabled) {
        if (this.showPage === 'CalibrationMode') {
          return 'Select Mode first';
        }
        if (this.showPage === 'SelectTemplate') {
          let text = '';
          if (!this.enoughPartsSelected) {
            text = text + 'Select minimum 2 parts';
          } else if (!this.processChainSelected) {
            text = text + 'Select a process chain';
          } else if (!this.valuesNotNull) {
            text = text + 'Set values';
          }
          return text;
        }
      }
      return '';
    },
  },

  methods: {
    ...mapMutations('calibration', [
      'setActualPage',
      'resetCalibrationState',
      'setCalibrationResult',
      'setCalibrationInstancesResult',
    ]),

    next() {
      if (this.showPage === 'CalibrationMode') {
        this.setActualPage('SelectTemplate');
        this.$emit('create-calibration-config');
        return;
      }
      if (this.selectedMode === 'machine') {
        if (this.showPage === 'SelectTemplate') {
          this.setActualPage('MachineMaterialLimits');
          this.$emit('add-parts-calibration-config');
          return;
        }
        if (this.showPage === 'MachineMaterialLimits') {
          this.setActualPage('CalibrationRunning');
        }
      }
      if (this.selectedMode === 'price') {
        if (this.showPage === 'SelectTemplate') {
          this.$emit('add-parts-calibration-config');
          this.setActualPage('PricingProperties');
          return;
        }
        if (this.showPage === 'PricingProperties') {
          this.setActualPage('CalibrationRunning');
          this.calibrateConfiguration();
          return;
        }
      }
    },

    cancel() {
      this.$router.push({ path: '/library/part' });
      this.resetCalibrationState();
    },

    saveResults() {
      this.$emit('save');
    },

    back() {
      if (this.showPage === 'SelectTemplate') {
        this.setActualPage('CalibrationMode');
        return;
      }
      if (this.showPage === 'MachineMaterialLimits' || this.showPage === 'PricingProperties') {
        this.setActualPage('SelectTemplate');
      }

      if (this.selectedMode === 'machine') {
        if (this.showPage === 'CalibrationResults') {
          this.setActualPage('MachineMaterialLimits');
        }
      }
      if (this.selectedMode === 'price') {
        if (this.showPage === 'CalibrationResults') {
          this.setActualPage('PricingProperties');
        }
      }
    },

    async calibrateConfiguration() {
      await this.axiosInstance
        .post(`api/v1/calibration/${this.calibrationConfigUid}`)
        .then(() => {
          this.setActualPage('CalibrationResults');
        })
        .catch(error => {
          console.error(error);
        });
    },
  },
};
</script>
