<template>
  <div>
    <AddCustomMachineModal
      :machine-data-form="customMachineForm"
      :machine-data="machineData"
      :show="showAddCustomMachineModal"
      mode="'add'"
      @close="showAddCustomMachineModal = false"
      @add="addMachine"
      @edit="editMachine"
    />
    <EditCustomMachineModal
      :machine-data-form="customMachineForm"
      :machine-data="machineData"
      :show="showEditCustomMachineModal"
      mode="'edit'"
      @close="showEditCustomMachineModal = false"
      @edit="editMachine"
    />
    <ViewMachineModal
      :machine-data="machineData"
      :filled-form-data="filledFormData"
      :show="showViewMachineModal"
      @close="showViewMachineModal = false"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import useCopyObject from '@/composables/copyObject.js';
import { addCustomMachineForm, populateMachineFormValues } from '@/utils/material';
import AddCustomMachineModal from '@/views/Library/machine/modals/AddCustomMachineModal.vue';
import EditCustomMachineModal from '@/views/Library/machine/modals/EditCustomMachineModal.vue';
import ViewMachineModal from '@/views/Library/machine/modals/ViewMachineModal.vue';

export default {
  name: 'CustomMachine',

  components: { AddCustomMachineModal, EditCustomMachineModal, ViewMachineModal },

  props: {
    machineData: {
      type: Object,
      required: true,
    },

    type: {
      type: String,
      default: '',
    },
  },

  emits: ['get-machines', 'highlight-machine', 'add-to-inhouse'],

  data() {
    return {
      customMachineForm: addCustomMachineForm,
      showViewMachineModal: false,
      showAddCustomMachineModal: false,
      showEditCustomMachineModal: false,
      filledFormData: {},
    };
  },

  computed: {
    ...mapState(['user', 'organization']),
    ...mapState('application', ['axiosInstance']),

    updatedProcessSynonyms() {
      return this.organization.process_synonyms;
    },

    updatedProcesses() {
      let actualProcesses = useCopyObject(this.updatedProcessSynonyms || {});

      if (actualProcesses !== undefined) {
        Object.keys(actualProcesses).forEach(key => {
          let listToRemove = [
            'EBM',
            'DOD',
            'VDC',
            'UIM',
            'TWIM',
            'SMW LC',
            'HAND LAMINATION',
            'SDC',
            'SAND CAST',
            'RIM',
            'PVC',
            'MIM',
            'LSR',
            'LP CAST',
            'LAMINATION',
            'INC CAST',
            'GIM',
            'G CAST',
            'FORM MIL',
            'FOAM CAST',
            'FACE MIL',
            'DM',
            'CTF CAST',
            'CMT',
          ];
          if (listToRemove.includes(actualProcesses[key]?.prc_acronym)) {
            delete actualProcesses[key];
          }
        });
      }

      const updatedProcesses = Object.values(actualProcesses || {}).map(prc => ({
        uid: prc.uid,
        label: prc.prc_acronym,
        value: prc.uid,
      }));

      return updatedProcesses;
    },

    updatedFilteredRestrictions() {
      const userRole = this.user.user_role;
      if (userRole === 'user') {
        return [{ label: 'Owner', value: 'owner' }];
      } else {
        return [
          { label: 'Owner', value: 'owner' },
          { label: 'My Organization', value: 'organization' },
        ];
      }
    },
  },

  watch: {
    machineData: {
      handler(machine) {
        if (Object.keys(machine).length) {
          if (this.type == 'view') {
            this.createViewDataTable();
          } else if (this.type == 'add') {
            this.showAddCustomMachineModal = true;
          } else if (this.type == 'edit') {
            this.showEditCustomMachineModal = true;
          }
        }
      },

      deep: true,
    },
  },

  mounted() {
    this.populateCustomMachineForm();
  },

  methods: {
    createViewDataTable() {
      this.filledFormData = populateMachineFormValues(this.machineData);
      this.showViewMachineModal = true;
    },

    addMachine(formData) {
      this.axiosInstance
        .post('/api/v1/machine/', formData)
        .then(response => {
          this.$root.notify('success', 'Custom Machine ' + response.data.mac_name + ' created', 3000);
          this.$emit('highlight-machine', response.data.mac_id);
          this.$emit('get-machines');
          this.$emit('add-to-inhouse', response.data);
        })
        .catch(error => {
          this.$root.notify('error', error.response.data.error_message, 3000);
        });
    },

    editMachine(formData) {
      this.axiosInstance
        .put(`/api/v1/machine/${formData.mac_id}/`, formData)
        .then(response => {
          this.$root.notify('success', 'Machine ' + response.data.mac_name + ' edited', 3000);
          this.$emit('get-machines');
        })
        .catch(error => {
          this.$root.notify('error', error.response.data.error_message, 3000);
        });
    },

    populateCustomMachineForm() {
      Object.values(this.customMachineForm || {}).forEach(field => {
        if (field.db_name === 'prc_uid') {
          field.options = this.updatedProcesses;
        } else if (field.db_name === 'restriction') {
          field.options = this.updatedFilteredRestrictions;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.spark-btn {
  float: right;
  font-size: var(--12px);
}
</style>
