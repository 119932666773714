<template>
  <SparkModal dismissable :model-value="show" class="!max-w-[800px]" @update:model-value="closeModal">
    <template #header>
      <h1>View Machine</h1>
    </template>
    <template #default>
      <table class="w-full">
        <tbody>
          <tr v-for="(field, index) in filledFormData" :key="`machine-${index}`" class="hover:bg-gray-50">
            <td class="text-11 text-gray-700 py-4">{{ field.name }}</td>
            <td class="text-11 text-right py-4">{{ field.value || '-' }}</td>
            <td class="text-11 text-gray-500 pl-16 py-4">{{ field.value ? field.unit : '' }}</td>
          </tr>
        </tbody>
      </table>
    </template>
    <template #footer>
      <div class="flex justify-end">
        <SparkButton variant="secondary" @click="closeModal">
          <div v-text="'Close'" />
        </SparkButton>
      </div>
    </template>
  </SparkModal>
</template>

<script>
import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkModal from '@/components/SparkComponents/SparkModal.vue';

export default {
  name: 'ViewMachineModal',

  components: { SparkModal, SparkButton },

  props: {
    show: { type: Boolean },
    machineData: { type: Object, required: true },
    filledFormData: { type: Object, required: true },
  },

  emits: ['close'],

  watch: {
    show: {
      handler(value) {
        if (value) {
          const prcField = this.filledFormData.find(field => field.db_name === 'prc_uid');
          const defaultProcess = this.machineData?.prc_uid;
          if (defaultProcess && prcField.options) {
            const process = prcField.options.find(opt => opt.value === defaultProcess);
            prcField.value = process ? process.label : '-';
          }
        }
      },
    },
  },

  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>
