<template>
  <div>
    <SparkInput
      v-model.lazy="lotSize"
      name="text"
      autocomplete="off"
      :disabled="chain.process_chain_id == '0' || lockedForUser"
      :title="lockedForUser ? lockedTitle : ''"
      label=""
      class="px-4"
      @click="setToActive()"
      @change="updateLotSize"
    />

    <SparkModal v-model="showConfirmationModal" dismissable>
      <template #header>
        <span>Warning</span>
      </template>

      <div class="p-20">
        <p>Changing the lot size will reset all Process Step costs to their default values. Do you want to proceed?</p>
      </div>

      <template #footer>
        <div class="flex justify-end gap-8">
          <SparkButton variant="secondary" @click="cancelLotSizeChange">
            Cancel
          </SparkButton>
          <SparkButton variant="primary" @click="confirmLotSizeChange">
            Yes, proceed
          </SparkButton>
        </div>
      </template>
    </SparkModal>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import SparkInput from '../../../../SparkComponents/SparkInput.vue';
import SparkModal from '../../../../SparkComponents/SparkModal.vue';
import SparkButton from '../../../../SparkComponents/SparkButton.vue';

export default {
  name: 'LotSize',

  components: {
    SparkInput,
    SparkModal,
    SparkButton,
  },

  props: {
    chain: { type: Object, required: true },
  },

  data() {
    return {
      lotSize: 0,
      showConfirmationModal: false,
      pendingLotSize: null,
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance', 'lockedTitle']),
    ...mapGetters(['lockedForUser']),

    hasFeedbackValues() {
      return Object.values(this.chain.process_steps || {}).some(step => 
        step.overwrites && Object.keys(step.overwrites).length > 0
      );
    }
  },

  watch: {
    lotSize: {
      handler(value) {
        if (value === '') {
          this.lotSize = 1;
          return;
        }

        value = parseInt(value);
        this.lotSize = Math.ceil(value);
        this.lotSize === 0 ? (this.lotSize = 1) : '';
        if (this.lotSize > 100000) {
          this.lotSize = 100000;
          this.$root.notify(
            'warning',
            'Quantity limit reached',
            'You have reached the quantity limit of 1.000.000.',
            3000
          );
        }
      },
      deep: true,
    },
  },

  mounted() {
    this.lotSize = this.chain.lot_size;
  },

  methods: {
    ...mapMutations(['changeInvestigationDetails']),

    async updateLotSize() {
      try {
        if (this.hasFeedbackValues) {
          this.pendingLotSize = this.lotSize;
          this.lotSize = this.chain.lot_size;
          this.showConfirmationModal = true;
          return;
        }

        const formData = { lot_size: this.lotSize };
        await this.axiosInstance.put(
          `api/v1/process-chain-feedback/${this.chain.process_chain_id}/`,
          formData
        );
      } catch (error) {
        console.error('Save Process Chain Error', error);
        this.$root.notify(
          'error',
          'Error',
          'Failed to update lot size',
          3000
        );
        this.lotSize = this.chain.lot_size;
      }
    },

    async confirmLotSizeChange() {
      try {
        const formData = { 
          lot_size: this.pendingLotSize,
        };
        await this.axiosInstance.put(
          `api/v1/process-chain-feedback/${this.chain.process_chain_id}/`,
          formData
        );
        this.lotSize = this.pendingLotSize;
        this.showConfirmationModal = false;
      } catch (error) {
        console.error('Save Process Chain Error', error);
        this.$root.notify(
          'error',
          'Error',
          'Failed to update lot size',
          3000
        );
        this.lotSize = this.chain.lot_size;
      }
      this.pendingLotSize = null;
    },

    cancelLotSizeChange() {
      this.showConfirmationModal = false;
      this.pendingLotSize = null;
      this.lotSize = this.chain.lot_size;
    },

    setToActive() {
      this.changeInvestigationDetails({ uid: this.chain.process_chain_id });
    },
  },
};
</script>
