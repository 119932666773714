<template>
  <SparkButton
    variant="plain"
    :custom="`${!withBackground ? '!bg-transparent' : ''}`"
    :disabled="disabled"
    class="group flex h-[1rem] max-h-[1rem] w-[1rem] items-center justify-center rounded-6 !p-0"
    :class="{ '!bg-gray-50': withBackground, [`${custom}`]: !!custom }"
  >
    <i
      v-if="icon !== ''"
      class="flex py-8 px-10 items-center justify-center text-[1rem] text-gray-700 group-disabled:text-gray-300"
      :class="{
        [`${custom}`]: !!custom,
        [`${icon}`]: !!icon,
        [`${small ? 'text-[0.75rem]' : ''}`]: small,
      }"
    />
    <slot />
  </SparkButton>
</template>

<script>
import SparkButton from './SparkButton.vue';

export default {
  name: 'SparkButtonIcon',

  components: { SparkButton },

  props: {
    icon: { type: String, required: true },
    custom: { type: String, default: '' },
    withBackground: { type: Boolean },
    small: Boolean,
    disabled: Boolean,
  },
};
</script>
