<template>
  <div class="relative text-16">
    <div
      class="flex justify-between items-center p-8 rounded-8 border border-gray-300 cursor-pointer"
      @click="toggleDropdown"
    >
      <span class="text-16 px-8">Step Types</span>
      <span class="text-16 px-8 text-primary-500">{{ selectedTypesText }}</span>
      <i class="fas fa-chevron-down" />
    </div>
    <div
      v-if="isOpen"
      class="absolute w-[300px] top-full rounded-8 right-0 bg-white border border-gray-100 shadow-md z-10"
    >
      <div class="max-h-[500px] overflow-y-auto">
        <div
          v-for="(label, type) in stepTypes"
          :key="type"
          class="flex items-center px-8 py-4 hover:bg-gray-50 hover:cursor-pointer"
          @click="selectType(type)"
        >
          <input
            :id="type"
            v-model="selectedTypes"
            type="checkbox"
            :value="type"
            class="mr-2 rounded-6 border border-gray-300 text-primary-500 focus:ring-primary-600 focus:ring-offset-0"
          />
          <div class="flex items-center p-2">{{ label }}</div>
          <div class="flex items-center px-4 py-2 bg-gray-50 rounded-6">
            {{ countStepsByType(type) }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="selectedTypes.length > 0"
      class="absolute right-0 mb-2 hover:cursor-pointer hover:text-primary-500 text-11"
      @click="clearSelectedTypes"
    >
      <span class="mb-4">Clear</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepTypeFilter',

  props: {
    processSteps: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  emits: ['update-selected-types'],

  data() {
    return {
      isOpen: false,
      selectedTypes: [],
      stepTypes: {
        pre: 'Pre-Process',
        main: 'Main Process',
        post: 'Post-Process',
      },
    };
  },

  computed: {
    selectedTypesText() {
      if (this.selectedTypes.length === 0) {
        return '';
      } else if (this.selectedTypes.length === 1) {
        return '|  ' + this.stepTypes[this.selectedTypes[0]];
      } else {
        const firstType = this.stepTypes[this.selectedTypes[0]];
        const remainingCount = this.selectedTypes.length - 1;
        return '|  ' + `${firstType}, +${remainingCount} more`;
      }
    },
  },

  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },

    selectType(type) {
      const index = this.selectedTypes.indexOf(type);
      if (index > -1) {
        this.selectedTypes.splice(index, 1);
      } else {
        this.selectedTypes.push(type);
      }

      this.$emit('update-selected-types', this.selectedTypes);
      this.isOpen = false;
    },

    countStepsByType(type) {
      return Object.values(this.processSteps).filter(step => step.step_type === type).length;
    },

    clearSelectedTypes() {
      this.selectedTypes = [];
      this.$emit('update-selected-types', this.selectedTypes);
      this.isOpen = false;
    },
  },
};
</script>
