<template>
  <div v-if="numberCheckedParts" class="flex flex-row justify-center items-center gap-16">
    <div class="flex flex-row">
      <div class="text-13 text-gray-500 mx-4">{{ numberCheckedParts }} selected</div>
      <div class="text-13 font-bold mx-4 hover:cursor-pointer hover:text-primary-700" @click="deselectCheckedParts()">
        Deselect
      </div>
    </div>
    <SparkButton variant="outlined" small @click="confirmDelete">
      <div class="flex flex-row">
        <i title="delete selected parts" class="fas fa-trash mr-16 cursor-pointer text-red-500" />
        <div class="text-red-500">Delete</div>
      </div>
    </SparkButton>
    <SparkButton variant="outlined" small @click="goToCalibration()"
      ><div class="flex">
        <img src="@/assets/icons/compass.svg" class="h-20 mr-16" />
        <div>Calibrate</div>
      </div>
    </SparkButton>
    <SparkButton variant="outlined" small @click="addToGroup()"
      ><div class="flex">
        <img src="@/assets/icons/group.svg" class="h-20 mr-16" />
        <div>Add to group</div>
      </div>
    </SparkButton>
    <DropdownMenu
      :list-options="options"
      :title-class="titleClass"
      close-click-style="always"
      show-caret
      title="Share"
      @update-option="share($event)"
    />

    <AddToGroupModal
      :show="showModal"
      :number-checked-parts="numberCheckedParts"
      :checked-parts="checkedParts"
      @close="closeModal()"
    />
  </div>
</template>

<script>
import { saveAs } from 'file-saver';
import { mapState, mapMutations } from 'vuex';

import SparkButton from '../SparkComponents/SparkButton.vue';

import AddToGroupModal from './AddToGroupModal.vue';

import DropdownMenu from '@/components/Reusable/DropdownMenu.vue';

export default {
  name: 'PartAction',

  components: { DropdownMenu, SparkButton, AddToGroupModal },

  props: {
    checkedParts: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  emits: ['deselect-parts'],

  data() {
    return {
      titleClass: ' flex items-center px-12 rounded-6 h-32 border border-gray-200 text-gray-700 text-16 cursor-pointer',
      options: [],
      reportTemplate: null,
      showModal: false,
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance', 'axiosInstanceFileUpload']),
    ...mapState(['fetchCustomListReportDOCX', 'user', 'popup', 'batches', 'fetchExcelListReportXLS']),

    numberCheckedParts() {
      return Object.keys(this.checkedParts).length;
    },
  },

  watch: {
    fetchCustomListReportDOCX: {
      handler(newVal) {
        this.fetchCustomListReportFile(newVal);
      },

      immediate: true,
    },

    fetchExcelListReportXLS: {
      handler(newVal) {
        this.fetchExcelListReportFile(newVal);
      },

      immediate: false,
    },

    popup: {
      handler(popup) {
        if (popup?.key === this.$options.name) {
          if (popup?.clicked === 'ok') {
            this.deleteSelectedParts();
            this.triggerPopup('');
          } else if (popup?.clicked === 'cancel' || popup?.clicked === 'close') {
            this.triggerPopup('');
          }
        }
      },

      deep: true,
    },
  },

  async mounted() {
    this.getTemplateDocxList();
    this.updateFetchCustomListReportDOCX(false);
    this.updateFetchExcelListReportXLS(false);
  },

  methods: {
    ...mapMutations([
      'updateFetchCustomListReportDOCX',
      'updateFetchExcelListReportXLS',
      'triggerPopup',
      'setReloadPartLibrary',
    ]),

    ...mapMutations('calibration', ['setActualPage']),

    async deleteSelectedParts() {
      if (this.numberCheckedParts > 0) {
        const partIds = Object.keys(this.checkedParts);
        const deletePromise = partIds.map(id => this.deletePart(id));

        await Promise.all(deletePromise)
          .then(() => {
            this.$root.notify('success', 'Parts deleted', 'Selected parts have been deleted.', 3000);
          })
          .catch(error => {
            this.$root.notify('error', 'Error Deleting selected parts error', error, 6000);
          });
      }
      this.setReloadPartLibrary(true);
      this.deselectCheckedParts();
    },

    async deletePart(id) {
      await this.axiosInstance.delete(`/api/v1/part/${id}/`);
    },

    confirmDelete() {
      this.triggerPopup({
        key: this.$options.name,
        show: true,
        title: 'Delete Parts',
        message: `${this.numberCheckedParts} ${
          this.numberCheckedParts > 1 ? 'parts' : 'part'
        }  will be deleted. Are you sure?`,
        buttons: true,
        formData: this.partId,
        buttonContent: [
          { text: 'Cancel', type: 'outlined', value: 'cancel' },
          { text: 'Delete', type: 'secondary', value: 'ok' },
        ],
      });
    },

    closeModal() {
      this.showModal = false;
      this.deselectCheckedParts();
    },

    async addToGroup() {
      this.showModal = true;
    },

    deselectCheckedParts() {
      this.$emit('deselect-parts');
    },

    async getTemplateDocxList() {
      this.axiosInstance.get('api/v1/doc-template-list/').then(response => {
        Object.keys(response.data).forEach(key => {
          let template = response.data[key];
          if (template.doc_type === 'part_list') {
            this.options.push({
              text: 'Download DOCX - ' + template.name,
              value: 'docx-custom',
              icon: 'fas fa-file',
              template: template,
            });
          }
        });
        this.options.push({
          text: 'Download Excel',
          value: 'excel-export',
          icon: 'fas fa-file-excel',
        });
        if (this.options.length === 1) {
          this.options.push({
            text: 'No Template Created Yet',
            value: 'no-templates',
            icon: 'fas fa-file',
          });
        }
      });
    },

    async requestCustomListReportDOCX() {
      await this.axiosInstance
        .post(`api/v1/doc-list-generator/${this.reportTemplate.uid}/`, {
          parts: this.checkedParts,
        })
        .then(() => {
          this.$root.notify('success', 'File requested', `Generating report ${this.reportTemplate.name}.`, 3000);
        });
    },

    fetchCustomListReportFile(newVal) {
      if (newVal) {
        this.axiosInstance
          .get(`api/v1/fetch-doc-list-generated/${this.reportTemplate.uid}/`, {
            responseType: 'blob',
            parts: this.checkedParts,
          })
          .then(response => {
            saveAs(response.data, this.reportTemplate.default_file_name);
          })
          .catch(error => {
            console.log(error);
          });
        this.updateFetchCustomListReportDOCX(false);
      }
    },

    fetchExcelListReportFile(newVal) {
      if (newVal) {
        this.axiosInstance
          .get('api/v1/fetch-excel-list-generated/', {
            responseType: 'blob',
            parts: this.checkedParts,
          })
          .then(response => {
            saveAs(response.data, 'part_list.xlsx');
          })
          .catch(error => {
            console.log(error);
          });
        this.updateFetchExcelListReportXLS(false);
      }
    },

    share(option) {
      if (option.value === 'docx-custom') {
        this.reportTemplate = option.template;
        this.requestCustomListReportDOCX();
      } else if (option.value === 'excel-export') {
        this.requestExcelExport();
      }
      if (option.value === 'no-templates' && this.user?.user_role === 'manager') {
        this.$router.push({ name: 'organization-settings' });
      }
    },

    async requestExcelExport() {
      await this.axiosInstance
        .post(`api/v1/excel-list-generator/`, {
          parts: this.checkedParts,
        })
        .then(() => {
          this.$root.notify('success', 'File requested', `Generating Excel File.`, 3000);
        });
    },

    goToCalibration() {
      this.$router.push({
        name: 'calibration',
      });
    },
  },
};
</script>
