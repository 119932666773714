<template>
  <SparkModal :model-value="show" @update:model-value="close">
    <template #header>
      <div class="text-18 font-bold" v-text="'Select Category Filter'" />
    </template>

    <template #default>
      <div class="flex items-center justify-between mb-20">
        <SparkSelect
          v-model="selectedCategory"
          custom="!w-200 !mx-auto my-16"
          :options="categoryOptionsWithNone"
          name="category"
          label="Category"
          @update:model-value="loadCategoryItems"
        />
      </div>
      <div class="flex items-center justify-between mb-20">
        <SparkSelect
          v-model="selectedCategoryItem"
          custom="!w-200 !mx-auto my-16"
          :options="categoryItemOptions"
          name="categoryItem"
          label="Category Item"
          :disabled="!selectedCategory"
        />
      </div>
      <div class="flex justify-end gap-16">
        <SparkButton variant="outlined" @click="close">
          <div v-text="'Cancel'" />
        </SparkButton>
        <SparkButton variant="secondary" @click="updateFilter">
          <div v-text="'Add'" />
        </SparkButton>
      </div>
    </template>
  </SparkModal>
</template>

<script>
import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkModal from '@/components/SparkComponents/SparkModal.vue';
import SparkSelect from '@/components/SparkComponents/SparkSelect.vue';

export default {
  name: 'SelectComboCategoryItemModal',

  components: { SparkModal, SparkSelect, SparkButton },

  props: {
    show: { type: Boolean },
    comboCategories: { type: Object, required: true },
  },

  emits: ['close', 'update-filter'],

  data() {
    return {
      selectedCategory: '',
      selectedCategoryItem: '',
      categoryItemOptions: [],
    };
  },

  computed: {
    categoryOptionsWithNone() {
      return [
        { value: '', label: '' },
        ...Object.entries(this.comboCategories).map(([key, value]) => ({
          value: key,
          label: value.label,
        })),
      ];
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },

    updateFilter() {
      if (!this.selectedCategory) {
        // If 'None' is selected
        this.$emit('update-filter', {
          category: '',
          item: '',
          itemUid: null,
        });
      } else {
        const category = this.comboCategories[this.selectedCategory].label;
        const item = this.comboCategories[this.selectedCategory].items[this.selectedCategoryItem];
        this.$emit('update-filter', {
          category,
          item,
          itemUid: this.selectedCategoryItem,
        });
      }
      this.close();
    },

    loadCategoryItems(categoryKey) {
      if (!categoryKey) {
        this.categoryItemOptions = [];
        this.selectedCategoryItem = '';
      } else {
        const category = this.comboCategories[categoryKey];
        this.categoryItemOptions = Object.entries(category.items).map(([key, value]) => ({
          value: key,
          label: value,
        }));
        this.selectedCategoryItem = '';
      }
    },
  },
};
</script>
