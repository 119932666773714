<template>
  <div>
    <div class="flex justify-between">
      <div class="text-16 font-bold">PRP Category Settings</div>
      <div class="text-13 text-primary-500 cursor-pointer hover:text-primary-600" @click="addNewCategory">Add</div>
    </div>
    <div>
      <div class="border border-gray-50 rounded-8 w-full">
        <div class="flex w-full text-13 text-gray-600 border-b border-gray-50 h-56 items-center">
          <div class="w-[96px] ml-24"><!-- For drag icon column --></div>
          <div class="w-[200px]">Category</div>
          <div class="w-[96px]">Required</div>
          <div class="flex-1">Options</div>
          <div class="w-[96px] text-center"><!-- For actions column --></div>
        </div>
        <draggable :list="prpCategoryList" item-key="order_index" class="w-full" @end="reindexCategories">
          <template #item="{ element }">
            <div class="cursor-move flex text-13 h-64 items-center w-full border-b border-gray-50">
              <div class="w-[96px] drag-icon ml-24">&#x2630;</div>
              <div class="w-[200px]">
                <div class="text-gray-1000 font-bold">{{ element.label }}</div>
                <div class="text-11 text-gray-400">{{ categoryTypeOptions[element.category_type] }}</div>
              </div>
              <div class="w-[96px]">
                <SparkBadge v-if="element.required" variant="success" class="w-fit">
                  <i class="fas fa-check" />
                </SparkBadge>
                <SparkBadge v-else variant="warning" class="w-fit">
                  <i class="fas fa-xmark" />
                </SparkBadge>
              </div>

              <div
                v-if="element.category_type === 'combo'"
                :title="formatItemsList(element.items)"
                class="flex-1 flex flex-wrap text-gray-600"
              >
                <div v-for="(item, index) in element.items" :key="item">
                  <div v-if="index === 0">{{ item.value }},&nbsp;</div>
                  <div v-if="index === 1">{{ item.value }},&nbsp;</div>
                  <div v-if="index === 2">{{ item.value }}&nbsp;</div>
                </div>
                <div v-if="Object.keys(element.items).length > 3">
                  + {{ Object.keys(element.items).length - 3 }} more
                </div>
              </div>
              <div v-else class="flex-1">&nbsp;</div>
              <div class="w-[96px] text-center">
                <DropdownMenu
                  title-icon="fas fa-ellipsis-v"
                  :list-options="[
                    { text: 'Edit', icon: 'far fa-edit', value: 'edit' },
                    { text: 'Delete', icon: 'far fa-trash-alt', value: 'delete' },
                  ]"
                  @update-option="handleDropdownAction($event, element)"
                />
              </div>
            </div>
          </template>
        </draggable>

        <DeleteCategoryModal
          :show="showDeleteCategoryModal"
          :category="categoryToDelete"
          prp
          @close="closeDeleteCategoryModal"
        />

        <AddEditCategoryModal
          :update-key="updateKey"
          :show="showAddEditCategoryModal"
          :category-to-edit="categoryToEdit"
          :mode="mode"
          @close="closeAddEditCategoryModal"
        />

        <div />
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapState, mapActions } from 'vuex';

import DropdownMenu from '@/components/Reusable/DropdownMenu.vue';
import SparkBadge from '@/components/SparkComponents/SparkBadge.vue';
import AddEditCategoryModal from '@/views/PartRequestPortal/components/modals/AddEditPrpCategoriesModal.vue';
import DeleteCategoryModal from '@/views/Settings/views/organization/category/components/DeleteCategoryModal.vue';
export default {
  name: 'PRPCategories',

  components: { SparkBadge, DropdownMenu, draggable, DeleteCategoryModal, AddEditCategoryModal },

  data() {
    return {
      categoryToEdit: {},
      mode: 'Add',
      updateKey: 0,
      showAddEditCategoryModal: false,
      showDeleteCategoryModal: false,
      categoryToDelete: {},
    };
  },

  computed: {
    ...mapState(['user', 'popup', 'categoryTypes']),
    ...mapState('application', ['axiosInstance']),
    ...mapState('prp', ['prpCategoryList']),

    categoryTypeOptions() {
      return Object.values(this.categoryTypes).reduce((acc, type) => {
        acc[type.value] = type.text;
        return acc;
      }, {});
    },
  },

  mounted() {
    this.fetchPrpCategoryList();
  },

  methods: {
    ...mapActions('prp', ['fetchPrpCategoryList']),

    closeDeleteCategoryModal() {
      this.showDeleteCategoryModal = false;
      this.fetchPrpCategoryList();
    },

    handleDropdownAction(option, element) {
      if (option.value === 'edit') {
        this.categoryToEdit = element;
        this.mode = 'Edit';
        this.showAddEditCategoryModal = true;
        this.updateKey += 1;
      } else if (option.value === 'delete') {
        this.categoryToDelete = element;
        this.showDeleteCategoryModal = true;
      }
    },

    reindexCategories() {
      let formData = {
        category_order: {},
      };

      const list = this.prpCategoryList;
      list.forEach((category, index) => {
        category.order_index = index;
        formData.category_order[index] = {
          uid: category.uid,
          category_type: category.category_type,
        };
      });

      this.axiosInstance.put(`api/v1/prp-category-order/`, formData);
    },

    formatItemsList(items) {
      if (!items?.length) return '';
      return items.map(item => item.value).join(', ');
    },

    addNewCategory() {
      this.mode = 'Add';
      this.showAddEditCategoryModal = true;
    },

    closeAddEditCategoryModal() {
      this.showAddEditCategoryModal = false;
      this.$getUser(this.$keycloak, this.axiosInstance, this.$store);
    },
  },
};
</script>
