<template>
  <div class="flex flex-col mx-4">
    <EstimationAndBenchmark
      type="price"
      :mouse-hover="mouseHover === 'market_price_pl'"
      :chain="chain"
      :process-chains="chains"
      :value="currentPrice"
      unit="€"
    />
    <div class="cursor-pointer" @click="trigger()">
      <OpenDetails 
        :show="Boolean(mouseHover === 'market_price_pl')" 
      />
    </div>
  </div>
</template>

<script>
import { markRaw } from 'vue';
import { mapMutations } from 'vuex';

import EstimationAndBenchmark from '../../components/EstimationAndBenchmark.vue';
import OpenDetails from '../../components/OpenDetails.vue';

import PricePopup from './components/PricePopup.vue';

import { analysisFinished } from '@/composables/processChainHandling.js';

export default {
  name: 'Price',

  components: {
    OpenDetails,
    EstimationAndBenchmark,
  },

  props: {
    chain: {
      type: Object,
      default() {
        return {};
      },
    },

    chains: {
      type: Object,
      default() {
        return {};
      },
    },

    mouseHover: { type: String, default: '' },
  },

  computed: {
    analysisFinished() {
      return analysisFinished(this.chain.process_chain_id);
    },

    currentPrice() {
      if (!this.analysisFinished || this.chain.status !== 'finished') return 0;
      return Number(
        this.chain.market_price?.market_price_pl?.user ?? 
        this.chain.market_price?.market_price_pl?.calculated ?? 
        0
      );
    },
  },

  methods: {
    ...mapMutations(['triggerPopup', 'changeInvestigationDetails']),

    trigger() {
      this.changeInvestigationDetails({ uid: this.chain.process_chain_id });
      this.triggerPopup({
        key: this.$options.name,
        show: true,
        title: 'Price',
        type: 'component',
        buttons: true,
        buttonContent: [{ text: 'Close', type: 'outlined', value: 'cancel' }],
        data: markRaw(PricePopup),
      });
    },
  },
};
</script>