<template>
  <div class="w-full">
    <div class="flex flex-col">
      <!-- 
      Market Price Section
      <div class="flex justify-between items-center text-13 mb-8">
        <span class="text-gray-500">Market price</span>
        <span class="text-primary-500 cursor-pointer hover:text-primary-600">Request again</span>
      </div>
      <div class="text-12 text-gray-500">Requested on {{ formatDate(marketPriceRequestDate) }}</div>
    </div> 
    @julia this part was on the wireframe, but are we still using the request market prices?
    -->
    <!-- Price Comparison Table -->
    <div class="mb-24">
      <h3 class="font-semibold text-15 mb-16">Price Overview</h3>
        <SparkSmallTable
          :columns="comparisonColumns"
          :rows="comparisonData"
        >
          <template #cell-perPart="{ value, row }">
            <template v-if="row.name === 'Pricing Configuration' || row.name === 'Gross Margin'">
              {{ value }}
            </template>
            <template v-else>
              {{ $formatTwoDecimalPlaces(value) }} €
            </template>
          </template>
          <template #cell-perLot="{ value, row }">
            <template v-if="row.name === 'Pricing Configuration' || row.name === 'Gross Margin'">
              {{ value }}
            </template>
            <template v-else>
              {{ $formatTwoDecimalPlaces(value) }} €
            </template>
          </template>
        </SparkSmallTable>
      </div>
      <!-- Tiered Pricing Section -->
      <div class="border border-gray-100 rounded-8 p-16">
        <div class="flex justify-between items-center mb-16">
          <h3 class="font-semibold text-15">Tiered pricing</h3>
          <SparkSelect
            v-model="selectedPricingConfigId"
            :options="availablePricingConfigs"
            name="pricing-config"
            class="w-[200px]"
            @update:model-value="updatePricingConfig"
          />
        </div>
        <div class="text-13 text-gray-500 mb-8">Price comparisson</div>
        
        <SparkSmallTable
        v-if="!loading"
          :columns="tableColumns"
          :rows="tieredPricingData"
        >
          <template #cell-pricePerPart="{ value }">
            {{ $formatTwoDecimalPlaces(value) }} €
          </template>
        </SparkSmallTable>
        <div v-else class="flex justify-center py-16">
          <SparkSpinner />
        </div>
      </div>

      <!-- Price Chart -->
      <div class="mt-24 mb-40">
        <h3 class="font-semibold text-15 mb-16">Price Analysis</h3>
        <PriceChart 
          :x-values="priceAnalysis.x_values"
          :y-values="priceAnalysis.y_values"
          :loading="isLoadingPrices"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import PriceChart from './PriceChart.vue';

import SparkSelect from '@/components/SparkComponents/SparkSelect.vue';
import SparkSmallTable from '@/components/SparkComponents/SparkSmallTable.vue';
import SparkSpinner from '@/components/SparkComponents/SparkSpinner.vue';

export default {
  name: 'PriceCard',

  components: {
    PriceChart,
    SparkSelect,
    SparkSmallTable,
    SparkSpinner,
  },

  data() {
    return {
      selectedPricingConfigId: null,
      tableColumns: [
        {
          key: 'lotSize',
          title: 'Lot size',
          sortable: true,
        },
        {
          key: 'costPerPart',
          title: 'Cost per Part',
          sortable: true,
          unit: '€',
        },
        {
          key: 'pricePerPart',
          title: 'Price per part',
          sortable: true,
          unit: '€',
        },
      ],

      loading: false,
      isLoadingPrices: false,
      priceAnalysis: {
        x_values: [],
        y_values: [],
      },

      priceAnalysisData: null,
      lotSizes: [1, 5, 10],

      comparisonColumns: [
        {
          key: 'name',
          title: '',
          sortable: false,
        },
        {
          key: 'perPart',
          title: 'Per Part',
          sortable: false,
        },
        {
          key: 'perLot',
          title: 'Per Lot',
          sortable: false,
        },
      ],
    };
  },

  computed: {
    ...mapState(['investigationDetails', 'part', 'pricingOptions']),

    activeProcessChain() {
      return this.part.process_chains[this.investigationDetails.uid];
    },

    availablePricingConfigs() {
      return this.pricingOptions.map(config => ({
        value: config.uid,
        label: config.name,
      }));
    },

    tieredPricingData() {
      if (!this.priceAnalysisData) return [];
      
      return this.lotSizes.map(lotSize => {
        const index = this.priceAnalysisData.x_values.findIndex(x => x === lotSize);
        return {
          lotSize,
          costPerPart: index !== -1 ? this.priceAnalysisData.cost_pp_values[index] : null,
          pricePerPart: index !== -1 ? this.priceAnalysisData.y_values[index] : null,
        };
      });
    },

    comparisonData() {
      const lotSize = this.activeProcessChain.lot_size || 1;
      const costPerPart = this.activeProcessChain.cost?.cost_pp || 0;
      const costPerLot = this.activeProcessChain.cost?.cost_pl || 0;
      
      const pricePerLot = this.activeProcessChain.market_price?.market_price_pl?.user || 
                         this.activeProcessChain.market_price?.market_price_pl?.calculated || 0;
      const pricePerPart = pricePerLot / lotSize;
      
      const marginPerPart = pricePerPart - costPerPart;
      const marginPercentage = costPerPart > 0 ? ((marginPerPart / costPerPart) * 100).toFixed(1) : 0;

      return [
        {
          name: 'Cost',
          perPart: costPerPart,
          perLot: costPerLot,
        },
        {
          name: 'Gross Margin',
          perPart: `${marginPercentage}%`,
          perLot: `${marginPercentage}%`,
        },
        {
          name: 'Pricing Configuration',
          perPart: this.pricingOptions.find(config => config.uid === this.selectedPricingConfigId)?.name || '-',
          perLot: this.pricingOptions.find(config => config.uid === this.selectedPricingConfigId)?.name || '-',
        },
        {
          name: 'Price',
          perPart: pricePerPart,
          perLot: pricePerLot,
        },
      ];
    },

    currentPrice() {
      const userPrice = this.activeProcessChain.market_price?.market_price_pl?.user;
      if (userPrice !== null && userPrice !== undefined) {
        return userPrice;
      }

      if (!this.priceAnalysisData) return null;
      const lotSize = this.activeProcessChain.lot_size || 1;
      const index = this.priceAnalysisData.x_values.findIndex(x => x === lotSize);
      
      if (index === -1) {
        const closestIndex = this.priceAnalysisData.x_values.reduce((prev, curr, i) => 
          Math.abs(curr - lotSize) < Math.abs(this.priceAnalysisData.x_values[prev] - lotSize) ? i : prev
        , 0);
        return this.priceAnalysisData.y_values[closestIndex];
      }
      
      return this.priceAnalysisData.y_values[index];
    },
  },

  watch: {
    selectedPricingConfigId: {
      immediate: true,
      handler(newConfigId) {
        if (newConfigId) {
          this.calculatePrices();
        }
      },
    },
  },

  mounted() {
    this.initializePricingConfig();
  },

  methods: {

    initializePricingConfig() {
      this.selectedPricingConfigId = this.activeProcessChain.pricing_config_id || 
                                   this.availablePricingConfigs[0]?.value;
    },

    async calculatePrices() {
      if (!this.selectedPricingConfigId || !this.activeProcessChain?.process_chain_id) return;

      try {
        this.loading = true;
        this.isLoadingPrices = true;
        const response = await this.$store.state.application.axiosInstance.post(
          `/api/v1/pricing-config-analysis/${this.selectedPricingConfigId}/`,
          {
            process_chain_id: this.activeProcessChain.process_chain_id,
          }
        );
        
        this.priceAnalysisData = response.data;
        this.priceAnalysis = response.data;
      } catch (error) {
        this.$root.notifyError('Price Analysis', error);
        this.priceAnalysisData = null;
        this.priceAnalysis = { x_values: [], y_values: [] };
      } finally {
        this.loading = false;
        this.isLoadingPrices = false;
      }
    },

    async updatePricingConfig(configId) {
      try {
        await this.$store.state.application.axiosInstance.put(
          `/api/v1/process-chain/${this.activeProcessChain.process_chain_id}/`,
          { pricing_config_id: configId }
        );
        // Recalculate prices with new config
        await this.calculatePrices();
      } catch (error) {
        this.$root.notifyError('Update Pricing Config', error);
      }
    },
  },
};
</script>