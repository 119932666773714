<template>
  <SparkModal :model-value="show" @update:model-value="close">
    <template #header>
      <div class="text-18 font-bold" v-text="header" />
    </template>

    <template #default>
      <div class="flex items-center justify-between">
        <div class="font-bold text-16" v-text="'Name*'" />
        <SparkInput v-model="name" custom="!w-[300px] !mr-150 my-8" name="name" />
      </div>

      <div class="flex items-center justify-between mb-48">
        <div class="font-bold text-16" v-text="'Restriction*'" />
        <div class="flex flex-col gap-4">
          <SparkSelect v-model="option" custom="!w-[300px] !mr-150 my-8" :options="restrictions" name="role" />
          <span v-text="warningText" />
        </div>
      </div>
      <div class="flex justify-end gap-16">
        <SparkButton variant="outlined" @click="close">
          <div v-text="'Cancel'" />
        </SparkButton>
        <SparkButton custom="!min-w-[80px]" variant="secondary" @click="save">
          <div v-text="mode === 'edit' ? 'Change' : 'Save'" />
        </SparkButton>
      </div>
    </template>
  </SparkModal>
</template>

<script>
import { mapState } from 'vuex';

import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkInput from '@/components/SparkComponents/SparkInput.vue';
import SparkModal from '@/components/SparkComponents/SparkModal.vue';
import SparkSelect from '@/components/SparkComponents/SparkSelect.vue';

export default {
  name: 'AddEditProfileModal',

  components: { SparkModal, SparkSelect, SparkButton, SparkInput },

  props: {
    show: { type: Boolean },
    mode: { type: String, default: 'add' },
    currentUid: { type: String, default: '' },
    currentName: { type: String, default: '' },
    currentOption: { type: String, default: '' },
    externalProfile: { type: [Object, String], default: () => ({}) },
  },

  emits: ['close', 'save'],

  data() {
    return {
      option: '',
      name: '',
      warningText: '',
    };
  },

  computed: {
    ...mapState(['user']),

    header() {
      return this.mode === 'add' ? 'Add Profile' : 'Edit Profile';
    },

    restrictions() {
      if (this.user.user_role === 'manager' && !this.user.is_external) {
        return [
          { label: 'Owner', value: 'owner' },
          { label: 'PRP', value: 'external' },
          { label: 'Organization', value: 'organization' },
        ];
      } else {
        return [{ label: 'Owner', value: 'owner' }];
      }
    },
  },

  watch: {
    show(value) {
      if (value) {
        this.option = this.currentOption;
        this.name = this.currentName;
      }
    },

    currentUid: {
      handler() {
        this.validityCheckExternalProfileText(this.currentUid);
      },

      deep: true,
    },
  },

  methods: {
    close() {
      this.warningText = '';
      this.$emit('close');
    },

    save() {
      this.$emit('save', {
        name: this.name,
        restriction: this.option,
      });
      this.warningText = '';
      this.close();
    },

    validityCheckExternalProfileText(profileUidToEdit) {
      this.warningText = '';
      if (
        Object.keys(this.externalProfile).length !== 0 &&
        profileUidToEdit !== this.externalProfile.uid &&
        this.currentOption === 'external'
      ) {
        this.warningText = "⚠️ Current PRP profile '" + this.externalProfile.name + "' will be unset.";
      }
    },
  },
};
</script>
