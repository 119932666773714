<template>
  <div>
    <SparkModal dismissable :model-value="show" class="w-[500px]" @update:model-value="close">
      <template #header>{{ mode }} process step </template>
      <template #default>
        <SparkInput
          v-model="form.name"
          class="mb-20 ml-4"
          name="name"
          label="Name *"
          max-length="100"
          :error="errors['name']"
          @input="validateField('name')"
        />

        <SparkSelect
          v-if="userIsPowerUserOrUser"
          v-model="powerUserRestriction"
          class="mb-20 ml-4 pt-8 w-[300px]"
          disabled
          :options="restrictionOwner"
          name="restriction"
          label="Restriction"
        />

        <SparkSelect
          v-else
          v-model="form.restriction"
          class="mb-20 ml-4 pt-8"
          :options="restrictions"
          name="restriction"
          label="Restriction"
        />
        <SparkSelect
          v-model="form.prc_acronym"
          class="mb-20 ml-4"
          :options="processes"
          name="prc_acronym"
          label="Processes *"
          :error="errors['prc_acronym']"
          @update:model-value="validateField('prc_acronym')"
        />
        <SparkSelect
          v-model="form.step_type"
          class="mb-20 ml-4"
          :options="stepTypes"
          name="step_type"
          label="Step Type *"
          :error="errors['step_type']"
          @update:model-value="validateField('step_type')"
        />
        <SparkSelect
          v-model="form.calc_lvl"
          class="ml-4"
          :options="calculationLvls"
          name="calc_lvl"
          label="Calculation Level *"
          :error="errors['calc_lvl']"
          @update:model-value="validateField('calc_lvl')"
        />

        <div
          v-if="form.calc_lvl === 'expression'"
          class="mt-2 ml-2"
          title="Per part cost expression may include 'part_volume' [cm³], 'part_surface' [cm²] or support_surface [cm²]"
        >
          <CustomExpression
            :options="expressionOptions"
            label="Expression"
            :reset="reset"
            :incoming-actual-value="form.calc_expression"
            :incoming-words="form.calc_expression_words"
            class="pb-24"
            @input="appendToCalcExpression"
          />
        </div>

        <div v-if="form.calc_lvl !== 'expression'" class="text-13 text-gray-500 ml-4 flex items-center">
          <label for="setup_time" class="w-200">Setup Time (hh:mm:ss)</label>
          <FeedbackValueTimespan
            editable
            :feedback-value="form.setup_time"
            @feedback-value-changed="setTime('setup_time', $event)"
          />
        </div>
        <div v-if="form.calc_lvl !== 'expression'" class="text-13 text-gray-500 ml-4 flex items-center">
          <label for="process_time" class="w-200">Process Time (hh:mm:ss)</label>
          <FeedbackValueTimespan
            editable
            :feedback-value="form.process_time"
            @feedback-value-changed="setTime('process_time', $event)"
          />
        </div>
        <div v-if="form.calc_lvl !== 'expression'" class="text-13 text-gray-500 ml-4 flex items-center">
          <label for="unloading_time" class="w-200">Unload Time (hh:mm:ss)</label>
          <FeedbackValueTimespan
            editable
            :feedback-value="form.unloading_time"
            @feedback-value-changed="setTime('unloading_time', $event)"
          />
        </div>

        <SparkInput
          v-model="form.fixed_cost"
          class="ml-4 mb-20"
          name="fixed_cost"
          type="number"
          label="Fixed Cost (€)"
        />
        <SparkInput
          v-model.number="form.machine_hourly_rate"
          name="machine_hourly_rate"
          type="number"
          label="Machine Hourly Rate (€/ h) *"
          class="mb-20 ml-4"
          :error="errors['machine_hourly_rate']"
          @input="validateField('machine_hourly_rate')"
        />

        <SparkInput
          v-model.number="form.staff_time_factor_percent"
          name="staff_time_factor"
          type="number"
          :error="errors['staff_time_factor_percent']"
          label="Staff Time Factor (%)*"
          class="ml-4 pt-8"
          @input="validateField('staff_time_factor_percent')"
        />

        <SparkSelect
          v-model="form.worker_type"
          class="mb-16 ml-4 pt-8"
          :options="workerTypeOptions"
          name="worker_type"
          label="Worker Type *"
          :error="errors['worker_type']"
          @update:model-value="validateField('worker_type')"
        />
        <div class="mt-2 ml-2 text-gray-500">Co2 Expression</div>
        <CustomExpression
          :options="expressionOptions"
          label="Co2 Expression"
          :reset="reset"
          :incoming-actual-value="form.co2_expression"
          :incoming-words="form.co2_expression_words"
          class="pb-24 ml-2"
          @input="appendToCo2Expression"
        />
        <div v-if="showConfirmationDialog" class="text-16 text-black ml-4 my-16 bg-red-50 rounded-6 p-8">
          <div class="text-16 text-red-500 flex">
            <img src="@/assets/img/red_exclamation_mark.svg" class="mr-16" />
            <div>{{ confirmationMessage }}</div>
          </div>
        </div>
        <div class="flex justify-end gap-8">
          <SparkButton class="mt-8" variant="outlined" @click="close">Cancel</SparkButton>
          <div v-if="!showConfirmationDialog">
            <SparkButton class="mt-8" variant="secondary" @click="validateFields">Save</SparkButton>
          </div>
          <div v-else>
            <SparkButton class="mt-8" variant="secondary" @click="confirmUpdate">Confirm Changes</SparkButton>
          </div>
        </div>
      </template>
    </SparkModal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { z } from 'zod';

import CustomExpression from '@/components/General/CustomExpression.vue';
import FeedbackValueTimespan from '@/components/Reusable/FeedbackValues/FeedbackValueTimespan.vue';
import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkInput from '@/components/SparkComponents/SparkInput.vue';
import SparkModal from '@/components/SparkComponents/SparkModal.vue';
import SparkSelect from '@/components/SparkComponents/SparkSelect.vue';

export default {
  name: 'CreateNewProcessChainStep',

  components: {
    FeedbackValueTimespan,
    SparkInput,
    SparkSelect,
    SparkButton,
    SparkModal,
    CustomExpression,
  },

  props: {
    filledForm: {
      type: Object,
      default() {
        return {};
      },
    },

    show: { type: Boolean },

    mode: { type: String, default: '' },
  },

  emits: ['created', 'close'],

  data() {
    return {
      restrictions: [
        { value: 'owner', label: 'Owner' },
        { value: 'organization', label: 'Organization' },
      ],

      restrictionOwner: [{ value: 'owner', label: 'Owner' }],

      powerUserRestriction: 'owner',

      stepTypes: [
        { value: 'pre', label: 'Pre' },
        { value: 'post', label: 'Post' },
        { value: 'main', label: 'Main' },
      ],

      form: {
        setup_time: '00:00:00',
        process_time: '00:00:00',
        unloading_time: '00:00:00',
      },

      emptyForm: {
        name: '',
        prc_acronym: '',
        step_type: '',
        calc_lvl: '',
        machine_hourly_rate: 0,
        staff_time_factor_percent: 0,
        worker_type: '',
        setup_time: '00:00:00',
        process_time: '00:00:00',
        unloading_time: '00:00:00',
      },

      reset: 0,

      errors: {},

      showConfirmationDialog: false,
      confirmationMessage: '',
      pendingUpdate: null,

      calcExpression: '',
      co2Expression: '',
    };
  },

  computed: {
    ...mapState(['workerTypes', 'calcLvls', 'user', 'expressionOptions']),
    ...mapState('application', ['axiosInstance']),

    processSynonyms() {
      return this.user.organization.process_synonyms;
    },

    processes() {
      let arr = [];
      arr.push({ value: 'all_processes', label: 'All Processes' });
      Object.keys(this.processSynonyms).forEach(key => {
        arr.push({
          value: this.processSynonyms[key].original_prc_acronym,
          label: this.processSynonyms[key].prc_name,
        });
      });
      arr.sort((a, b) => a.label.localeCompare(b.label));
      return arr;
    },

    schema() {
      return z.object({
        name: z.string().min(1, 'Required').max(100, 'Maximal length is 100'),
        prc_acronym: z.string().min(1, 'Required'),
        step_type: z.string().min(1, 'Required'),
        calc_lvl: z.string().min(2, 'Required'),
        machine_hourly_rate: z
          .number({
            invalid_type_error: 'Must be a number',
            coerce: true, // This will attempt to convert strings to numbers
          })
          .min(0, 'Minimum value is 0')
          .max(100000, 'Maximal value is 100000'),
        staff_time_factor_percent: z
          .number({
            invalid_type_error: 'Must be a number',
            coerce: true, // This will attempt to convert strings to numbers
          })
          .min(0, 'Minimum value is 0')
          .max(100, 'Maximal value is 100'),
        worker_type: z.string().min(1, 'Required'),
      });
    },

    userIsPowerUserOrUser() {
      return this.user.user_role === 'poweruser' || this.user.user_role === 'user';
    },

    calculationLvls() {
      let arr = [];
      Object.keys(this.calcLvls).forEach(key => {
        arr.push({ label: this.calcLvls[key].text, value: this.calcLvls[key].value });
      });
      return arr;
    },

    workerTypeOptions() {
      let arr = [];
      Object.keys(this.workerTypes).forEach(key => {
        arr.push({ label: this.workerTypes[key].text, value: this.workerTypes[key].value });
      });
      return arr;
    },
  },

  watch: {
    filledForm: {
      handler(newValue) {
        this.form = { ...newValue };
      },

      deep: true,
    },
  },

  methods: {
    close() {
      this.$emit('close');
      this.reset++;
      this.errors = {};
    },

    appendToCalcExpression(expression) {
      this.calcExpression = expression;
    },

    appendToCo2Expression(expression) {
      this.co2Expression = expression;
    },

    action() {
      let times = ['unloading_time', 'setup_time', 'process_time'];
      times.forEach(time => {
        if (this.form[time] === '00:00') {
          this.form[time] = 0;
        }
      });

      if (this.form['prc_acronym'] === 'all_processes') {
        this.form['prc_acronym'] = null;
      }

      this.form.staff_time_factor = this.form.staff_time_factor / 100;
      this.form.calc_expression = this.calcExpression;
      this.form.co2_expression = this.co2Expression;
      if (this.mode === 'Create') {
        this.axiosInstance.post('/api/v1/process-step-template/', this.form).then(() => {
          this.$emit('created');
          this.close();
          this.$root.notify('success', this.form.name, 'Process step created', '', 10000);
        });
      } else if (this.mode === 'Edit') {
        this.editProcessStep();
      }
    },

    async editProcessStep() {
      const response = await this.axiosInstance.put(`/api/v1/process-step-template/${this.form.uid}/`, this.form);
      if (response.data.needs_confirmation) {
        this.showConfirmationDialog = true;
        this.confirmationMessage = response.data.message;
        this.pendingUpdate = { ...this.form, confirm_edit: true };
      } else {
        this.handleSuccessfulUpdate();
      }
    },

    handleSuccessfulUpdate() {
      this.$emit('created');
      this.close();
      this.reset++;
      this.$root.notify('success', this.form.name, 'Process step edited', '', 10000);
    },

    async confirmUpdate() {
      try {
        await this.axiosInstance.put(`/api/v1/process-step-template/${this.form.uid}/`, this.pendingUpdate);
        this.handleSuccessfulUpdate();
      } catch (error) {
        console.error('Error confirming update:', error);
        this.$root.notify('error', 'Failed to confirm update', '', 10000);
      } finally {
        this.showConfirmationDialog = false;
        this.pendingUpdate = null;
      }
    },

    validateFields() {
      const result = this.schema.safeParse(this.form);
      const errors = result.error?.issues;
      if (!errors) {
        this.action();
        return;
      }

      this.errors = errors.reduce((acc, issue) => {
        acc[issue.path[0]] = issue.message;
        return acc;
      }, {});
    },

    validateField(field) {
      const result = this.schema.safeParse(this.form);
      const errors = result.error?.issues;
      if (errors) {
        this.errors[field] = errors.find(issue => issue?.path[0] === field)?.message || '';
      } else {
        this.errors[field] = '';
      }
    },

    setTime(str, value) {
      this.form[str] = value;
    },
  },
};
</script>
