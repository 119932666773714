export const addCustomMaterialForm = {
  General: [
    {
      name: 'Name',
      db_name: 'mat_name',
      field: 'text',
      options: '',
      unit: '',
      required: true,
      prc: '',
      component: 'SparkInput',
      value: null,
    },
    {
      name: 'Access',
      db_name: 'restriction',
      field: 'select',
      options: [],
      unit: '',
      required: false,
      prc: '',
      component: 'SparkSelect',
      value: null,
    },
    {
      name: 'OEM Name',
      db_name: 'oem_name',
      field: 'text',
      options: '',
      unit: '',
      required: true,
      prc: '',
      component: 'SparkInput',
      value: null,
    },
    {
      name: 'Technology',
      db_name: 'tech',
      field: 'select',
      options: [],
      unit: '',
      required: true,
      prc: '',
      component: 'SparkSelect',
      value: null,
    },
    {
      name: 'Process',
      db_name: 'prc_uid',
      field: 'select',
      options: [],
      unit: '',
      required: true,
      prc: '',
      component: 'SparkSelect',
      value: null,
    },
    {
      name: 'Raw Material Cost',
      db_name: 'raw_mat_cost',
      field: 'number',
      options: [1, 1000000],
      unit: '€/kg',
      required: true,
      prc: '',
      component: 'SparkInput',
      value: null,
    },
    {
      name: 'Material Density',
      db_name: 'mat_rho',
      field: 'number',
      options: [0.1, 20],
      unit: 'g/cm³',
      required: true,
      prc: '',
      component: 'SparkInput',
      value: null,
    },
    {
      name: 'Color',
      db_name: 'color',
      field: 'text',
      options: '',
      unit: '',
      required: false,
      prc: '',
      component: 'SparkInput',
      value: null,
    },
    {
      name: 'Default Machine',
      db_name: 'mac_id',
      field: 'select',
      options: [],
      unit: '',
      required: false,
      prc: '',
      component: 'SparkSelect',
      value: null,
    },
    {
      name: 'Material Level 2',
      db_name: 'mat_lvl_2_id',
      field: 'select',
      options: [],
      unit: '',
      required: false,
      prc: '',
      component: 'SparkSelect',
      value: null,
    },
    {
      name: 'Id',
      db_name: 'mat_id',
      field: 'text',
      options: '',
      unit: '',
      required: false,
      prc: '',
      component: 'SparkInput',
      value: null,
    },
  ],
  'Additive Manufacturing': [
    {
      name: 'Infill Density',
      db_name: 'infill_phi',
      field: 'number',
      options: [0, 100],
      unit: '%',
      required: false,
      prc: 'fdm',
      component: 'SparkInput',
      value: null,
      isPercent: true
    },
    {
      name: 'Relative density of support structures',
      db_name: 'sup_phi',
      field: 'number',
      options: [0, 1],
      unit: '',
      required: false,
      prc: '',
      component: 'SparkInput',
      value: null,
    },
    {
      name: 'Ratio of built material, that cannot be recycled.',
      db_name: 'c_wst',
      field: 'number',
      options: [0, 1],
      unit: '',
      required: false,
      prc: '',
      component: 'SparkInput',
      value: null,
    },
    {
      name: 'Minimal printable supportless overhang angle (horizontal-to-surface angle)',
      db_name: 'min_ang',
      field: 'number',
      options: '',
      unit: '°',
      required: false,
      prc: '',
      component: 'SparkInput',
      value: null,
    },
    {
      name: 'Layer Height',
      db_name: 'lyr_h',
      field: 'number',
      options: [0.001, 100000],
      unit: 'mm',
      required: false,
      prc: '',
      component: 'SparkInput',
      value: null,
    },
    {
      name: 'Wall Thickness',
      db_name: 'infill_custom_wall_thickness',
      field: 'number',
      options: [0, 100000],
      unit: 'mm',
      required: false,
      prc: 'fdm',
      component: 'SparkInput',
      value: null,
    },
    {
      name: 'Built Rate (Core)',
      db_name: 'bld_spd_fill',
      field: 'number',
      options: [0.001, 100000],
      unit: 'cm³/h',
      required: false,
      prc: '',
      component: 'SparkInput',
      value: null,
    },
    {
      name: 'Embodied Carbon',
      db_name: 'co2_per_kg_min',
      field: 'number',
      options: [-100000, 100000],
      unit: 'kgCO₂/kg',
      required: false,
      prc: '',
      component: 'SparkInput',
      value: null,
    },
    {
      name: 'Build Rate (Contour)',
      db_name: 'bld_spd_contour',
      field: 'number',
      options: [0.001, 100000],
      unit: 'cm³/h',
      required: false,
      prc: '',
      component: 'SparkInput',
      value: null,
    },
    {
      name: 'Minimal printable wall thickness',
      db_name: 't_lim_min',
      field: 'number',
      options: [0.001, 100000],
      unit: 'mm',
      required: false,
      prc: '',
      component: 'SparkInput',
      value: null,
    },
    {
      name: 'Maximum printable wall thickness',
      db_name: 't_lim_max',
      field: 'number',
      options: [0.001, 100000],
      unit: 'mm',
      required: false,
      prc: '',
      component: 'SparkInput',
      value: null,
    },
    {
      name: 'Minimal printable gap size',
      db_name: 'gap_lim_min',
      field: 'number',
      options: [0.001, 100000],
      unit: 'mm',
      required: false,
      prc: '',
      component: 'SparkInput',
      value: null,
    },
    {
      name: 'Minimal printable size',
      db_name: 'size_lim_min',
      field: 'number',
      options: [0.001, 100000],
      unit: 'mm',
      required: false,
      prc: '',
      component: 'SparkInput',
      value: null,
    },
    {
      name: 'Maximum general part size',
      db_name: 'size_lim_max',
      field: 'number',
      options: [0.001, 100000],
      unit: 'mm',
      required: false,
      prc: '',
      component: 'SparkInput',
      value: null,
    },
    {
      name: 'Scaling factors to respect process and sintering shrinkage (x, y, z)',
      db_name: 'scaling_factors',
      field: 'scaling-custom',
      options: '',
      unit: '-',
      required: false,
      prc: '',
      component: 'SparkInput',
      value: null,
    },
    {
      name: 'Bounding Box Price',
      db_name: 'price_per_bounding_box_volume',
      field: 'number',
      options: [0.0, 1000000],
      unit: '€/cm³',
      required: false,
      prc: '',
      component: 'SparkInput',
      value: null,
    },
  ],
  Casting: [
    {
      name: 'Minimum allowed edge radius for Sharp Edges Detection',
      db_name: 'radii_lim_min',
      field: 'number',
      options: [0, 100000],
      unit: 'mm',
      required: false,
      prc: '',
      component: 'SparkInput',
      value: null,
    },
    {
      name: 'Minimum allowed mean wall thickness',
      db_name: 't_mean_lim_min',
      field: 'number',
      options: [0, 100000],
      unit: 'mm',
      required: false,
      prc: '',
      component: 'SparkInput',
      value: null,
    },
  ],
  Milling: [
    {
      name: 'Milling rate for roughing',
      db_name: 'milling_rate_roughing',
      field: 'number',
      options: [0.001, 100000],
      unit: 'cm³/h',
      required: false,
      prc: '',
      component: 'SparkInput',
      value: null,
    },
    {
      name: 'Milling rate for surface finishing',
      db_name: 'milling_rate_finishing',
      field: 'number',
      options: [0.001, 100000],
      unit: 'cm²/h',
      required: false,
      prc: '',
      component: 'SparkInput',
      value: null,
    },
  ],
  'Fire and Smoke': [
    {
      name: 'EN45545',
      db_name: 'en45545',
      field: 'select',
      options: [],
      unit: '',
      required: false,
      prc: '',
      component: 'SparkSelect',
      value: null,
    },
    {
      name: 'Hazard Level',
      db_name: 'hazard_level',
      field: 'select',
      options: [],
      unit: '',
      required: false,
      prc: '',
      component: 'SparkSelect',
      value: null,
    },
    {
      name: 'UL94',
      db_name: 'ul94',
      field: 'select',
      options: [],
      unit: '',
      required: false,
      prc: '',
      component: 'SparkSelect',
      value: null,
    },
    {
      name: 'NFPA130',
      db_name: 'nfpa130',
      field: 'select',
      options: [],
      unit: '',
      required: false,
      prc: '',
      component: 'SparkSelect',
      value: null,
    },
    {
      name: 'Thickness Range',
      db_name: 'thickness_range',
      field: 'number',
      options: [0, 100000],
      unit: 'mm',
      required: false,
      prc: '',
      component: 'SparkInput',
      value: null,
    },
    {
      name: 'Safety Data Sheet',
      db_name: 'sds_link',
      field: 'text',
      options: '',
      unit: '',
      required: false,
      prc: '',
      component: 'SparkInput',
      value: null,
    },
  ],
};
export const populateFormValues = materialData => {
  let tempForm = JSON.parse(JSON.stringify(addCustomMaterialForm));
  function getValueFromMaterialData(materialData, db_name) {
    const keys = db_name.split('.');
    let value = materialData;
    for (let key of keys) {
      value = value ? value[key] : null;
      if (value === null || value === undefined) break;
    }
    return value;
  }

  function assignValues(formArray, data) {
    formArray.forEach(formField => {
      if (formField.db_name) {
        let value;

        if (data.general && data.general[formField.db_name] !== undefined) {
          value = data.general[formField.db_name];
        } else {
          value = getValueFromMaterialData(data, formField.db_name);
        }

        if (value !== undefined && value !== null) {
          formField.value = value;
        }
      }
    });
  }

  for (const section in tempForm) {
    if (Array.isArray(tempForm[section])) {
      assignValues(tempForm[section], materialData);
    }
  }

  return tempForm;
};

export const addCustomMachineForm = [
  {
    name: 'Name',
    db_name: 'mac_name',
    field: 'text',
    options: '',
    unit: '',
    required: false,
    prc: '',
    component: 'SparkInput',
    value: null,
  },
  {
    name: 'OEM Name',
    db_name: 'oem_name',
    field: 'text',
    options: '',
    unit: '',
    required: true,
    prc: '',
    component: 'SparkInput',
    value: null,
  },
  {
    name: 'Process',
    db_name: 'prc_uid',
    field: 'select',
    options: [],
    unit: '',
    required: true,
    prc: '',
    component: 'SparkSelect',
    value: null,
  },
  {
    name: 'Machine Hourly Rate',
    db_name: 'mac_cost_ph',
    field: 'number',
    options: [0.1, 100000],
    unit: '€/h',
    required: true,
    prc: '',
    component: 'SparkInput',
    value: null,
  },
  {
    name: 'Build envelope x-direction',
    db_name: 'bld_size_x',
    field: 'number',
    options: [0.1, 100000],
    unit: 'mm',
    required: true,
    prc: '',
    component: 'SparkInput',
    value: null,
  },
  {
    name: 'Build envelope y-direction',
    db_name: 'bld_size_y',
    field: 'number',
    options: [0.1, 100000],
    unit: 'mm',
    required: true,
    prc: '',
    component: 'SparkInput',
    value: null,
  },
  {
    name: 'Build envelope z-direction',
    db_name: 'bld_size_z',
    field: 'number',
    options: [0.1, 100000],
    unit: 'mm',
    required: true,
    prc: '',
    component: 'SparkInput',
    value: null,
  },
  {
    name: 'Process time per layer',
    db_name: 'mac_prc_t_plyr',
    field: 'number',
    options: [0, 100000],
    unit: 's',
    required: false,
    prc: '',
    component: 'SparkInput',
    value: null,
  },
  {
    name: 'Fixed machine cost per job',
    db_name: 'fix_mac_cost_pj',
    field: 'number',
    options: [0, 1000000],
    unit: '€',
    required: false,
    prc: '',
    component: 'SparkInput',
    value: null,
  },
  {
    name: 'Setup time',
    db_name: 'setup_time',
    field: 'number',
    options: [0, 1000000],
    unit: 'h',
    required: false,
    prc: '',
    component: 'SparkInput',
    value: null,
  },
  {
    name: 'Unloading time',
    db_name: 'unloading_time',
    field: 'number',
    options: [0, 1000000],
    unit: 'h',
    required: false,
    prc: '',
    component: 'SparkInput',
    value: null,
  },
  {
    name: 'Machine idle time',
    db_name: 'mac_idl_t_pj',
    field: 'number',
    options: [0, 1000000],
    unit: 'h',
    required: false,
    prc: '',
    component: 'SparkInput',
    value: null,
  },
  {
    name: 'Layer Height',
    db_name: 'lyr_h',
    field: 'number',
    options: [0, 1000000],
    unit: 'mm',
    required: false,
    prc: '',
    component: 'SparkInput',
    value: null,
  },
  {
    name: 'Staff supervision time',
    db_name: 'staff_time_factor_percent',
    field: 'number',
    options: [0, 100],
    unit: '%',
    required: false,
    prc: '',
    component: 'SparkInput',
    value: null,
  },
  {
    name: 'Access',
    db_name: 'restriction',
    field: 'select',
    options: [],
    unit: '',
    required: false,
    prc: '',
    component: 'SparkSelect',
    value: null,
  },
  {
    name: 'Average Power Consumption',
    db_name: 'power_consumption',
    field: 'number',
    options: [0, 10000],
    unit: 'kW',
    required: false,
    prc: '',
    component: 'SparkInput',
    value: null,
  },
  {
    name: 'Id',
    db_name: 'mac_id',
    field: 'text',
    options: '',
    unit: '',
    required: false,
    prc: '',
    component: 'SparkInput',
    value: null,
  },
];

export const populateMachineFormValues = machineData => {
  let tempForm = JSON.parse(JSON.stringify(addCustomMachineForm));
  function getValueFromMachineData(machineData, db_name) {
    const keys = db_name.split('.');
    let value = machineData;
    for (let key of keys) {
      value = value ? value[key] : null;
      if (value === null || value === undefined) break;
    }
    return value;
  }

  function assignValues(formArray, data) {
    formArray.forEach(formField => {
      if (formField.db_name) {
        let value = getValueFromMachineData(data, formField.db_name);
        if (value !== undefined && value !== null) {
          formField.value = value;
        }
      }
    });
  }

  assignValues(tempForm, machineData);
  return tempForm;
};
