<template>
  <DropdownMenu
    title-icon="fas fa-ellipsis-h"
    :list-options="dropdownOptions"
    title-class="border border-gray-300 py-[7px] px-12 rounded-8"
    @update-option="handleOptionSelect($event)"
  />
  <AddEditProfileModal
    :show="showAddProfileModal"
    :external-profile="anotherProfileUidIsExternal"
    @save="createAnalysisProfile"
    @close="closeAddProfileModal"
  />
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex';

import AddEditProfileModal from '../modals/AddEditProfileModal.vue';

import DropdownMenu from '@/components/Reusable/DropdownMenu.vue';

export default {
  name: 'PartActionDropdown',

  components: {
    DropdownMenu,
    AddEditProfileModal,
  },

  data() {
    return {
      anotherProfileUidIsExternal: '',
      showAddProfileModal: false,
    };
  },

  computed: {
    ...mapState(['part', 'user', 'analysisProfiles']),
    ...mapState('application', ['axiosInstance']),
    processChainsNotExist() {
      return this.$store.state.part.process_chains.length === 0;
    },

    dropdownOptions() {
      return [
        {
          text: 'Save as profile',
          value: 'save_profile',
          icon: 'fas fa-save',
          disabled: this.processChainsNotExist,
        },
      ];
    },
  },

  methods: {
    handleOptionSelect(event) {
      if (event.value === 'save_profile') {
        this.create();
      }
    },

    ...mapMutations(['updateTemplatesAndProfiles']),
    ...mapActions(['fetchAnalysisTemplatesAndProfiles']),

    async create() {
      await this.validityCheckExternalProfileText();

      this.showAddProfileModal = true;
    },

    closeAddProfileModal() {
      this.showAddProfileModal = false;
    },

    async validityCheckExternalProfileText() {
      let text = '';

      if (this.analysisProfiles && Object.keys(this.analysisProfiles).length > 0) {
        Object.keys(this.analysisProfiles).forEach(key => {
          if (this.analysisProfiles[key].restriction === 'external') {
            this.anotherProfileUidIsExternal = this.analysisProfiles[key];
            text = "⚠️ Current PRP profile '" + this.analysisProfiles[key].name + "' will be unset.";
          }
        });
      } else {
        this.anotherProfileUidIsExternal = '';
      }

      return text;
    },

    async createAnalysisProfile(profile) {
      profile['process_chain_ids'] = Object.keys(this.part.process_chains);
      this.axiosInstance
        .post(`/api/v1/analysis-profile/`, profile)
        .then(response => {
          this.updateTemplatesAndProfiles(new Date());
          this.$root.notify(
            'success',
            'Creating Profile',
            `Profile '${response.data.name}' successfully created.`,
            6000
          );
          this.fetchAnalysisTemplatesAndProfiles();
        })
        .catch(error => {
          this.$root.notifyError('Creating Profile', error);
        });
    },
  },
};
</script>
