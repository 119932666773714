<template>
  <div class="flex p-20">
    <div class="flex flex-col w-1/3">
      <div class="text-16 font-bold">Cost Configuration</div>
      <div class="text-13 text-primary-500 cursor-pointer hover:text-primary-600" @click="addCostProfile">Add</div>
    </div>
    <div class="flex flex-col w-2/3 border border-gray-50 rounded-8">
      <div
        v-for="(config, key) in costingConfigs"
        :key="key"
        class="p-12 flexjustify-between items-center hover:bg-gray-50 hover:cursor-pointer border-b border-gray-50"
        @click="toggleConfig(config)"
      >
        <div class="flex justify-between w-full">
          <div class="flex justify-between items-center">
            <i v-if="configToShow !== config" class="fas fa-chevron-right text-gray-500 mr-8" />
            <i v-else class="fas fa-chevron-down text-primary-500 mr-8" />
            <div class="text-13 font-bold" v-text="config.name" />
            <SparkBadge v-if="key === defaultCostingConfigUid" class="ml-16" variant="success"
              ><div>Default</div>
            </SparkBadge>
          </div>
          <DropdownMenu
            :list-options="dropdownOptions[key]"
            title-icon="fas fa-ellipsis-h"
            close-click-style="always"
            @update-option="handleAction($event, config, key)"
            @click.stop
          />
        </div>
        <div v-if="configToShow === config">
          <div class="flex ml-20">
            <div class="w-1/5 text-13 text-gray-500">Name</div>
            <div class="text-13">{{ configToShow.name }}</div>
          </div>
          <div class="flex ml-20">
            <div class="w-1/5 text-13 text-gray-500">AM Expert</div>
            <div class="text-13">{{ configToShow.am_expert_hr }} €</div>
          </div>
          <div class="flex ml-20">
            <div class="w-1/5 text-13 text-gray-500">Designer</div>
            <div class="text-13">{{ configToShow.designer_hr }} €</div>
          </div>
          <div class="flex ml-20">
            <div class="w-1/5 text-13 text-gray-500">Technician</div>
            <div class="text-13">{{ configToShow.technician_hr }} €</div>
          </div>
          <div class="flex ml-20">
            <div class="w-1/5 text-13 text-gray-500">Overhead</div>
            <div class="text-13">{{ (configToShow.overhead * 100).toFixed(0) }}%</div>
          </div>
          <div class="flex ml-20">
            <div class="w-1/5 text-13 text-gray-500">Margin Default</div>
            <div class="text-13">{{ (configToShow.margin_default * 100).toFixed(0) }}%</div>
          </div>
        </div>
      </div>
    </div>
    <CostProfileModal
      :mode="mode"
      :show="showModal"
      :uid-to-edit="uidToEdit"
      :config-to-edit="configToEdit"
      @close="closeModal"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import CostProfileModal from './CostProfileModal.vue';

import DropdownMenu from '@/components/Reusable/DropdownMenu.vue';
import SparkBadge from '@/components/SparkComponents/SparkBadge.vue';
export default {
  name: 'CostProfileSettings',

  components: {
    DropdownMenu,
    SparkBadge,
    CostProfileModal,
  },

  data() {
    return {
      dropdownOptions: {},
      configToShow: null,
      configToEdit: null,
      showModal: false,
      mode: 'Add',
      uidToEdit: null,
    };
  },

  computed: {
    ...mapState(['user', 'organization']),
    ...mapState('application', ['axiosInstance']),

    defaultCostingConfigUid() {
      return this.organization.costing_config.uid;
    },

    costingConfigs() {
      return this.organization.costing_configs;
    },

    sparkCostingConfigUid() {
      return Object.keys(this.costingConfigs || {}).find(key => this.costingConfigs[key].name === '3D Spark');
    },
  },

  watch: {
    costingConfigs: {
      handler() {
        this.calculateDropownOptions();
      },

      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(['fetchOrganization']),

    calculateDropownOptions() {
      Object.keys(this.organization.costing_configs || {}).forEach(key => {
        const config = this.organization.costing_configs[key];
        if (config.name === '3D Spark') {
          this.dropdownOptions[key] = [
            { text: 'Edit', value: 'edit', icon: 'fas fa-edit', disabled: true },
            { text: 'Delete', value: 'delete', icon: 'fas fa-trash', disabled: true },
            { text: 'Set Default', value: 'setDefault', icon: 'fas fa-check', disabled: false },
          ];
        } else {
          this.dropdownOptions[key] = [
            { text: 'Edit', value: 'edit', icon: 'fas fa-edit', disabled: false },
            { text: 'Delete', value: 'delete', icon: 'fas fa-trash', disabled: false },
            { text: 'Set Default', value: 'setDefault', icon: 'fas fa-check', disabled: false },
          ];
        }
      });
    },

    closeModal() {
      this.showModal = false;
    },

    handleAction(option, config, uid) {
      switch (option.value) {
        case 'edit':
          this.configToEdit = config;
          this.uidToEdit = uid;
          this.mode = 'Edit';
          this.showModal = true;
          break;
        case 'delete':
          this.deleteConfig(uid);
          break;
        case 'setDefault':
          this.setDefaultProfile(uid);
          break;
      }
    },

    deleteConfig(uid) {
      this.axiosInstance
        .delete(`api/v1/costing-config/${uid}/`)
        .then(() => {
          this.fetchOrganization();
          this.$root.notify('success', 'Cost profile', 'Cost profile successfully deleted.', 3000);
          if (uid === this.defaultCostingConfigUid) {
            this.setDefaultProfile(this.sparkCostingConfigUid);
          }
          this.calculateDropownOptions();
        })
        .catch(error => {
          console.log(error.response);
        });
    },

    setDefaultProfile(uid) {
      if (uid === this.defaultCostingConfigUid) {
        this.$root.notify('warning', 'Cost profile', 'This is already the active cost profile.', 3000);
        return;
      }
      this.axiosInstance
        .post(`api/v1/costing-config-selection/${uid}/`)
        .then(() => {
          this.fetchOrganization();
        })
        .catch(error => {
          console.log(error.response);
        });
    },

    addCostProfile() {
      this.showModal = true;
      this.mode = 'Add';
      this.uidToEdit = null;
    },

    toggleConfig(config) {
      if (this.configToShow === config) {
        this.configToShow = null;
      } else {
        this.configToShow = config;
      }
    },
  },
};
</script>
