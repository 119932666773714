<template>
  <div class="rounded-8 p-16">
    <div>Take your first steps in 3D Spark and do the Onboarding Tour</div>
    <SparkButton class="mt-16" variant="primary" @click="pushRoute()">To Onboarding Tour</SparkButton>
  </div>
</template>

<script>
import SparkButton from '@/components/SparkComponents/SparkButton.vue';

export default {
  name: 'OnboardingTour',

  components: {
    SparkButton,
  },

  data() {
    return {};
  },

  methods: {
    pushRoute() {
      this.$router.push('/get-started');
    },
  },
};
</script>
