<template>
  <div class="flex flex-col w-full">
    <div v-if="pricingList.length > 0">
      <draggable :list="pricingList" item-key="order_index" @end="reindexPricings">
        <template #item="{ element }">
          <div class="mb-24 flex w-full items-center">
            <div class="cursor-move mr-10">&#x2630;</div>
            <div
              class="border w-full relative border-gray-100 shadow-sm rounded-6"
              :class="[{ ' border-2 border-primary-500': element.order_index === appliedPricing && showPrpSidebar }]"
            >
              <div class="pt-12 absolute top-0 right-0">
                <DropdownMenu
                  :list-options="dropdownOptions"
                  title-icon="fas fa-ellipsis-h"
                  close-click-style="always"
                  :title-style="{ 'font-size': 'var(--15px)', padding: '1px 0px 0px 0px' }"
                  @update-option="handleDropdownSelect($event, element)"
                />
              </div>
              <div v-if="element.ruleWords.length" class="border-b border-gray-100 pb-8 p-12">
                <div class="text-gray-400 my-2 text-14" v-text="'Condition'" />
                <div class="flex flex-wrap gap-8 text-11">
                  <span
                    v-for="(word, index) in element.ruleWords"
                    :key="index"
                    :class="{
                      'bg-gray-100 px-4 rounded-6': (word.length > 1 && !isOperatorOrConditional(word)) || !isNaN(word),
                    }"
                    v-text="word"
                  />
                </div>
              </div>
              <div
                v-if="element.expressionWords.length"
                c
                :class="{ 'border-b border-gray-100': element.custom_text }"
                class="p-12"
              >
                <div class="text-gray-400 my-4 text-14" v-text="'Price Calculation Formula'" />
                <div class="flex flex-wrap gap-8 text-11">
                  <span
                    v-for="(word, index) in element.expressionWords"
                    :key="index"
                    :class="{ 'bg-gray-100 px-4 rounded-6': word.length > 1 || !isNaN(word) }"
                    v-text="word"
                  />
                </div>
              </div>
              <div v-if="element.custom_text" class="px-12 pb-8 p-12">
                <div class="text-gray-400 my-4 text-14" v-text="'Custom text'" />
                <div class="flex flex-wrap gap-8 text-11" v-text="element.custom_text" />
              </div>
            </div>
          </div>
        </template>
      </draggable>
    </div>
    <div v-else-if="selectedPricingOptionUid && selectedPricingOptionUid !== 'combinedConfig' && !isCombinedConfig">
      <p>No pricings available.</p>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapState } from 'vuex';

import DropdownMenu from '@/components/Reusable/DropdownMenu.vue';

export default {
  name: 'ExpressionRuleContainer',

  components: { draggable, DropdownMenu },

  props: {
    pricingList: { type: Object, required: true },
    selectedPricingOptionUid: { type: String, required: true },
    appliedPricing: { type: Number, default: -1 },
  },

  emits: ['delete-pricing', 'duplicate-pricing', 'edit-pricing'],

  data() {
    return {
      activePricing: null,
      dropdownOptions: [
        { text: 'Edit', value: 'edit', icon: 'fas fa-edit', disabled: false },
        { text: 'Duplicate', value: 'duplicate', icon: 'fas fa-copy', disabled: false },
        { text: 'Delete', value: 'delete', icon: 'fas fa-trash', disabled: false },
      ],
    };
  },

  computed: {
    ...mapState(['ruleOptions', 'expressionOptions']),
    ...mapState('application', ['axiosInstance', 'showPrpSidebar']),

    isCombinedConfig() {
      return this.$route.fullPath.includes('combined');
    },
  },

  methods: {
    reindexPricings() {
      let formData = {
        pricing_order: {},
        uid: this.selectedPricingOptionUid,
      };
      this.pricingList.forEach((pricing, index) => {
        pricing.order_index = index;
        formData.pricing_order[index] = pricing.uid;
      });

      this.axiosInstance.put(`api/v1/pricing-list/${this.selectedPricingOptionUid}/`, formData);
      this.activePricing = null;
    },

    isOperatorOrConditional(value) {
      return ['+', '-', '*', '/', '>', '<', '>=', '<=', '==', '!=', '&&', '||'].includes(value);
    },

    handleDropdownSelect(event, element) {
      switch (event.value) {
        case 'edit':
          this.$emit('edit-pricing', element);

          break;
        case 'duplicate':
          this.$emit('duplicate-pricing', element.uid);
          break;
        case 'delete':
          this.$emit('delete-pricing', element.uid);

          break;
        default:
          break;
      }
    },
  },
};
</script>
