<template>
  <SparkModal :model-value="show" @update:model-value="close">
    <template #header>
      <div class="text-18 font-bold flex">
        <div>Save&nbsp;</div>
      </div>
    </template>

    <template #default>
      <div>
        <SparkInput v-model="calibrationName" label="Calibration Name" name="name" />
        <div class="pt-8">
          <SparkInput
            v-if="selectedMode === 'machine'"
            v-model="materialName"
            label="Material Name"
            name="materialname"
          />
          <SparkInput v-if="selectedMode === 'machine'" v-model="machineName" label="Machine Name" name="machinename" />
          <SparkInput v-if="selectedMode === 'price'" v-model="pricingName" label="Pricing Name" name="pricingname" />
          <div class="ml-8 mt-12 flex">
            <SparkCheckbox v-model="createTemplate" label="Create Template" name="createTemplate" />
            <label class="text-14">Create Template</label>
          </div>
          <SparkInput
            v-if="createTemplate"
            v-model="templateName"
            class="mt-10"
            label="Template Name"
            name="templateName"
          />
        </div>
        <div class="flex gap-8 justify-end">
          <SparkButton variant="outlined" @click="close()"><div v-text="'Cancel'" /></SparkButton>
          <SparkButton variant="secondary" @click="save()"><div v-text="'Save'" /></SparkButton>
        </div>
      </div>
    </template>
  </SparkModal>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex';

import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkCheckbox from '@/components/SparkComponents/SparkCheckbox.vue';
import SparkInput from '@/components/SparkComponents/SparkInput.vue';
import SparkModal from '@/components/SparkComponents/SparkModal.vue';

export default {
  name: 'SaveResultsModal',

  components: { SparkModal, SparkInput, SparkButton, SparkCheckbox },

  props: {
    show: { type: Boolean },
  },

  emits: ['close'],

  data() {
    return {
      calibrationName: '',
      materialName: '',
      machineName: '',
      pricingName: '',
      createTemplate: false,
      templateName: '',
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
    ...mapState('calibration', [
      'selectedProcessChain',
      'selectedMode',
      'calibrationConfigUid',
      'selectedParameters',
      'calibrationResult',
    ]),
  },

  watch: {
    show(newVal) {
      if (newVal) {
        this.calibrationName = this.calibrationResult.name;
        this.materialName = 'Calibration - ' + this.calibrationResult?.material_name;
        this.machineName = 'Calibration - ' + this.calibrationResult?.machine_name;
        this.templateName =
          'Template - ' + this.calibrationResult?.machine_name + ' - ' + this.calibrationResult?.material_name;
      }
    },
  },

  methods: {
    ...mapMutations('calibration', ['setCalibrationResult']),
    ...mapActions(['fetchPricingOptionList']),

    close() {
      this.$emit('close');
    },

    async save() {
      const data = {
        calibration_config_name: this.calibrationName,
      };

      await this.axiosInstance
        .put(`api/v1/calibration-config-list/${this.calibrationConfigUid}`, data)
        .then(() => {
          this.$emit('close');
          this.$router.push({ path: '/library/part' });
          this.createCalibratedResult();
        })
        .catch(error => {
          console.error(error);
        });
    },

    async createCalibratedResult() {
      const data = {
        material_name: this.materialName,
        machine_name: this.machineName,
        pricing_name: this.pricingName,
        create_template: this.createTemplate,
        template_name: this.templateName,
      };

      await this.axiosInstance
        .post(`api/v1/calibration-result/${this.calibrationConfigUid}`, data)
        .then(response => {
          // this.setCalibrationResult(...this.calibrationResult, ...{'name' : this.calibrationName});
          if (this.selectedMode === 'machine') {
            if (this.createTemplate) {
              let text =
                'Machine: ' +
                response.data.machine_name +
                '        ' +
                'Material: ' +
                response.data.material_name +
                '        ' +
                'Template: ' +
                response.data.template_name;
              this.$root.notify('success', 'Machine/material combination and Templatecreated', text, 10000);
            } else {
              let text =
                'Machine: ' + response.data.machine_name + '        ' + 'Material: ' + response.data.material_name;
              this.$root.notify('success', 'Machine and material combination created', text, 10000);
            }
          } else {
            this.$root.notify('success', 'Pricing created', response.data.pricing_name, 10000);
            this.fetchPricingOptionList();
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
  },
};
</script>
