import {
  Mesh,
  BufferGeometry,
  Float32BufferAttribute,
  MeshPhongMaterial,
  MeshBasicMaterial,
  FrontSide,
  BackSide,
  LineSegments,
  LineBasicMaterial,
  EdgesGeometry,
} from 'three';
import { ConvexHull } from 'three/examples/jsm/math/ConvexHull.js';

import { backSideMeshOpacity, hullMeshOpacity } from '@/assets/js/3d_viewer/SparkModules/MeshColor.js';

const meshMeta = {
  frontSideMesh: {
    renderOrder: 0,
    material: new MeshPhongMaterial({
      side: FrontSide,
      color: 0xe5f5ff,
      emissive: 0x000000,
      reflectivity: 0.2, // default: 1.0
      shininess: 50, // default: 30
      vertexColors: true,
      flatShading: true,
    }),
  },

  backSideMesh: {
    renderOrder: 1,
    material: new MeshPhongMaterial({
      side: BackSide,
      color: 0xe60000,
      transparent: true, // Slightly seethrough
      opacity: backSideMeshOpacity,
      polygonOffset: true, // Offset prevents overlap
      polygonOffsetFactor: 1,
      polygonOffsetUnits: 1,
    }),
  },

  convexHull: {
    renderOrder: 2,
    material: new MeshBasicMaterial({
      side: FrontSide,
      color: 0xb8ce45,
      transparent: true,
      opacity: hullMeshOpacity,
      vertexColors: true,
      polygonOffset: true,
      polygonOffsetFactor: -1,
      polygonOffsetUnits: -1,
    }),
  },

  edges: {
    renderOrder: 3,
    material: new LineBasicMaterial({
      color: 0x000000,
      transparent: true,
    }),
  },
};

export class FrontSideMeshCreator {
  addMeshToScene(scene, frontSideMesh) {
    // Old glb files may not contain vertext normals
    if (frontSideMesh.geometry.attributes.normal == undefined) {
      frontSideMesh.geometry.computeVertexNormals();
    }

    frontSideMesh.name = 'Frontside';
    frontSideMesh.material = meshMeta.frontSideMesh.material;
    frontSideMesh.material.flatShading = true;
    frontSideMesh.renderOrder = meshMeta.frontSideMesh.renderOrder;

    scene.add(frontSideMesh);
  }
}

export class BackSideMeshCreator {
  addMeshToScene(scene, frontSideMesh) {
    let backsideMesh = new Mesh(frontSideMesh.geometry, meshMeta.backSideMesh.material);
    backsideMesh.name = 'Backside';
    backsideMesh.renderOrder = meshMeta.backSideMesh.renderOrder;
    scene.add(backsideMesh);
    return backsideMesh;
  }
}

export class EdgesCreator {
  sceneAlreadyHasEdgesMesh(scene) {
    for (let cntChil = scene.children.length - 1; cntChil >= 0; cntChil--) {
      let child = scene.children[cntChil];
      if (child.type === 'LineSegments' && child.name == 'meshEdgesWireframe') {
        return true;
      }
    }
    return false;
  }

  removeMeshFromScene(scene) {
    for (let cntChil = scene.children.length - 1; cntChil >= 0; cntChil--) {
      let child = scene.children[cntChil];
      if (child.type === 'LineSegments' && child.name == 'meshEdgesWireframe') {
        child.geometry.dispose();
        child.material.dispose();
        scene.remove(child);
      }
    }
  }

  addMeshToScene(scene, mesh) {
    this.removeMeshFromScene(scene);

    // in geometry, the scale of the mesh is not applied automatically --> do this manually
    const scaledGeometry = mesh.geometry.clone();
    scaledGeometry.scale(...mesh.scale.toArray());

    const thresholdAngle = 0; // assure that all edges are shown
    const edges = new EdgesGeometry(scaledGeometry, thresholdAngle);
    const edgeLines = new LineSegments(edges, meshMeta.edges.material);
    edgeLines.name = 'meshEdgesWireframe';

    edgeLines.position.copy(mesh.position);
    edgeLines.rotation.copy(mesh.rotation);

    scene.add(edgeLines);
    return edgeLines;
  }
}

export class ConvexHullCreator {
  removeMeshFromScene(scene) {
    for (let cntChil = scene.children.length - 1; cntChil >= 0; cntChil--) {
      let child = scene.children[cntChil];
      if (child.type === 'Mesh' && child.name == 'ConvexHull') {
        child.geometry.dispose();
        child.material.dispose();
        scene.remove(child);
      }
    }
  }

  addMeshToScene(scene, mesh) {
    this.removeMeshFromScene(scene);
    const convexHull = new ConvexHull().setFromObject(mesh);
    let convexGeometry = this._hullToGeometry(convexHull);

    let convexMesh = new Mesh(convexGeometry, meshMeta.convexHull.material);
    convexMesh.name = 'ConvexHull';
    convexMesh.renderOrder = meshMeta.convexHull.renderOrder;
    scene.add(convexMesh);
    return convexMesh;
  }

  _hullToGeometry(convexHull) {
    const [faceIndices, positions] = this._getFaceIndsAndVertPos(convexHull);
    const numberVertices = positions.length / 3;

    let convexGeometry = new BufferGeometry();
    convexGeometry.setAttribute('position', new Float32BufferAttribute(positions, 3));
    convexGeometry.setAttribute('color', new Float32BufferAttribute(numberVertices * 4, 4));
    convexGeometry.setIndex(faceIndices);
    return convexGeometry;
  }

  _getFaceIndsAndVertPos(convexHull) {
    this.positions = [];
    this.vertexIndexMap = new Map();
    this.vertexCount = 0;
    let faceIndices = [];

    convexHull.faces.forEach(face => {
      const edge = face.edge;
      const vertices = [];
      let currentEdge = edge;
      do {
        vertices.push(currentEdge.head().point);
        currentEdge = currentEdge.next;
      } while (currentEdge !== edge);

      if (vertices.length >= 3) {
        const a = this._addVertexAndReturnInd(vertices[0]);
        for (let cnt = 1; cnt < vertices.length - 1; cnt++) {
          const b = this._addVertexAndReturnInd(vertices[cnt]);
          const c = this._addVertexAndReturnInd(vertices[cnt + 1]);
          faceIndices.push(a, b, c);
        }
      }
    });
    return [faceIndices, this.positions];
  }

  _addVertexAndReturnInd(vertex) {
    const key = `${vertex.x},${vertex.y},${vertex.z}`;
    if (!this.vertexIndexMap.has(key)) {
      this.positions.push(...vertex.toArray());
      this.vertexIndexMap.set(key, this.vertexCount++);
    }
    return this.vertexIndexMap.get(key);
  }
}
