<template>
  <div class="p-24 text-16 font-bold">Get Started with our Onboarding Tour</div>
  <img class="mt-60 w-1/2 ml-auto mr-auto" src="@/assets/img/Logo_BW_Negative_Transparent.png" alt="Get Started" />
</template>

<script>
export default {
  name: 'GetStarted',
};
</script>
