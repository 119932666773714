<template>
  <div :class="{ [custom]: !!custom }">
    <SparkHiddenInput
      v-model="inputField"
      :name="name"
      type="checkbox"
      class="flex cursor-pointer items-center gap-10 peer-disabled:cursor-default peer-checked:[&>.checkbox>svg]:block peer-checked:[&>.checkbox]:border-primary-500 peer-checked:[&>.checkbox]:bg-primary-500 peer-disabled:[&>.checkbox]:!border-gray-200 peer-disabled:[&>.checkbox]:bg-gray-50"
      :disabled="disabled"
      :value="value"
      :unchecked-value="false"
    >
      <div
        class="checkbox bg-white rounded-6 flex h-16 w-16 shrink-0 items-center justify-center border border-gray-200"
        :class="{
          [`${custom}`]: !!custom,
        }"
      >
        <i
          class="text-11 hidden pt-[1px] text-white fas fa-check"
          :class="{
            [`${custom}`]: !!custom,
          }"
        />
      </div>
      <div v-bind="$attrs">
        <slot />
      </div>
    </SparkHiddenInput>
  </div>
</template>

<script>
import { computed } from 'vue';

import SparkHiddenInput from './SparkHiddenInput.vue';

export default {
  name: 'SparkCheckbox',

  components: {
    SparkHiddenInput,
  },

  inheritAttrs: false,

  props: {
    name: { type: String, required: true },
    modelValue: { type: [Boolean, null, Number, String, Array], default: false },
    value: { type: [Boolean, null, Number, String], default: true },
    custom: { type: String, default: '' },
    disabled: { type: Boolean },
  },

  emits: ['update:modelValue', 'change'],

  setup(props, { emit }) {
    const inputField = computed({
      get: () => props.modelValue,
      set: value => {
        emit('update:modelValue', value);
        emit('change', value);
      },
    });

    return { inputField };
  },

  watch: {
    modelValue: {
      immediate: true,
      handler(value) {
        this.inputField = value;
      },
    },
  },
};
</script>
