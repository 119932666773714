<template>
  <div v-if="editable" class="flex items-baseline">
    <input
      v-model.lazy="storedHours"
      class="text-13 p-1 ml-1 rounded-6 border-gray-200 w-30"
      :class="disabled ? 'text-gray-50' : ''"
      type="number"
      step="1"
      min="0"
      max="99"
      placeholder="00"
      :title="lockedForUser ? lockedTitle : ''"
      :disabled="lockedForUser || disabled"
      @change="setNewFeedbackTime()"
      @focus="inputFocusActive(true)"
      @blur="inputFocusActive(false)"
    />:
    <input
      v-model.lazy="storedMinutes"
      class="text-13 p-1 ml-1 rounded-6 border-gray-200 w-30"
      :class="disabled ? 'text-gray-50' : ''"
      type="number"
      step="1"
      min="0"
      max="59"
      placeholder="00"
      :title="lockedForUser ? lockedTitle : ''"
      :disabled="lockedForUser || disabled"
      @change="setNewFeedbackTime()"
      @focus="inputFocusActive(true)"
      @blur="inputFocusActive(false)"
    />:
    <input
      v-model.lazy="storedSeconds"
      class="text-13 p-1 ml-1 rounded-6 border-gray-200 w-30"
      :class="disabled ? 'text-gray-50' : ''"
      type="number"
      step="1"
      min="0"
      max="59"
      placeholder="00"
      :title="lockedForUser ? lockedTitle : ''"
      :disabled="lockedForUser || disabled"
      @change="setNewFeedbackTime()"
      @focus="inputFocusActive(true)"
      @blur="inputFocusActive(false)"
    />
  </div>
  <div v-else>
    <div>{{ storedHours }}:{{ storedMinutes }}:{{ storedSeconds }}</div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
export default {
  name: 'FeedbackValueTimespan',

  props: {
    feedbackValue: { type: [String, Number, null, undefined], required: true },
    editable: Boolean,
  },

  emits: ['feedback-value-changed', 'input-focus-active'],

  data() {
    return {
      storedHours: '',
      storedMinutes: '',
      storedSeconds: '',
      disabled: false,
    };
  },

  computed: {
    ...mapState('application', ['lockedTitle']),
    ...mapGetters(['lockedForUser']),
  },

  watch: {
    feedbackValue: {
      handler(feedbackValue) {
        if (typeof feedbackValue === 'string') {
          const [hours, minutes, seconds] = feedbackValue.split(':').map(Number);
          const totalSeconds = hours * 3600 + minutes * 60 + seconds;
          this.setProppedFeedbackValue(totalSeconds);
          return;
        }
        this.setProppedFeedbackValue(feedbackValue * 3600);
      },

      immediate: true,
    },
  },

  methods: {
    setProppedFeedbackValue(totalSeconds) {
      this.storedHours = this.storedMinutes = this.storedSeconds = '00';
      if (totalSeconds) {
        const hours = parseInt(Math.floor(totalSeconds / 3600));
        const minutes = parseInt((totalSeconds - hours * 3600) / 60);
        const seconds = parseInt(totalSeconds - hours * 3600 - minutes * 60);

        this.storedHours = hours.toString().padStart(2, '0');
        this.storedMinutes = minutes.toString().padStart(2, '0');
        this.storedSeconds = seconds.toString().padStart(2, '0');
      }
    },

    setNewFeedbackTime() {
      this.disabled = true;
      // prevent faulty user input and string values
      let storedTimes = [this.storedHours.toString(), this.storedMinutes.toString(), this.storedSeconds.toString()];
      for (let index = 0; index < storedTimes.length; ++index) {
        const element = storedTimes[index];
        if (element === '' || isNaN(element) || element === '00' || element === '000') {
          if (index === 0) {
            this.storedHours = 0;
          } else if (index === 1) {
            this.storedMinutes = 0;
          } else if (index === 2) {
            this.storedSeconds = 0;
          }
        }
      }
      // convert to backend float timeformat
      let newTime =
        parseInt(this.storedHours) + parseInt(this.storedMinutes) / 60 + parseInt(this.storedSeconds) / 3600;
      this.$emit('feedback-value-changed', newTime);

      if (this.storedHours < 10 && this.storedHours.length < 2) {
        this.storedHours = '0' + this.storedHours;
      }

      if (this.storedMinutes < 10 && this.storedMinutes.length < 2) {
        this.storedMinutes = '0' + this.storedMinutes;
      }
      if (this.storedSeconds < 10 && this.storedSeconds.length < 2) {
        this.storedSeconds = '0' + this.storedSeconds;
      }
      this.disabled = false;
    },

    inputFocusActive(value) {
      this.$emit('input-focus-active', value);
    },

    // feedbackValueChanged(newFeedbackValue) {
    //   this.$emit('feedback-value-changed', newFeedbackValue);
    // },
  },
};
</script>

<style lang="scss" scoped>
* {
  transition: all 0.3s ease-in;
}

/* --------------------------------------------------------------------------------------------------- */
/* Remove spinners to make the currency symbol appear right next to the cost value in Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

::-webkit-datetime-edit-second-field {
  background: white;
  color: transparent;
  margin-left: -3px;
  position: absolute;
  width: 1px;
}
</style>
