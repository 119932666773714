<template>
  <div>
    <AddCustomMaterialModal
      :material-data-form="customMaterialForm"
      :material-data="materialData"
      :show="showAddCustomMaterialModal"
      mode="'add'"
      @close="showAddCustomMaterialModal = false"
      @add="addMaterial"
      @edit="editMaterial"
    />
    <EditCustomMaterialModal
      :material-data-form="customMaterialForm"
      :material-data="materialData"
      :show="showEditCustomMaterialModal"
      mode="'edit'"
      @close="showEditCustomMaterialModal = false"
      @edit="editMaterial"
    />
    <ViewMaterialModal
      :material-data="materialData"
      :filled-form-data="filledFormData"
      :show="showViewMaterialModal"
      @close="showViewMaterialModal = false"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import useCopyObject from '@/composables/copyObject.js';
import getCurrentDateWithTime from '@/composables/dateFormatting.js';
import { transformObjectToArray } from '@/helpers';
import { addCustomMaterialForm, populateFormValues } from '@/utils/material';
import AddCustomMaterialModal from '@/views/Library/material/modals/AddCustomMaterialModal.vue';
import EditCustomMaterialModal from '@/views/Library/material/modals/EditCustomMaterialModal.vue';
import ViewMaterialModal from '@/views/Library/material/modals/ViewMaterialModal.vue';

export default {
  name: 'CustomMaterial',

  components: { AddCustomMaterialModal, EditCustomMaterialModal, ViewMaterialModal },

  props: {
    materialData: { type: Object, required: true },
    type: { type: String, required: true },
  },

  emits: ['add-to-favourites'],

  data() {
    return {
      showAddCustomMaterialModal: false,
      showEditCustomMaterialModal: false,
      showViewMaterialModal: false,
      customMaterialForm: JSON.parse(JSON.stringify(addCustomMaterialForm)),
      filledFormData: {},
    };
  },

  computed: {
    ...mapState([
      'processes',
      'materialLevel2',
      'materialLevel3',
      'materialLevel4',
      'en45545Requirements',
      'hazardLevels',
      'ul94Flammabilities',
      'nfpa130Compliancies',
      'filteredMachines',
      'allMachines',
      'user',
      'organization',
    ]),

    ...mapState('application', ['axiosInstance']),

    ...mapState({
      currentTechs: 'curTechs',
    }),

    // additionalData() {
    //   let data = {};
    //   data['has_ai_prediction_model'] = this.materialData?.has_ai_prediction_model;

    //   return data;
    // },

    updatedFilteredMachines() {
      return transformObjectToArray(this.filteredMachines);
    },

    updatdedCurTechs() {
      const currentTechnology = transformObjectToArray(this.currentTechs);
      return currentTechnology;
    },

    updatedMaterialLevel2() {
      return transformObjectToArray(this.materialLevel2);
    },

    updatedEn45545Requirements() {
      return transformObjectToArray(this.en45545Requirements);
    },

    updatedHazardLevels() {
      return transformObjectToArray(this.hazardLevels);
    },

    updatedUl94Flammabilities() {
      return transformObjectToArray(this.ul94Flammabilities);
    },

    updatedNfpa130Compliancies() {
      return transformObjectToArray(this.nfpa130Compliancies);
    },

    updatedProcessSynonyms() {
      return this.organization.process_synonyms;
    },

    updatedFilteredRestrictions() {
      const userRole = this.user.user_role;
      if (userRole === 'user') {
        return [{ label: 'Owner', value: 'owner' }];
      } else {
        return [
          { label: 'Owner', value: 'owner' },
          { label: 'My Organization', value: 'organization' },
        ];
      }
    },

    updatedProcesses() {
      let actualProcesses = useCopyObject(this.updatedProcessSynonyms || {});

      if (actualProcesses !== undefined) {
        Object.keys(actualProcesses).forEach(key => {
          let listToRemove = [
            'EBM',
            'DOD',
            'VDC',
            'UIM',
            'TWIM',
            'SMW LC',
            'HAND LAMINATION',
            'SDC',
            'SAND CAST',
            'RIM',
            'PVC',
            'MIM',
            'LSR',
            'LP CAST',
            'LAMINATION',
            'INC CAST',
            'GIM',
            'G CAST',
            'FORM MIL',
            'FOAM CAST',
            'FACE MIL',
            'DM',
            'CTF CAST',
            'CMT',
          ];
          if (listToRemove.includes(actualProcesses[key]?.prc_acronym)) {
            delete actualProcesses[key];
          }
        });
      }

      const updatedProcesses = Object.values(actualProcesses || {}).map(prc => ({
        uid: prc.uid,
        label: prc.prc_acronym,
        value: prc.uid,
      }));

      return updatedProcesses;
    },
  },

  watch: {
    materialData: {
      handler(material) {
        // let description = '';
        // let linkvalue = '';
        // if (material['has_ai_prediction_model']) {
        //   description = 'Missing values are generated by the 3D Spark AI.';
        //   linkvalue = 'www.3dspark.de';
        // }
        if (Object.keys(material).length) {
          if (this.type === 'view') {
            this.createViewDataTable();
          } else if (this.type === 'add') {
            this.showAddCustomMaterialModal = true;
          } else if (this.type === 'edit') {
            this.mode = 'edit';
            this.showEditCustomMaterialModal = true;
          }
        }
      },

      deep: true,
    },

    updatedFilteredMachines() {
      this.populateCustomMaterialForm();
    },

    updatdedCurTechs() {
      this.populateCustomMaterialForm();
    },

    updatedMaterialLevel2() {
      this.populateCustomMaterialForm();
    },

    updatedEn45545Requirements() {
      this.populateCustomMaterialForm();
    },

    updatedHazardLevels() {
      this.populateCustomMaterialForm();
    },

    updatedUl94Flammabilities() {
      this.populateCustomMaterialForm();
    },

    updatedNfpa130Compliancies() {
      this.populateCustomMaterialForm();
    },

    updatedFilteredRestrictions() {
      this.populateCustomMaterialForm();
    },

    updatedProcessSynonyms() {
      this.populateCustomMaterialForm();
    },
  },

  mounted() {
    this.populateCustomMaterialForm();
  },

  methods: {
    createViewDataTable() {
      this.filledFormData = {};
      this.filledFormData = populateFormValues(this.materialData);
      this.showViewMaterialModal = true;
    },

    generateMatNameIfNotGiven(formData) {
      if (formData === undefined) return formData;
      if (formData.mat_name === undefined) {
        let dateString = getCurrentDateWithTime().dateWithTime;
        formData.mat_name = this.user.first_name + ' ' + this.user.last_name + ' ' + dateString;
      }
      return formData;
    },

    populateCustomMaterialForm() {
      Object.values(this.customMaterialForm).forEach(section => {
        section.forEach(field => {
          if (field.db_name === 'tech') {
            field.options = this.updatdedCurTechs;
          } else if (field.db_name === 'prc_uid') {
            field.options = this.updatedProcesses;
          } else if (field.db_name === 'mac_id') {
            field.options = this.updatedFilteredMachines;
          } else if (field.db_name === 'mat_lvl_2_id') {
            field.options = this.updatedMaterialLevel2;
          } else if (field.db_name === 'en45545') {
            field.options = this.updatedEn45545Requirements;
          } else if (field.db_name === 'hazard_level') {
            field.options = this.updatedHazardLevels;
          } else if (field.db_name === 'ul94') {
            field.options = this.updatedUl94Flammabilities;
          } else if (field.db_name === 'nfpa130') {
            field.options = this.updatedNfpa130Compliancies;
          } else if (field.db_name === 'restriction') {
            field.options = this.updatedFilteredRestrictions;
          }
        });
      });
    },

    addMaterial(formData) {
      this.axiosInstance
        .post('/api/v1/material/', formData)
        .then(response => {
          let message = 'Custom Material ' + response.data.mat_name + ' created';
          this.$root.notify('success', 'Add Material', message, 3000);
          this.$emit('add-to-favourites', response.data);
          this.$parent.highlightNewMaterial(response.data.mat_id);
        })
        .catch(error => {
          this.$root.notify('error', 'Add Material', error.response?.data.error_message, 3000);
        });
    },

    editMaterial(formData) {
      this.axiosInstance
        .put(`/api/v1/material/${formData.mat_id}/`, formData)
        .then(response => {
          if (response && response.data) {
            this.$root.notify('success', 'Edit Material ' + response.data.mat_name + ' edited', 3000);
            this.$emit('add-to-favourites', response.data);
            this.$parent.highlightNewMaterial(response.data.mat_id);
          }
        })
        .catch(error => {
          if (error && error.response && error.response.data) {
            this.$root.notify('error', error.response.data.error_message, 3000);
          } else {
            this.$root.notify('error', 'An unknown error occurred', 3000);
          }
        });
    },
  },
};
</script>
