<template>
  <div class="border-b border-gray-50 flex justify-between items-center">
    <div class="p-4 flex flex-col gap-4 w-full">
      <div class="flex">
        <div class="text-gray-400 pr-4 w-1/4">Type</div>
        <div class="w-3/4">{{ subscriptionTemplates[subscription.subscription_type].verbose_name }}</div>
      </div>
      <div class="flex">
        <div class="text-gray-400 pr-4 w-1/4">Subject</div>
        <div class="w-3/4">{{ subscription.name }}</div>
      </div>
      <div v-if="subscription.condition_is_external" class="flex">
        <div class="text-gray-400 pr-4 w-1/4">Only PRP parts</div>
        <div class="w-3/4">
          <i class="fas fa-check" />
        </div>
      </div>
      <div class="flex">
        <div class="text-gray-400 pr-4 w-1/4">Subscribers</div>
        <div class="w-3/4">
          <div v-for="subscriber in subscription.subscribers" :key="subscriber">{{ subscriber }}</div>
          <div v-for="email_subscriber in subscription.email_subscribers" :key="email_subscriber">{{ email_subscriber }}</div>
        </div>
      </div>
    </div>
    <div>
      <DropdownMenu
        :list-options="options"
        close-click-style="always"
        title-icon="fas fa-ellipsis-h"
        @update-option="update($event)"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import DropdownMenu from '@/components/Reusable/DropdownMenu.vue';

export default {
  name: 'Subscription',

  components: { DropdownMenu },

  props: {
    subscription: {
      type: Object,
      required: true,
    },
  },

  emits: ['edit'],

  data() {
    return {
      options: [
        // { text: 'Edit', value: 'edit', icon: 'fas fa-pen' },
        { text: 'Delete', value: 'delete', icon: 'fas fa-trash' },
      ],
    };
  },

  computed: {
    ...mapState(['subscriptionTemplates']),
  },

  methods: {
    ...mapActions(['deleteSubscription']),
    update(event) {
      if (event.value === 'delete') {
        this.deleteSubscription(this.subscription.uid);
      } else if (event.value === 'edit') {
        this.$emit('edit', this.subscription);
      }
    },
  },
};
</script>
