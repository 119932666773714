<template>
  <div>
    <div ref="form" class="bg-white w-full">
      <template v-for="(section, sectionName) in materialData" :key="sectionName">
        <CollapsibleSection :title="sectionName">
          <template v-for="field in section" :key="field.db_name">
            <Component
              :is="field.component"
              v-model="formData[field.db_name]"
              :name="field.db_name"
              :label="field.name + (field.unit ? ` (${field.unit})` : '')"
              :options="Array.isArray(field.options) && field.options.length > 0 ? field.options : []"
              :type="field.field"
              :disabled="field.name === 'Id'"
              :error="errors[field.db_name]"
              :is-percent="field.isPercent"
              top-label
              @change="validateField(field.db_name)"
            />
          </template>
        </CollapsibleSection>
      </template>
    </div>

    <div class="flex justify-end m-20 gap-8">
      <SparkButton variant="outlined" @click="$emit('close')">
        <div v-text="'Cancel'" />
      </SparkButton>
      <SparkButton variant="secondary" type="button" @click="validateFields">
        <div v-text="mode === 'add' ? 'Add Material' : 'Save'" />
      </SparkButton>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { z } from 'zod';

import CollapsibleSection from './CollapsibleSection.vue';

import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkForm from '@/components/SparkComponents/SparkForm.vue';
import SparkInput from '@/components/SparkComponents/SparkInput.vue';
import SparkSelect from '@/components/SparkComponents/SparkSelect.vue';
import getCurrentDateWithTime from '@/composables/dateFormatting.js';
import { getChoicebyUid } from '@/helpers.js';

export default {
  name: 'CustomMaterialForm',

  components: {
    CollapsibleSection,
    SparkButton,
    SparkForm,
    SparkInput,
    SparkSelect,
  },

  props: {
    materialData: { type: Object, required: true },
    show: { type: Boolean, required: true },
    mode: { type: String, required: true },
  },

  emits: ['add', 'close', 'prc-changed', 'edit'],

  data() {
    return {
      formData: {},
      errors: {},
    };
  },

  computed: {
    ...mapState([
      'curTechs',
      'user',
      'filteredMachines',
      'filteredMachines',
      'materialLevel2',
      'en45545Requirements',
      'hazardLevels',
      'ul94Flammabilities',
      'nfpa130Compliancies',
      'user',
    ]),

    schema() {
      return z.object({
        mat_name: z.string().min(1, 'Required').max(100, 'Maximal length is 100'),
        oem_name: z.string().min(1, 'Required'),
        tech: z.string().min(1, 'Required'),
        prc_uid: z.string().min(1, 'Required'),
        mac_id: z.string().min(1, 'Required'),
      });
    },
  },

  watch: {
    show: {
      handler(value) {
        if (value && Object.keys(this.materialData).length > 0) {
          this.formData = {};
          Object.values(this.materialData).forEach(section => {
            section.forEach(field => {
              this.formData[field.db_name] = field.value;
              if (field.db_name === 'mat_name' && this.mode === 'add') {
                this.formData[field.db_name] = '';
              }
            });
          });
        }
      },

      immediate: true,
    },

    'formData.prc_uid': {
      handler(newValue) {
        this.$emit('prc-changed', { prcUid: newValue, macId: this.formData.mac_id });
      },

      immediate: true,
      deep: true,
    },
  },

  methods: {
    handleSubmit() {
      const macIdAvailable = getChoicebyUid(this.filteredMachines, this.formData.mac_id);
      if (!this.formData.mac_id || !this.formData.prc_uid || !macIdAvailable) {
        this.$root.notify('error', 'Please select a machine and process.', 3000);
        return;
      }
      if (this.mode === 'add') {
        this.formData = this.generateMatNameIfNotGiven(this.formData);
        this.$emit('add', this.formData);
      } else {
        this.$emit('edit', this.formData);
      }
    },

    generateMatNameIfNotGiven(formData) {
      if (formData === undefined) return formData;
      if (!formData.mat_name) {
        let dateString = getCurrentDateWithTime().dateWithTime;
        formData.mat_name = this.user.first_name + ' ' + this.user.last_name + ' ' + dateString;
      }
      return formData;
    },

    validateFields() {
      const result = this.schema.safeParse(this.formData);
      const errors = result.error?.issues;
      if (!errors) {
        this.handleSubmit();
        return;
      }

      this.errors = errors.reduce((acc, issue) => {
        acc[issue.path[0]] = issue.message;
        return acc;
      }, {});

      this.$refs.form.scrollIntoView({ behavior: 'smooth' });
    },

    validateField(field) {
      if (!this.schema.shape[field]) {
        return;
      }
      const result = this.schema.safeParse(this.formData);
      const errors = result.error?.issues;
      if (errors) {
        this.errors[field] = errors.find(issue => issue?.path[0] === field)?.message || '';
      } else {
        this.errors[field] = '';
      }
    },
  },
};
</script>
