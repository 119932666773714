<template>
  <div class="flex p-20">
    <div class="flex flex-col w-1/3">
      <div class="text-16 font-bold">Custom Documents</div>
      <div class="text-13 text-primary-500 cursor-pointer hover:text-primary-600" @click="downloadTemplate">
        Download Template
      </div>
      <div class="text-13 text-primary-500 cursor-pointer hover:text-primary-600" @click="addCustomDocument">
        Add customized document
      </div>
    </div>
    <div v-if="!noTemplatesExisting" class="border border-gray-50 rounded-8 w-2/3">
      <div class="flex w-full text-13 text-gray-600 border-b border-gray-50 h-56 items-center">
        <div class="w-1/4 ml-24">File</div>
        <div class="w-1/4">Document Type</div>
        <div class="w-1/4">Created By</div>
        <div class="w-1/4 text-center">&nbsp;</div>
      </div>

      <div
        v-for="template in templates"
        :key="template.uid"
        class="h-60 flex w-full items-center hover:bg-gray-50 hover:cursor-pointer border-b border-gray-50"
      >
        <div class="w-1/4 ml-24">
          <div class="text-13 font-bold">{{ template.name }}</div>
          <div class="text-13">{{ template.default_file_name }}</div>
        </div>
        <div class="text-13 w-1/4 text-left">
          <div v-if="template.doc_type === 'part'">Part</div>
          <div v-if="template.doc_type === 'part_list'">Part List</div>
        </div>
        <div class="text-13 w-1/4">{{ template.creator_name }}</div>
        <DropdownMenu
          class="w-1/4 text-center"
          :list-options="dropdownOptions"
          title-icon="fas fa-ellipsis-h"
          close-click-style="always"
          @update-option="handleAction($event, template)"
          @click.stop
        />
      </div>
    </div>
    <AddDocumentModal :show="showModal" @close="closeModal" />
    <DownloadTemplate :show="showTemplateModal" @close="closeTemplateModal" />
  </div>
</template>

<script>
import { saveAs } from 'file-saver';
import { mapState } from 'vuex';

import AddDocumentModal from './components/AddDocumentModal.vue';
import DownloadTemplate from './components/DownloadTemplate.vue';

import DropdownMenu from '@/components/Reusable/DropdownMenu.vue';

export default {
  name: 'CustomDocumentSettings',

  components: {
    AddDocumentModal,
    DropdownMenu,
    DownloadTemplate,
  },

  data() {
    return {
      templates: null,
      noTemplatesExisting: null,
      showModal: false,
      showTemplateModal: false,

      dropdownOptions: [
        { text: 'Delete', value: 'delete', icon: 'fas fa-trash', disabled: false },
        { text: 'Download', value: 'download', icon: 'fas fa-download', disabled: false },
      ],
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance', 'axiosInstanceFileUpload']),
  },

  mounted() {
    this.getTemplateDocxList();
  },

  methods: {
    closeModal() {
      this.showModal = false;
      this.getTemplateDocxList();
    },

    closeTemplateModal() {
      this.showTemplateModal = false;
    },

    // Get Template List
    async getTemplateDocxList() {
      this.axiosInstance.get('api/v1/doc-template-list/').then(response => {
        this.templates = response.data;
        if (Object.keys(this.templates).length === 0) {
          this.noTemplatesExisting = true;
        } else {
          this.noTemplatesExisting = false;
        }
      });
    },

    handleAction(option, template) {
      switch (option.value) {
        case 'delete':
          this.deleteSpecificTemplateDocx(template);
          break;
        case 'download':
          this.downloadSpecificTemplateDocx(template);
          break;
      }
    },

    // DELETE Specific Template
    async deleteSpecificTemplateDocx(template) {
      this.axiosInstanceFileUpload.delete(`api/v1/doc-template/${template.uid}/`).then(() => {
        this.getTemplateDocxList();
        this.$root.notify('success', 'Template deleted', 3000);
      });
    },

    addCustomDocument() {
      this.showModal = true;
    },

    downloadTemplate() {
      this.showTemplateModal = true;
    },

    // Download Specific Template File
    async downloadSpecificTemplateDocx(template) {
      this.axiosInstance
        .get(`api/v1/doc-template-file/${template.uid}/`, {
          responseType: 'blob',
        })
        .then(response => {
          saveAs(response.data, template.default_file_name);
        })
        .catch(error => {
          console.log(error);
        });
    },
  },
};
</script>
